import React, { useState } from 'react'
import styled from 'styled-components'
import { InputConnect } from './FormProvider'
import { generatestring, a11yOnClick } from '../../utils/helpers'

const FormGroup = styled.div.attrs({
  className: 'form-group'
})`
  .custom-switch {
    .custom-control-label, .custom-control-input {
      cursor: pointer;
    }
  }
`
const classnames = (...classes) => {
  return { className: classes.flat().filter(c => c).join(' ') }
}

function WebInputInner (props) {
  const [state] = useState({ inputid: generatestring() })
  const inputelementprops = {
    id: state.inputid,
    name: props.name,
    ...(props.showinvalid ? { 'aria-invalid': true, 'aria-describedby': state.inputid + 'fb' } : {})
  }
  const inputprops = valFromEvent => ({
    ...inputelementprops,
    ...props.eventprops(valFromEvent)
  })

  const validityClass = props.showfeedback ? (props.error ? 'is-invalid' : 'is-valid') : ''
  const renderlabel = (className, label) => (<label htmlFor={state.inputid} {...classnames(className, props.labelClass)}>{label || props.label}</label>)
  const renderfeedback = () => (props.showinvalid && <small id={state.inputid + 'fb'} {...classnames('invalid-feedback', props.feedbackClass)}>{props.error}</small>)
  return (
    <FormGroup className={props.className}>
      <props.WrappedComponent {...props} inputelementprops={inputelementprops} inputprops={inputprops}
        validityClass={validityClass} renderlabel={renderlabel} renderfeedback={renderfeedback} />
    </FormGroup>
  )
}

const WebInputConnect = WrappedComponent => {
  return InputConnect(props => WebInputInner({ ...props, WrappedComponent }))
}

export const TextInput = WebInputConnect(props => {
  return <>
    {props.renderlabel()}
    <input type='text' value={props.value || ''} {...classnames('form-control', props.validityClass, props.inputClass)} {...props.inputprops(e => e.target.value)} />
    {props.renderfeedback()}
  </>
})

export const Checkbox = WebInputConnect(props => {
  return <>
    <input type='checkbox' checked={props.value || false} {...classnames('form-check-input', props.validityClass, props.inputClass)} {...props.inputprops(e => e.target.checked)} />
    {props.renderlabel('form-check-label')}
    {props.renderfeedback()}
  </>
})

export const Switch = WebInputConnect(props => {
  return <>
    <div className='custom-control custom-switch' {...a11yOnClick(props.boolClick(!props.value))} aria-label={'checkbox, ' + props.label + ', ' + (props.value ? 'checked' : 'unchecked')}>
      <input type='checkbox' checked={props.value || false} readOnly tabIndex={-1} {...classnames('custom-control-input', props.validityClass, props.inputClass)} {...props.inputelementprops} />
      {props.renderlabel('custom-control-label')}
    </div>
    {props.renderfeedback()}
  </>
})

export const YesNoInput = WebInputConnect(props => {
  return <>
    {props.renderlabel()}
    <div {...classnames('btn-group', props.validityClass, props.inputClass)} style={{ marginLeft: '10px' }}>
      <button type='button' {...classnames(props.value === true ? ' btn-primary' : ' btn-secondary', props.buttonClass)} {...a11yOnClick(props.boolClick(true))}>Yes</button>
      <button type='button' {...classnames(props.value === false ? ' btn-primary' : ' btn-secondary', props.buttonClass)} {...a11yOnClick(props.boolClick(false))}>No</button>
    </div>
    {props.renderfeedback()}
  </>
})
