/* global getComputedStyle */
import React, { Component } from 'react'
import { actions, connect } from 'mirrorx'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import SideBar from './components/SideBar/SideBar'
import Header from './components/Header/Header'
import Content from './components/Content/Content'
import { common } from './store/Theme'
import Banner from './components/Banner/Banner'
import { sleep } from 'txstate-utils'

createGlobalStyle`
  body {
    background-color: ${common.colors.mainBackground};
    color: ${common.colors.trueBlack};
  }

  #nprogress .bar {
    background-color: ${common.colors.lightBlue};
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${common.colors.lightBlue}, 0 0 5px ${common.colors.lightBlue};
  }
`

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  flex: 1;
  margin-left: ${props => props.collapsed ? 60 : 250}px;
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    margin-left: ${props => props.collapsed ? 0 : 250}px;
  }
`
Layout.displayName = 'Layout'

const Page = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`
Page.displayName = 'Page'

const getBreakpoints = () => {
  const cssVars = [
    '--breakpoint-xs',
    '--breakpoint-sm',
    '--breakpoint-md',
    '--breakpoint-lg',
    '--breakpoint-xl'
  ]
  return cssVars.reduce((breakpoints, varName) => {
    const pixels = getComputedStyle(document.body)
      .getPropertyValue(varName)
      .trim()
    breakpoints[varName.slice(-2)] = pixels.endsWith('px')
      ? Number(pixels.slice(0, -2))
      : Number(pixels)
    return breakpoints
  }, {})
}

const getColors = () => {
  const cssVars = [
    '--primary',
    '--secondary',
    '--success',
    '--info',
    '--warning',
    '--danger',
    '--light',
    '--dark'
  ]
  return cssVars.reduce((colors, varName) => {
    const color = getComputedStyle(document.body)
      .getPropertyValue(varName)
      .trim()
    colors[varName.slice(2)] = color
    return colors
  }, {})
}

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  loopForCurrentUser () {
    actions.auth.getCurrentUser()
    this.updateuserinterval = setInterval(actions.auth.getCurrentUser, 60000)
  }

  componentDidMount () {
    this.loopForCurrentUser()
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) clearInterval(this.updateuserinterval)
      else this.loopForCurrentUser()
    }, false)

    const updatemeta = async () => {
      for (let i = 0; i < 5; i++) {
        const breakpoints = getBreakpoints()
        if (breakpoints.lg > 0) {
          actions.theme.setBreakpoints(breakpoints)
          actions.meta.setColors(getColors())
          this.updateWindowDimensions()
          this.setState({ isLoading: false })
          return
        }
        await sleep(10)
      }
    }
    updatemeta()

    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  componentWillUnmount () {
    clearInterval(this.updateuserinterval)
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    const width = window.innerWidth
    const finalbp = Object.values(this.props.theme.breakpoints).sort().reduce((finalbp, bpwidth) => {
      return bpwidth < width ? finalbp + 1 : finalbp
    }, 0)
    actions.meta.setBreakpointIfChanged({ isMobile: width <= this.props.theme.mobilebreakpoint, breakpoint: finalbp })
  }

  render () {
    if (this.state.isLoading || this.props.currentUserLoading) return null
    return (
      <ThemeProvider theme={{ ...this.props.theme }}>
        <Router>
          <Page>
            <SideBar />
            <Layout collapsed={this.props.collapsed}>
              <Header />
              <Content>
                <Banner />
              </Content>
            </Layout>
          </Page>
        </Router>
      </ThemeProvider>
    )
  }
}

App.displayName = 'CMApp'

const mapStateToProps = state => {
  return {
    theme: state.theme,
    currentUser: state.auth.current,
    currentUserLoading: state.auth.loading,
    collapsed: state.layout.sidebarCollapsed
  }
}
export default connect(mapStateToProps)(App)
