import React, { Component } from 'react'
import { actions, connect } from 'mirrorx'
import styled from 'styled-components'
import ToggleFilter from './Form/ToggleFilter'
import SelectFilter from './Form/SelectFilter'
import Loader from '../Loading/Loader'
import { withURLFilter } from '../../utils/filterProvider'
import {
  RELEASE_TYPES,
  RELEASE_STATUS,
  EXPLANATIONS
} from '../../utils/constants'
import { rebuildSelect } from '../../utils/helpers'
import { FormButton } from '../../styles/Components/Common'
import SearchFilter from './Form/SearchFilter'
import withBasicForm from '../../utils/basicFormProvider'

const ButtonBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;

  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    width: 80%;
  }
`

class ReleasesFilter extends Component {
  componentDidMount () {
    this.url = this.props.asObject()
    actions.user.getUsers()
    actions.team.getTeams()
    actions.system.getSystems()
    actions.client.getClients()
    this.props.syncState(this.props.asObject())
  }

  handleCancel = e => {
    e.stopPropagation()
    actions.releaseList.toggleFiltersModal()
  }

  handleFilter = e => {
    e.preventDefault()
    e.stopPropagation()
    this.props.mergeHash(this.props.formState)
    this.props.updateURL()
    actions.releaseList.getReleases(this.props.asObject())
    actions.releaseList.toggleFiltersModal()
  }

  onKeyDown = e => {
    if (e.key === 'Escape') this.handleCancel(e)
  }

  render () {
    const {
      users,
      teams,
      clients,
      systems,
      loading,
      errors
    } = this.props
    return (
      <>
        {loading && <Loader errors={errors} />}
        {!loading && (
          <form onSubmit={this.handleFilter} onKeyDown={this.onKeyDown}>
            <SearchFilter
              value={this.props.formState.q}
              name='q'
              onChange={this.props.handleInputChange}
            />
            <SelectFilter
              label='type'
              value={rebuildSelect(RELEASE_TYPES, this.props.formState.type)}
              options={Object.entries(RELEASE_TYPES)}
              handleChange={this.props.handleMultiSelectChange('type')}
            />
            <SelectFilter
              label='status'
              value={rebuildSelect(RELEASE_STATUS, this.props.formState.status)}
              options={Object.entries(RELEASE_STATUS)}
              handleChange={this.props.handleMultiSelectChange('status')}
            />
            <SelectFilter
              label='ticket-owner'
              value={rebuildSelect(users, this.props.formState.creator)}
              options={users}
              handleChange={this.props.handleMultiSelectChange('creator')}
            />
            <SelectFilter
              label='change-team'
              value={rebuildSelect(teams, this.props.formState.team)}
              options={teams}
              handleChange={this.props.handleMultiSelectChange('team')}
            />
            <SelectFilter
              label='prod-team'
              value={rebuildSelect(teams, this.props.formState.prodteam)}
              options={teams}
              handleChange={this.props.handleMultiSelectChange('prodteam')}
            />
            <SelectFilter
              label='systems'
              value={rebuildSelect(systems, this.props.formState.system)}
              options={systems}
              handleChange={this.props.handleMultiSelectChange('system')}
            />
            <SelectFilter
              label='primary-client'
              value={rebuildSelect(clients, this.props.formState.client)}
              options={clients}
              handleChange={this.props.handleMultiSelectChange('client')}
            />
            <ToggleFilter
              label='prod-only'
              name='prodonly'
              handleActive={this.props.handleButtonToggle('prodonly')}
              handleBtnClick={this.props.handleButtonBoolSet('prodonly')}
              value={this.props.formState.prodonly}
            />
            <ToggleFilter
              label='communication-plan'
              value={this.props.formState.commplan}
              handleActive={this.props.handleButtonToggle('commplan')}
              handleBtnClick={this.props.handleButtonBoolSet('commplan')}
            />
            <ToggleFilter
              label='downtime'
              value={this.props.formState.downtime}
              handleActive={this.props.handleButtonToggle('downtime')}
              handleBtnClick={this.props.handleButtonBoolSet('downtime')}
            />
            <ToggleFilter
              label='financial-aid-impact'
              value={
                this.props.formState.impact === undefined
                  ? this.props.formState.impact
                  : String(this.props.formState.impact) === '1'
              }
              handleActive={this.props.handleButtonImpactToggle('impact')}
              handleBtnClick={this.props.handleButtonImpactSet('impact')}
            />
            <ToggleFilter
              label='lone-wolf'
              value={this.props.formState.lonewolf}
              title={EXPLANATIONS.lonewolf}
              handleActive={this.props.handleButtonToggle('lonewolf')}
              handleBtnClick={this.props.handleButtonBoolSet('lonewolf')}
            />
            <div className='form-group pt-3'>
              <ButtonBar>
                <FormButton type='submit'>
                  Apply Filters
                </FormButton>
                <FormButton className='muted' onClick={this.handleCancel}>
                  Cancel
                </FormButton>
              </ButtonBar>
            </div>
          </form>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  const users = state.user.list.map(u => [u.id, u.fullname])
  const teams = state.team.list.map(t => [t.id, t.name])
  const systems = state.system.list.map(s => [s.id, s.name])
  const clients = state.client.list.map(c => [c.id, c.name])
  const loading =
    !state.releaseList.hasFetchedList ||
    state.releaseList.loading ||
    !Object.keys(state.user.list).length ||
    !Object.keys(state.team.list).length ||
    !Object.keys(state.system.list).length ||
    !Object.keys(state.client.list).length
  const errors = Array.of(
    state.releaseList.error,
    state.user.error,
    state.team.error,
    state.system.error,
    state.client.error
  )
  return {
    loading,
    errors,
    users,
    teams,
    systems,
    clients
  }
}
export default withURLFilter(
  withBasicForm(connect(mapStateToProps)(ReleasesFilter))
)
