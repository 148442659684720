import React from 'react'
import Container from 'react-bootstrap/Container'
import { actions } from 'mirrorx'
import styled from 'styled-components'
import { FormButton } from '../../styles/Components/Common'

const Description = styled.p`
    font-size: .75em;
    margin-bottom: .5em;
`

const CloseChange = ({ changeid }) => {
  const closeChange = (changeid) => () => {
    actions.change.closeChange(changeid)
  }
  return (
    <Container className='mb-3'>
      <Description id='close-change'><strong>Close this Change Request</strong></Description>
      <FormButton
        type='button'
        className='muted'
        onClick={closeChange(changeid)}
      >
        Close Change Request
      </FormButton>
    </Container>
  )
}
export default CloseChange
