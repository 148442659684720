import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'

const renderOption = value => <option key={value.id} value={value.id}>{value.name}</option>

class PrimaryClient extends Component {
  onChange = e => {
    const { onChange, name } = this.props
    onChange && onChange({ name, value: { id: Number(e.target.value) } })
  }

  render () {
    const { values = [], value = {}, name } = this.props
    return (
      <>
        <Form.Group>
          <Form.Label htmlFor='primaryClientSelect'>Select Primary Client</Form.Label>
          <Form.Control
            id='primaryClientSelect'
            as='select'
            name={name}
            required
            value={value.id}
            onChange={this.onChange}
          >
            {values.map(renderOption)}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>If this is an internal project, select Internal Client, other choose an appropriate client</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }
}

export default PrimaryClient
