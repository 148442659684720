import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'

class StandardSelect extends Component {
  constructor (props) {
    super(props)
    const { values: standards } = this.props
    const standard = standards.slice(0, 1).reduce((acc, val) => val, null)
    this.sendChanges(standard)
  }

  sendChanges = standard => {
    if (standard) {
      const { onChange } = this.props
      const values = [
        { name: 'standard', value: standard.id },
        { name: 'title', value: standard.title },
        { name: 'prodonly', value: standard.prodonly },
        { name: 'prodteam', value: { id: standard.prodteam.id } },
        { name: 'commplan', value: standard.commplan },
        { name: 'impacted', value: standard.impacted },
        { name: 'impacts', value: standard.impacts },
        { name: 'client', value: { id: standard.client.id ?? 0 } },
        { name: 'systems', value: standard.systems.map(s => ({ id: s.id, name: s.name })) }
      ]
      values.forEach(onChange)
    }
  }

  handleChange = e => {
    const { values: standards } = this.props
    const standard = standards
      .filter(s => s.id === +e.target.value)
      .reduce((acc, val) => val, null)
    this.sendChanges(standard)
  }

  renderOption = standard => {
    return (
      <option key={standard.id} value={standard.id}>
        {standard.title}
      </option>
    )
  }

  render () {
    const { label, values, disabled } = this.props
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control as='select' onChange={this.handleChange} required disabled={disabled}>
          {values.map(this.renderOption)}
        </Form.Control>
      </Form.Group>
    )
  }
}
export default StandardSelect
