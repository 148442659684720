import React, { Component } from 'react'
import LoadingCircle from './LoadingCircle'
import ErrorAlert from '../ErrorAlert/ErrorAlert'

class Loader extends Component {
  componentDidMount () {
    this.timerId = window.setTimeout(this.handleIsLoading, 300)
  }

  componentWillUnmount () {
    clearInterval(this.timerId)
  }

  state = {
    isLoading: false
  }

  handleIsLoading = () => {
    this.setState({
      isLoading: true
    })
  }

  handleIsTimedOut = () => {
    this.setState({
      isLoading: false
    })
  }

  render () {
    const { isLoading } = this.state
    const { errors = [] } = this.props
    const hasError = !!errors.filter(err => err).length
    return (
      <>
        {isLoading && !hasError && <LoadingCircle />}
        {hasError && (
          <ErrorAlert
            errors={errors}
            defaultMessage='An error occurred while loading the requested resource.'
          />
        )}
      </>
    )
  }
}

export default Loader
