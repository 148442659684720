import mirror, { actions } from 'mirrorx'
import api from '../api/api'

const requestTeams = state => ({
  ...state,
  loading: true,
  error: null
})

const requestTeam = state => ({
  ...state,
  loadingOne: true,
  errorOne: null
})

const teamsSuccess = (state, data) => {
  return {
    ...state,
    loading: false,
    error: null,
    lastPage: data.finalpage || 0,
    list: data.documents || [],
    actions: data.actions || {}
  }
}

const teamSuccess = (state, data) => {
  return {
    ...state,
    loadingOne: false,
    errorOne: null,
    current: data
  }
}

const teamsFailure = (state, error) => ({
  ...state,
  loading: false,
  error
})

const teamFailure = (state, error) => ({
  ...state,
  loadingOne: false,
  errorOne: error
})

const initEditRequest = state => ({
  ...state,
  editData: {
    loading: true,
    error: null
  }
})

const initEditSuccess = (state, data) => {
  return {
    ...state,
    editData: {
      loading: false,
      error: null,
      users: data
    }
  }
}

const initEditFailure = (state, error) => ({
  ...state,
  editData: {
    loading: false,
    error
  }
})

const clearCurrent = state => {
  return {
    ...state,
    current: {}
  }
}

mirror.model({
  name: 'team',
  initialState: {
    loading: false,
    loadingOne: false,
    error: null,
    errorOne: null,
    lastPage: null,
    list: [],
    current: {},
    actions: {},
    editData: {}
  },
  reducers: {
    requestTeams,
    requestTeam,
    teamsSuccess,
    teamSuccess,
    teamsFailure,
    teamFailure,
    initEditRequest,
    initEditSuccess,
    initEditFailure,
    clearCurrent
  },
  effects: {
    async getTeams () {
      actions.team.requestTeams()
      try {
        const data = await api.getTeams()
        actions.team.teamsSuccess(data)
      } catch (error) {
        actions.team.teamsFailure({ error: error.message })
      }
    },
    async getTeam (id) {
      actions.team.requestTeam()
      try {
        const team = await api.getTeam(id)
        actions.team.teamSuccess(team)
      } catch (error) {
        actions.team.teamFailure({ errorOne: error.message })
      }
    },
    async initEdit (_, getState) {
      if (getState().team.editData.loading) return
      actions.team.initEditRequest()
      try {
        const users = await api.getAllUsers()
        actions.team.initEditSuccess(users.documents)
      } catch (error) {
        actions.team.initEditFailure({ error: error.message })
      }
    }
  }
})
