import React, { Component } from 'react'
import styled from 'styled-components'
import Name from './Name'

const Header = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  margin: 0 0 16px 0;
`

const CloseButton = styled.button`
  font-size: 25px;
  cursor: pointer;
  user-select: none;
`

class DefaultEditHeader extends Component {
  render () {
    const { addNew, onClick, onCloseClick, resource, title, showEditButton } = this.props
    return (
      <Header>
        <Name name={addNew ? `New ${resource}` : title} onClick={onClick} showEditButton={showEditButton} />
        <CloseButton onClick={onCloseClick} aria-label='close section' className='clear-btn'>
          <span className='ion-android-close' />
        </CloseButton>
      </Header>
    )
  }
}

export default DefaultEditHeader
