import React, { Component } from 'react'
import { ToggleButton } from '../../styles/Components/Common'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Label = styled.div`
  margin: 0 18px 0 0;
`

const ButtonGroup = styled.div`
  display: ${props => props.isActive ? 'flex' : 'none'};
`

class SpecialImpact extends Component {
  onClick = (value) => {
    const { onChange, name } = this.props
    onChange && onChange({ name, value })
  }

  render () {
    const { isActive, value, activeLabel, inactiveLabel } = this.props

    return (
      <Container>
        <Label className='form-label'>{isActive ? activeLabel() : inactiveLabel(value)}</Label>
        <ButtonGroup isActive={isActive} className='btn-group' role='group' aria-label='Yes or No'>
          <ToggleButton
            disabled={!isActive}
            className={value === true ? 'selected left' : 'left'}
            onClick={() => this.onClick(true)}
          >
            Yes
          </ToggleButton>
          <ToggleButton
            disabled={!isActive}
            className={value === false ? 'selected right' : 'right'}
            onClick={() => this.onClick(false)}
          >
            No
          </ToggleButton>
        </ButtonGroup>
      </Container>
    )
  }
}

export default SpecialImpact
