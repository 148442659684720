import React, { Component } from 'react'
import VotingActions from './VotingActions'
import VotingSummary from './VotingSummary'
import Loader from '../Loading/Loader'
import { connect } from 'mirrorx'

class Voting extends Component {
  render () {
    const { change, votingClosed, loading, errors, userid, isMobile } = this.props
    if (loading) {
      return <Loader errors={errors} />
    }
    return (
      <>
        <VotingActions
          change={change}
          isMobile={isMobile}
          isClosed={votingClosed}
          userid={userid}
        />
        <VotingSummary change={change} isMobile={isMobile} />
      </>
    )
  }
}

const mapStateToProps = state => {
  const { id: userid } = state.auth.current
  const isMobile = state.meta.isMobile
  const loading = !userid
  const errors = Array.of(state.user.errorCurrent)
  return {
    userid,
    loading,
    isMobile,
    errors
  }
}

export default connect(mapStateToProps)(Voting)
