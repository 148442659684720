import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import { getMimeType, isNewFile } from '../../../utils/upload'
import FileList from './FileList'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { uploadMachine, actions } from './uploadMachine'
import { interpret } from 'xstate/lib/interpreter'

const Dropzone = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 0.25em;
`

const dropZoneStyle = state => {
  if (state.current.value.drag === 'on') {
    return {
      border: '2px solid #4cb26b',
      boxShadow: '0 0 10px 5px #4cb26b80'
    }
  } else if (state.current.value.error === 'on') {
    return {
      border: '2px dashed var(--danger)'
    }
  } else {
    return { border: '2px dashed #80808080' }
  }
}

const DropText = styled.span`
  text-align: center;
  user-select: none;
  font-variant: small-caps;
  font-size: 18px;
  display: ${props => props.visible ? '' : 'none'};
`

const Input = styled.input`
  position: 'absolute';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointerEvents: 'none';
  display: none;
`

const ErrorMessage = styled.span`
  color: var(--danger);
  font-size: 14px;
`

const onDragOver = e => {
  e.preventDefault()
  e.persist()
  return false
}

const getUploadErrorMessage = (current) => {
  let message = ''
  if (current.value.files === 'empty') {
    message = 'Upload your change request document below'
  } else {
    message = 'Select Change Request for a document below or upload another'
  }
  return message
}

class DocumentUpload extends Component {
  constructor (props) {
    super(props)
    this.state = {
      current: uploadMachine.initialState
    }
    this.fileInput = React.createRef()
    this.node = React.createRef()
    this.dragTargets = []
    this.service = interpret(uploadMachine).onTransition(current => this.setState({ current }))
  }

  processFiles = async droppedFiles => {
    const { acceptedMimeTypes, required, requiredDoc } = this.props
    const { values } = this.props
    const validFiles = []

    for (let i = 0; i < droppedFiles.length || 0; i++) {
      const file = droppedFiles.item(i)
      const mimeType = await getMimeType(file)
      const fileIsNotInDropZone = acceptedMimeTypes.includes(mimeType) && isNewFile(file, values)

      if (fileIsNotInDropZone) {
        validFiles.push(file)
      }
    }
    const files = [...values, ...validFiles]
    const { onChange, name } = this.props
    onChange && onChange({ name, value: files })

    this.service.send(actions.addFiles(validFiles.length))

    if (required && files.length === 1 && files[0].docType !== requiredDoc) {
      this.handleFileTypeSelect(files[0], requiredDoc)
    }

    this.fileInput.current.value = null
  }

  onDrop = e => {
    e.preventDefault()
    e.persist()
    this.dragTargets = []

    this.service.send(actions.toggleDrag())
    this.processFiles(e.dataTransfer.files)
  }

  onDragEnter = e => {
    e.preventDefault()
    if (!this.dragTargets.includes(e.target)) {
      this.dragTargets.push(e.target)
    }
    if (this.state.current.value.drag === 'off') {
      this.service.send(actions.toggleDrag())
    }
    e.persist()
  }

  onDragLeave = e => {
    e.preventDefault()
    this.dragTargets = this.dragTargets
      .filter(el => el !== e.target && this.node.current.contains(el))
    if (this.dragTargets.length <= 0) {
      this.service.send(actions.toggleDrag())
    }
  }

  openFileChooser = (e) => {
    const clickedOnDropzoneChild = e.target !== this.node.current
    const clickedOnDropzoneText = e.target === (this.node.current || {}).children
    if (clickedOnDropzoneChild && !clickedOnDropzoneText.length) return

    this.fileInput.current.value = null
    this.fileInput.current && this.fileInput.current.click()
  }

  fileChooserSelect = () => {
    if (this.fileInput.current) {
      const { files = [] } = this.fileInput.current
      if (files.length > 0) {
        this.processFiles(files)
      }
    }
  }

  handleRemoveFile = (file) => {
    const currentFiles = this.props.values.filter(existing => {
      return existing.name !== file.name
    })

    const { onChange, name } = this.props
    onChange && onChange({ name, value: currentFiles })

    this.service.send(actions.removeFile())
  }

  handleFileTypeSelect = (file, type) => {
    file.docType = type
    const uploads = this.props.values

    const files = uploads.map(existingFile => {
      const nameMatch = existingFile.name === file.name
      const sizeMatch = existingFile.size === file.size
      const lastModifiedMatch = existingFile.lastModified === file.lastModified
      if (nameMatch && sizeMatch && lastModifiedMatch) {
        existingFile.docType = type
      }
      return existingFile
    })
    const { onChange, name } = this.props
    onChange && onChange({ name, value: files })
  }

  isMissingDocuments = () => {
    const { required, requiredDoc, values } = this.props
    const { current } = this.state
    let missingDocs = false
    if (required) {
      const uploadedDocTypes = values
        .map(file => file.docType)
        .filter(docType => docType !== undefined)

      missingDocs = !uploadedDocTypes.includes(requiredDoc)

      if (missingDocs) {
        if (current.value.error === 'off') {
          const message = getUploadErrorMessage(current)
          this.service.send(actions.enableError(message))
        }
      }
    }

    if (!missingDocs && current.value.error === 'on') {
      this.service.send(actions.disableError())
    }

    return missingDocs
  }

  isValid = () => {
    return !this.isMissingDocuments()
  }

  componentDidMount () {
    this.service.start()
    window.addEventListener('dragover', e => {
      e && e.preventDefault()
    }, false)
    window.addEventListener('drop', e => {
      e && e.preventDefault()
    }, false)
  }

  componentWillUnmount () {
    this.service.stop()
  }

  componentDidUpdate (prevProps, prevState) {
    const { required } = this.props

    if (prevProps.required === required) return

    if (required) {
      this.service.send(actions.selectExternal())
    } else {
      this.service.send(actions.selectInternal())
    }
  }

  render () {
    const { values, formValidated, fileTypes } = this.props
    const { current } = this.state

    return (
      <Form.Group>
        <Row>
          <Col xs={12}>
            <ErrorMessage>{current.context.errorMessage}</ErrorMessage>
          </Col>
          <Col xs={12} md={12}>
            <FileList
              formValidated={formValidated}
              onFileTypeSelect={this.handleFileTypeSelect}
              onRemoveFile={this.handleRemoveFile}
              files={values}
              types={fileTypes}
            />
          </Col>
          <Form.Label htmlFor='documents' className='sr-only'>Upload Documents</Form.Label>
          <Col xs={12} md={12}>
            <Input
              id='documents'
              onChange={this.fileChooserSelect}
              ref={this.fileInput}
              type='file'
              multiple={this.isValid()}
            />
            <Dropzone
              id='drop_zone'
              style={dropZoneStyle(this.state)}
              ref={this.node}
              onDragStart={this.onDragStart}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              onDragOver={onDragOver}
              onDrop={this.onDrop}
              onClick={this.openFileChooser}
            >
              <DropText visible>drag files or click to upload (pdf only)</DropText>
            </Dropzone>
          </Col>
        </Row>
      </Form.Group>
    )
  }
}

DocumentUpload.defaultProps = {
  acceptedMimeTypes: ['application/pdf'],
  uploads: []
}

export default DocumentUpload
