import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ToggleButton as Button } from '../../styles/Components/Common'

class ButtonSelect extends Component {
  onClick = type => e => {
    e.preventDefault()
    const { onChange, name } = this.props
    onChange && onChange({ name, value: type })
  }

  valueToDisplay = this.props.valueToDisplay

  renderButton = (value, i) => {
    const selected = this.props.value === value
    const { disabled } = this.props
    return (
      <Button
        disabled={disabled}
        key={i.toString()}
        onClick={this.onClick(value)}
        className={`${selected ? 'selected smooth' : 'smooth'}`}
      >
        {this.valueToDisplay(value)}
      </Button>
    )
  }

  render () {
    const { values, label, isMobile } = this.props
    const xsColumns = values.length > 2 ? 7 : 7
    return (
      <Form.Group>
        <Row>
          <Col xs={12 - (isMobile ? 0 : xsColumns)} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
          </Col>
          <Col xs={isMobile ? 12 : xsColumns} lg={5}>
            {values.map(this.renderButton)}
          </Col>
        </Row>
      </Form.Group>
    )
  }
}

export default ButtonSelect
