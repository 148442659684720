import mirror, { actions } from 'mirrorx'
import api from '../api/api'
import dayjs from 'dayjs'

mirror.model({
  name: 'changeList',
  initialState: {
    hasFetchedList: false,
    filtersModalOpen: false,
    loading: null,
    error: null,
    list: []
  },
  reducers: {
    requestChanges: (state, id) => ({
      ...state,
      changesid: id,
      hasFetchedList: false,
      voteError: null,
      loading: true,
      error: null,
      list: []
    }),
    changesSuccess: (state, { data, id }) => {
      if (state.changesid !== id) return state
      return {
        ...state,
        loading: false,
        hasFetchedList: true,
        error: null,
        lastPage: data.finalpage || 0,
        list: data.documents || [],
        sort: data.sort || [],
        actions: data.actions || {}
      }
    },
    changesFailure: (state, error) => ({
      ...state,
      hasFetchedList: false,
      loading: false,
      error
    }),
    filtersModalToggle: (state, filtersModalOpen) => ({
      ...state,
      filtersModalOpen
    })
  },
  effects: {
    async getChanges (params = {}, getState) {
      const requestidentifier = Math.random().toString().slice(2, 15)
      actions.changeList.requestChanges(requestidentifier)
      if (!params.startdate) {
        params.startdate = dayjs().subtract(1, 'year').toISOString()
      }
      try {
        actions.changeList.changesSuccess({
          data: await api.getChanges(params),
          id: requestidentifier
        })
      } catch (error) {
        actions.changeList.changesFailure({ error: error.message })
      }
    },
    toggleFiltersModal (_, getState) {
      const modalState = getState().changeList.filtersModalOpen
      actions.changeList.filtersModalToggle(!modalState)
    }
  }
})
