import mirror, { actions } from 'mirrorx'
import { cacheTime } from '../utils/helpers'
import { REFRESH_SECONDS } from '../utils/constants'
import api from '../api/api'

const requestReleases = state => ({
  ...state,
  list: [],
  hasFetchedList: false,
  loading: true,
  error: null,
  voteError: null
})

const requestRelease = state => ({
  ...state,
  hasFetchedCurrent: false,
  voteError: null,
  loading: true,
  current: {},
  error: null
})

const releaseSuccess = (state, data) => ({
  ...state,
  loading: false,
  hasFetchedCurrent: true,
  lastRefresh: cacheTime(),
  voteError: null,
  error: null,
  current: data
})

const releaseFailure = (state, error) => ({
  ...state,
  loading: false,
  hasFetchedCurrent: false,
  lastRefresh: cacheTime(),
  voteError: null,
  error: error,
  current: {}
})

const voteSuccess = (state, data) => ({
  ...state,
  loading: false,
  lastRefresh: cacheTime(),
  voteError: null,
  current: data
})

const voteFailure = (state, voteError) => ({
  ...state,
  loading: false,
  voteError
})

const createQuestionSuccess = (state, data) => {
  const current = { ...state.current }
  current.questions.push(data)
  return {
    ...state,
    loading: false,
    voteError: null,
    error: null,
    current
  }
}

const createAnswerSuccess = (state, { data, id }) => {
  const current = { ...state.current }
  const index = current.questions.findIndex(q => q.id === id)
  index >= 0 && current.questions[index].answers.push(data)
  return {
    ...state,
    loading: false,
    voteError: null,
    error: null,
    current: current
  }
}

const formInit = (state) => ({
  ...state,
  error: null
})

const createFailure = (state, error) => ({
  ...state,
  loading: false,
  error: error
})

const createInit = (state) => ({
  ...state,
  loading: true,
  error: null
})

const createSuccess = (state, data) => ({
  ...state,
  loading: false,
  lastRefresh: cacheTime(),
  voteError: null,
  error: null,
  current: data
})

const requestCancel = state => ({
  ...state,
  loading: true,
  error: null
})
const cancelSuccess = (state, data) => ({
  ...state,
  loading: false,
  error: null,
  current: data
})
const cancelFailure = (state, error) => ({
  ...state,
  loading: false,
  error: error
})

const useCache = state => ({
  ...state,
  loading: false
})

mirror.model({
  name: 'release',
  initialState: {
    lastRefresh: cacheTime(),
    hasFetchedCurrent: false,
    voteError: null,
    loading: null,
    error: null,
    actions: {},
    current: {}
  },
  reducers: {
    requestReleases,
    requestRelease,
    requestCancel,
    cancelSuccess,
    cancelFailure,
    releaseSuccess,
    releaseFailure,
    formInit,
    createInit,
    createSuccess,
    createQuestionSuccess,
    createFailure,
    createAnswerSuccess,
    voteSuccess,
    voteFailure,
    useCache
  },
  effects: {
    async getRelease (id, getState) {
      const { release } = getState()
      const empty = !Object.keys(release.current).length
      const different = release.current.id !== id
      const stale = cacheTime() - release.lastRefresh > REFRESH_SECONDS
      if (empty || different || stale) {
        actions.release.requestRelease()
        try {
          actions.release.releaseSuccess(await api.getRelease(id))
        } catch (error) {
          actions.release.releaseFailure({ error: error.message })
        }
      } else {
        actions.release.useCache()
      }
    },
    async cancelRelease ({ id, reason }) {
      actions.release.requestCancel()
      try {
        await api.cancelRelease(id, { data: { reason } })
        actions.release.releaseSuccess(await api.getRelease(id))
      } catch (error) {
        actions.change.cancelFailure({ error: error.message })
      }
    },
    async createRelease ({ changeid, data }) {
      actions.release.createInit()
      try {
        actions.release.createSuccess(await api.createRelease(changeid, data))
      } catch (error) {
        actions.release.createFailure({ error: error.message, validation: error.validationerrors })
      }
    },
    async createReleaseReport ({ releaseid, data }) {
      actions.release.createInit()
      try {
        actions.release.createSuccess((await api.createReleaseReport(releaseid, data)).data)
      } catch (error) {
        actions.release.createFailure({ error: error.message, validation: error.validationerrors })
      }
    },
    async createLessonsLearned ({ releaseid, data }) {
      actions.release.createInit()
      try {
        actions.release.createSuccess((await api.createLessonsLearned(releaseid, data)).data)
      } catch (error) {
        actions.release.createFailure({ error: error.message, validation: error.validationerrors })
      }
    },
    async createQuestion (data) {
      try {
        const { data: question } = await api.createQuestion(data)
        actions.release.createQuestionSuccess(question)
      } catch (error) {
        actions.release.createFailure({ error: error.message })
      }
    },
    async answerQuestion (data) {
      try {
        const answer = await api.answerQuestion(data)
        actions.release.createAnswerSuccess({ data: answer.data, id: data.id })
      } catch (error) {
        actions.release.createFailure({ error: error.message })
      }
    },
    async postVote (data) {
      actions.release.requestReleases()
      try {
        actions.release.voteSuccess((await api.postVote(data)).data)
      } catch (error) {
        actions.release.voteFailure({ error: error.message })
      }
    }
  }
})
