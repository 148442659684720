import React, { Component } from 'react'
import { actions, connect } from 'mirrorx'
import ListContainer from '../List/ListContainer'
import ChangesTable from './ChangesTable/ChangesTable'
import ResponsiveTable from './ChangesTable/ResponsiveTable'
import FilterModal from '../Filter/FilterModal'
import Loader from '../Loading/Loader'
import ChangesFilter from '../Filter/ChangesFilter'
import qs from 'qs'

class ChangeList extends Component {
  componentDidMount () {
    this.queryChanges()
    actions.user.getUsers()
    actions.team.getTeams()
    actions.system.getSystems()
    actions.client.getClients()
  }

  queryChanges = () => {
    const params = qs.parse(this.props.location.hash.slice(1), { arrayFormat: 'repeat' })
    actions.changeList.getChanges(params)
  }

  render () {
    const { loading, errors, changes, history, isMobile, filtersModal } = this.props
    return (
      <>
        <ListContainer
          dateRange
          isMobile={isMobile}
          toggleModal={actions.changeList.toggleFiltersModal}
          uri='change'
          onSubmit={this.queryChanges}
          title='Change Requests'>
          {loading
            ? <Loader errors={errors} />
            : isMobile
              ? <ResponsiveTable changes={changes} history={history} />
              : <ChangesTable changes={changes} history={history} />}
        </ListContainer>
        {filtersModal && (
          <FilterModal closeModal={actions.changeList.toggleFiltersModal}>
            <ChangesFilter />
          </FilterModal>)}
      </>
    )
  }
}

const mapStateToProps = state => {
  const changes = state.changeList.list
  const filtersModal = state.changeList.filtersModalOpen
  const isMobile = state.meta.isMobile
  const loading = !state.changeList.hasFetchedList || state.changeList.loading
  const errors = Array.of(state.changeList.error)
  return {
    changes,
    filtersModal,
    isMobile,
    loading,
    errors
  }
}

export default connect(mapStateToProps)(ChangeList)
