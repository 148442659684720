import React from 'react'

export class FilteringProvider extends React.Component {
  update (reducer) {
    return new Promise((resolve) => {
      this.setState(reducer, resolve)
    })
  }
}

class Filtering extends React.Component {
  shouldComponentUpdate (nextprops) {
    const oldprops = { ...this.props.mapstatetoprops(this.props.value, this.props.parentProps), ...this.props.parentProps }
    const newprops = { ...nextprops.mapstatetoprops(nextprops.value, nextprops.parentProps), ...nextprops.parentProps }
    const oldkeys = Object.keys(oldprops)
    const newkeys = Object.keys(newprops)
    if (oldkeys.length !== newkeys.length) return true
    if (newkeys.some(key => oldprops[key] !== newprops[key])) return true
    return false
  }

  render () {
    const { mapstatetoprops, parentProps, WrappedComponent, value } = this.props
    const newprops = mapstatetoprops(value, parentProps)
    return <WrappedComponent {...parentProps} {...newprops} />
  }
}

export const FilteringConnect = Consumer => {
  return mapstatetoprops => WrappedComponent => props => (<Consumer>
    {value => (
      <Filtering mapstatetoprops={mapstatetoprops} parentProps={props} WrappedComponent={WrappedComponent} value={value} />
    )}
  </Consumer>)
}
