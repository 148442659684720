import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.th`
  user-select: none;
  cursor: pointer;
`

const SortIndicatorContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`

const HeaderCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const Caret = styled.span`
  line-height: 7px;
  opacity: ${props => props.visible};
`

const SortArrows = ({ direction, content }) => {
  let upVisible = 1
  let downVisible = 1

  if (direction === 'up') {
    upVisible = 1
    downVisible = 0
  } else if (direction === 'down') {
    upVisible = 0
    downVisible = 1
  } else {
    upVisible = 1
    downVisible = 1
  }

  return (
    <SortIndicatorContainer>
      <Caret className='fas fa-caret-up' visible={upVisible} />
      <Caret className='fas fa-caret-down' visible={downVisible} />
    </SortIndicatorContainer>
  )
}

class SimpleHeaderCell extends Component {
  onClick = () => {
    const { handleSort, sortOn } = this.props
    handleSort && handleSort(sortOn)
  }

  render () {
    const { direction, style } = this.props
    const Content = this.props.cellContent
    return (
      <Container
        style={style}
        onClick={this.onClick}
      >
        <HeaderCell>
          <Content />
          <SortArrows direction={direction} />
        </HeaderCell>
      </Container>
    )
  }
}

export default SimpleHeaderCell
