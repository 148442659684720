import React from 'react'
import styled from 'styled-components'
import MobileMyWorkMyTeam from './MobileMyWorkMyTeam'

const PaddingBox = styled.div`
  padding: ${props => props.theme.dashboard.content.padding};
`

const BoxContent = (props) => {
  const { isMobile, personal, type } = props
  return (
    <PaddingBox>
      {isMobile && personal !== undefined ? <MobileMyWorkMyTeam type={type} personal={personal} /> : null}
      {props.children}
    </PaddingBox>
  )
}

export default BoxContent
