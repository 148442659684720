import React from 'react'
import AlternateListEditor from '../../AlternateListEditor/ListEditor'
import SystemForm from './SystemForm'
import GenericRow from '../Generic/GenericRow'
import GenericHeaderRow from '../Generic/GenericHeaderRow'
import SystemDetail from './SystemDetail'

class SystemListEditor extends React.Component {
  render () {
    return <AlternateListEditor
      endpoint='system'
      HeaderRow={GenericHeaderRow}
      Row={GenericRow}
      Form={SystemForm}
      Detail={SystemDetail}
    />
  }
}

export default SystemListEditor
