import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import TextArea from '../Form/TextArea'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import { FormButton } from '../../styles/Components/Common'
import styled from 'styled-components'
import QuestionList from '../Question/QuestionList'
import FormManager from '../Form/FormManager'
import { actions } from 'mirrorx'

const QuestionContainer = styled(Container)`
  padding: 15px 0;
`

class QuestionForm extends Component {
  form = React.createRef()
  questionForm = React.createRef()

  onSubmit = ({ event, data, resetForm }) => {
    event.preventDefault()
    event.stopPropagation()

    const form = this.form.current
    const isValid = form.checkValidity()

    if (isValid) {
      this.postForm(data, resetForm)
    }
  }

  handleClear = (resetFormFn) => () => {
    this.questionForm.current.value = ''
    resetFormFn()
  }

  postForm = ({ question: body }, resetForm) => {
    const { change: request } = this.props
    const type = request.modelName
    const newQuestion = {
      id: request.id,
      body,
      type
    }
    actions[type].createQuestion(newQuestion)
    const clearFn = this.handleClear(resetForm)
    clearFn()
  }

  render () {
    const { change } = this.props
    return (
      <Container>
        <FormManager initialValues={{}}>
          {({
            handleChange,
            values,
            touched,
            validated,
            onSubmit,
            resetForm
          }) => (
            <Container>
              <QuestionContainer>
                <Row id='answer-question'>
                  <Col xs={12}>
                    <QuestionList change={change} />
                  </Col>
                </Row>
                {change.actions.question ? (
                  <Form ref={this.form} noValidate validated={validated}>
                    <Col xs={12} className='px-0'>
                      <TextArea
                        forwardRef={this.questionForm}
                        label='Ask A Question'
                        labelStyle={{ fontSize: 18 }}
                        placeholder='What would you like to know?'
                        feedback="Your question can't be blank"
                        name='question'
                        id='question'
                        onChange={handleChange}
                      />
                    </Col>
                    <ButtonToolbar>
                      <FormButton
                        onClick={onSubmit(this.onSubmit)}
                      >
                        Post
                      </FormButton>
                    </ButtonToolbar>
                  </Form>
                ) : null}
              </QuestionContainer>
            </Container>
          )}
        </FormManager>
      </Container>
    )
  }
}

export default QuestionForm
