import React from 'react'
import DashTable from '../../Table/DashboardTable/DashTable'
import DashList from '../../Table/DashboardTable/DashList'
import Loader from '../../Loading/Loader'

const headers = [
  { display: 'Request #', accessor: 'id' },
  { display: 'Title', accessor: 'title' }
]

const ActionDisplay = ({ activeWork: { list, loading }, errors, history, isMobile }) => {
  const emptyState = {
    quote: 'Enjoy your free time',
    description: 'There are no requests waiting for action.'
  }

  if (loading) {
    return <Loader errors={errors} />
  }
  return (
    isMobile
      ? <DashList history={history} emptyState={emptyState} data={list} actionable />
      : <DashTable history={history} headers={headers} emptyState={emptyState} data={list} actionable />
  )
}
export default ActionDisplay
