import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Label, FilterPill } from '../../styles/Components/Common'
import { FILTERS } from '../../utils/constants'
import styled from 'styled-components'
import { connect } from 'mirrorx'
import { initCap, boolToHuman, a11yOnClick } from '../../utils/helpers'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
`

class FilterDisplay extends Component {
  constructor (props) {
    super(props)
    this.requiresLookup = ['creator', 'devteam', 'prodteam', 'system', 'client']
    this.keyToPropMap = {
      creator: 'users',
      devteam: 'teams',
      prodteam: 'teams',
      system: 'systems',
      client: 'clients'
    }
  }

  handleClick = (key, val) => e => {
    const { onClick, onSubmit } = this.props
    // Remove from URL
    onClick && onClick(key, val)
    // Update the results from API
    onSubmit && onSubmit()
  }

  handleAllKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleClearAll(e)
    }
  }

  handleClearAll = e => {
    const { onClick, onSubmit, filters } = this.props
    const list = Object.keys(filters)
    for (let i = 0; i < list.length; i++) {
      onClick && onClick(list[i], undefined, true)
    }
    onSubmit && onSubmit()
  }

  createPill = ({ key, value, display }) => {
    return (
      <FilterPill
        key={key + value}
        {...a11yOnClick(this.handleClick(key, value))}
      >
        {FILTERS[key]}: {display || initCap(value)}
      </FilterPill>
    )
  }

  getDisplayName = (key, value) => {
    let displayName = ''
    try {
      const list = this.props[this.keyToPropMap[key]]
      for (let i = 0; i < list.length; i++) {
        const [id, name] = list[i]
        if (String(id) === value) {
          displayName = name
        }
      }
    } catch (e) {
      console.error(e.message)
    }
    return displayName
  }

  buildFilter = key => value => {
    let display = ''
    const boolList = ['true', 'false', '1', '-1']
    if (this.requiresLookup.includes(key)) {
      display = this.getDisplayName(key, value)
    }
    if (key !== 'system' && !!display) {
      display = initCap(display)
    }
    if (key === 'q') {
      display = value
    }
    if (boolList.includes(value) && !display) {
      display = boolToHuman(value)
    }
    return this.createPill({ key, value, display })
  }

  createFilter = f => {
    const [key, value] = f

    if (Array.isArray(value)) {
      return value.map(this.buildFilter(key))
    }
    return this.buildFilter(key)(value)
  }

  removeDates = (f, i) => {
    const [key] = f
    return key !== 'startdate' && key !== 'enddate'
  }

  orderFilters = list => {
    const front = []
    const end = []
    for (let i = 0; i < list.length; i++) {
      const [key, value] = list[i]
      if (key === 'q') {
        end.push([key, value])
      } else {
        front.push([key, value])
      }
    }
    return front.concat(end)
  }

  render () {
    const { filters } = this.props
    const filterList = Object.entries(filters).filter(this.removeDates)
    if (this.props.loading || !filterList.length) {
      return null
    }
    return (
      <Column>
        <Label>FILTERS APPLIED</Label>
        <Row>
          {this.orderFilters(filterList).map(this.createFilter)}
          <FilterPill
            tabIndex='0'
            className='active'
            onClick={this.handleClearAll}
            onKeyDown={this.handleAllKeyPress}
          >
            CLEAR ALL
          </FilterPill>
        </Row>
      </Column>
    )
  }
}

FilterDisplay.propTypes = {
  filters: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const users = state.user.list.map(u => [u.id, u.fullname])
  const teams = state.team.list.map(t => [t.id, t.name])
  const systems = state.system.list.map(s => [s.id, s.name])
  const clients = state.client.list.map(c => [c.id, c.name])
  const loading =
    !Object.keys(state.user.list).length ||
    !Object.keys(state.team.list).length ||
    !Object.keys(state.system.list).length ||
    !Object.keys(state.client.list).length
  return {
    loading,
    users,
    teams,
    systems,
    clients
  }
}
export default connect(mapStateToProps)(FilterDisplay)
