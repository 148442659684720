import React from 'react'
import styled from 'styled-components'
import { actions } from 'mirrorx'
import { a11yOnClick } from '../../utils/helpers'

const Button = styled.label`
  background: 'transparent';
  border: 2px solid ${props => props.theme.common.colors.lightBlue};
  padding: 0.375rem 0.7rem;
  &, &:hover {
    color: #fff;
  }

  &.selected {
    background-color: ${props => props.theme.common.colors.lightBlue};
  }
  &:not(.selected) {
    &:hover,
    &:focus {
      background-color: ${props => props.theme.common.colors.lightBlueShadow};
      border: 2px solid ${props => props.theme.common.colors.lightBlueShadow};
    }
  }
`

const MyWorkMyTeam = ({ personal, type }) => {
  return (
    <div className='btn-group btn-group-toggle'>
      <Button
        className={'btn btn-sm' + (personal ? ' selected' : '')}
        {...(!personal ? a11yOnClick(() => actions.dashboard.toggle(type)) : {})}
      >
        My Work
      </Button>
      <Button
        className={'btn btn-sm' + (!personal ? ' selected' : '')}
        {...(personal ? a11yOnClick(() => actions.dashboard.toggle(type)) : {})}
      >
        My Team
      </Button>
    </div>
  )
}
export default MyWorkMyTeam
