import React, { Component } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import { VOTING_TYPES } from '../../utils/constants'
import { actions } from 'mirrorx'
import { compose, identity, pipe } from '../../utils/helpers'
import { voteBuilder } from '../../utils/builders'
import { createVoteHelpers } from './Helpers'
import PromptModal from '../Portal/PromptModal'

const VotingBar = styled.div`
  background: #f8f9fa;
  padding: 30px 0px;
`

const CallToAction = styled.span`
  font-size: 1.2rem;
  padding-top: 5px;
  text-align: center;
`

const VotingIcon = styled.i`
  padding: 7px;

  &.lg {
    font-size: 1.2rem;
  }
`

const Grid = styled.div`
  display: grid;
  width: ${props => (props.isMobile ? '100%' : '70%')};
  grid-template-columns: ${props =>
    props.isMobile ? '1fr' : 'repeat(4, 1fr)'};
  grid-gap: ${props => (props.isMobile ? '40px 0' : '0 40px')};
`

const Button = styled.button`
  background-color: ${props => props.theme.common.colors.mainBlue};
  box-shadow: 0 2px 0 0 ${props => props.theme.common.colors.darkBlue};
  border-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;

  &:hover, &:active {
    background-color: ${props => props.theme.common.colors.activeBlue};
  }

  &.disabled {
    background-color: ${props => props.theme.common.colors.darkGray};
    color: ${props => props.theme.common.colors.darkestGray};
    cursor: default;
  }

  &.active {
    background-color: ${props => props.theme.common.colors.activeBlue};
    color: #fff;
  }
`

class VotingActions extends Component {
  state = {
    isPromptOpen: false,
    reason: null
  }

  voteOptions = [
    {
      fn: () => this.handleApprovedClicked,
      icon: 'mdi mdi-thumb-up',
      text: VOTING_TYPES.approved
    },
    {
      fn: () => this.handleDeniedClicked,
      icon: 'mdi mdi-thumb-down',
      text: VOTING_TYPES.denied
    },
    {
      fn: () => this.handleNaClicked,
      icon: 'ion-minus-round lg',
      text: VOTING_TYPES.na
    }
  ]

  // Handlers
  handleTogglePrompt = () => {
    this.setState({
      isPromptOpen: !this.state.isPromptOpen
    })
  }

  handleReasonInput = e => {
    this.setState({
      reason: e.target.value
    })
  }

  handlePromptConfirmClicked = () => {
    this.handleTogglePrompt()
    this.handleVote(this.createVote(VOTING_TYPES.denied.action, this.state.reason))
  }

  handleApprovedClicked = () => {
    this.handleVote(this.createVote(VOTING_TYPES.approved.action))
  }

  handleDeniedClicked = () => {
    this.handleTogglePrompt()
  }

  handleNaClicked = () => {
    this.handleVote(this.createVote(VOTING_TYPES.na.action))
  }

  // Creators
  createVote = (vote, reason) => {
    const constructVote = reason
      ? compose(
        voteBuilder.addVote(vote),
        voteBuilder.addReason(reason)
      )
      : compose(
        identity,
        voteBuilder.addVote(vote)
      )
    return constructVote(voteBuilder.basis)
  }

  handleVote = vote => {
    const { id } = this.props.change
    const type = this.props.change.modelName
    const constructVote = pipe(
      voteBuilder.addVote(vote),
      voteBuilder.addType(type),
      voteBuilder.addId(id)
    )
    actions[type].postVote(constructVote(voteBuilder.basis))
  }

  renderButton = ({ fn, text, icon }) => {
    return (
      <Button
        className={this.helpers.getClassList(text.action)}
        disabled={this.helpers.isDisabled()}
        key={text.action}
        onClick={fn()}>
        <VotingIcon className={icon} />
        &nbsp;
        {this.helpers.isDisabled() ? text.displayPast : text.display}
      </Button>
    )
  }

  renderButtons = () => {
    return this.voteOptions.map(this.renderButton)
  }

  render () {
    this.helpers = createVoteHelpers(this.props.change, this.props.userid)
    return (
      <>
        {this.props.change.actions.vote || this.helpers.isDisabled() ? (
          <VotingBar>
            <Container>
              <Container>
                <Grid isMobile={this.props.isMobile}>
                  <CallToAction>Your vote</CallToAction>
                  {this.renderButtons()}
                </Grid>
              </Container>
            </Container>
          </VotingBar>
        ) : null}
        {this.state.isPromptOpen && (<PromptModal
          onClose={this.handleTogglePrompt}
          onInput={this.handleReasonInput}
          onConfirm={this.handlePromptConfirmClicked}
          promptText='Please provide a reason for denying this request.'
        />)}
      </>
    )
  }
}

export default VotingActions
