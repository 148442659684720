import mirror, { actions } from 'mirrorx'
import api from '../api/api'

const requestImpacts = state => ({
  ...state,
  loading: true,
  error: null
})

const impactsSuccess = (state, data) => ({
  ...state,
  loading: false,
  error: null,
  lastPage: data.finalpage || 0,
  list: data.impacts || []
})

const impactSuccess = (state, data) => ({
  ...state,
  loading: false,
  error: null,
  current: data
})

const impactsFailure = (state, error) => ({
  ...state,
  loading: false,
  error
})

mirror.model({
  name: 'impact',
  initialState: {
    loading: false,
    error: null,
    lastPage: null,
    list: [],
    current: null
  },
  reducers: {
    requestImpacts,
    impactsSuccess,
    impactSuccess,
    impactsFailure
  },
  effects: {
    async getImpacts () {
      actions.impact.requestImpacts()
      try {
        const { documents: impacts, finalpage } = await api.getImpacts()
        actions.impact.impactsSuccess({ impacts, finalpage })
      } catch (error) {
        actions.impact.impactsFailure({ error: error.message })
      }
    },
    async getImpact (id) {
      actions.impact.requestImpacts()
      try {
        const impact = await api.getImpact(id)
        actions.impact.impactSuccess(impact)
      } catch (error) {
        actions.impact.impactsFailure({ error: error.message })
      }
    }
  }
})
