import React from 'react'
import { ListEditorProvider, ListEditorConnect } from './ListEditorProvider'
import PaneManager from './PaneManager'
import List from './List/List'
import Editor from './Editor/Editor'
import { LISTEDITOR_TYPES } from '../../utils/constants'

class ListEditorContent extends React.PureComponent {
  render () {
    const { endpoint, selected, editing, Row, HeaderRow, Form, Detail } = this.props
    return (
      <PaneManager showRight={selected || editing} disableLeft={editing}
        left={
          <List title={LISTEDITOR_TYPES[endpoint].plural} Row={Row} HeaderRow={HeaderRow} />
        } right={
          <Editor FormFields={Form} Detail={Detail} />
        }
      />
    )
  }
}
const ListEditorContentConsumer = ListEditorConnect(state => ({
  selected: state.selected,
  editing: state.editing
}))(ListEditorContent)

class AlternateListEditor extends React.Component {
  render () {
    const { endpoint } = this.props
    return (
      <ListEditorProvider endpoint={endpoint}>
        <ListEditorContentConsumer {...this.props} />
      </ListEditorProvider>
    )
  }
}

export default AlternateListEditor
