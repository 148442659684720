import React, { Component } from 'react'
import SimpleRow from './SimpleRow'
import styled from 'styled-components'

const Thead = styled.thead`
  background-color: ${props => props.theme.common.colors.offWhite};
`

class SimpleHeader extends Component {
  render () {
    const { headers, handleSort, sortProps, style } = this.props
    return (
      <Thead>
        <SimpleRow headers={headers} style={style} sortProps={sortProps} handleSort={handleSort} headerRow />
      </Thead>
    )
  }
}

export default SimpleHeader
