import React, { Component } from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const HeadingRow = styled(Row)`
  margin-bottom: 16px;
`

const Title = styled.h1`
  color: #363534;
  font-size: 1.5em;
`

const Owner = styled.p`
  color: #363534;
  display: inline;
  margin: 0 8px 0 0;
`

const Team = styled.p`
  color: #363534;
  display: inline;
  margin: 0 0 0 8px;
`

class FormHeader extends Component {
  render () {
    const { title, owner, userTeam } = this.props
    return (
      <HeadingRow>
        <Col xs={12}>
          <Title>{title}</Title>
        </Col>
        <Col xs={6}>
          {owner ? <Owner><b>Owner: </b>{owner}</Owner> : null}
          {userTeam ? <Team><b>Team: </b>{userTeam.name}</Team> : null}
        </Col>
      </HeadingRow>
    )
  }
}

export default FormHeader
