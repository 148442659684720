import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { VOTING_TYPES } from '../../utils/constants'
import styled from 'styled-components'

const VotingIcon = styled.i`
  color: white;
  padding: 7px;
`

const VotingIconInverted = styled(VotingIcon)`
  color: #333;
`

const DropdownBtn = styled(DropdownButton)`
  .btn-primary, .btn-primary.dropdown-toggle{
    border: 2px solid ${props => props.theme.common.colors.lightBlue};
    color: ${props => props.theme.common.colors.trueBlack};
    background: none !important;
  }
`

const MobileSelect = ({ onApprovedClicked, onDeniedClicked, onNaClicked, title = 'Choose an action' }) => {
  return (
    <DropdownBtn id='voting-action' variant='primary' title={title}>
      <Dropdown.Item as='button' onClick={onApprovedClicked}>
        <VotingIconInverted className='fas fa-thumbs-up' />
        {VOTING_TYPES.approved.display}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item as='button' onClick={onDeniedClicked}>
        <VotingIconInverted className='fas fa-thumbs-down' />
        {VOTING_TYPES.denied.display}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item as='button' onClick={onNaClicked}>
        <VotingIconInverted className='fas fa-minus' />
        {VOTING_TYPES.na.display}
      </Dropdown.Item>
    </DropdownBtn>
  )
}
export default MobileSelect
