import React from 'react'
import { ListEditorConnect } from '../ListEditorProvider'
import { StyledTR, ArrowTD } from '../../../styles/Components/Table'
import { a11yOnClick } from '../../../utils/helpers'

class ConnectedRow extends React.Component {
  render () {
    const { Row, item, selected, setSelected } = this.props
    return <StyledTR className={selected ? 'table-active' : ''} {...a11yOnClick(() => setSelected(item.id))} aria-haspopup='true'>
      <Row item={item} />
      <ArrowTD aria-hidden='true' />
    </StyledTR>
  }
}

export default ListEditorConnect((state, props) => {
  return {
    selected: state.selected === props.item.id,
    setSelected: state.setSelected
  }
})(ConnectedRow)
