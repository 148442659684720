import React, { Component } from 'react'
import styled from 'styled-components'
import Pencil from '../Icons/Pencil'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #363534;
`

const NameText = styled.span`
  font-size: 20px;
  margin-right: 12px;
`

const PencilButton = styled.button`
  color: #363534;
`

class Name extends Component {
  render () {
    return (
      <Container>
        <NameText>{this.props.name}</NameText>
        {this.props.showEditButton ? <PencilButton className='clear-btn' aria-label={'edit ' + this.props.name} onClick={(e) => { e.preventDefault(); this.props.onClick(e) }}><Pencil size={28} /></PencilButton> : null}
      </Container>
    )
  }
}

export default Name
