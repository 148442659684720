import React, { Component } from 'react'
import styled from 'styled-components'
import Collapse from 'react-bootstrap/Collapse'
import { SecondaryTitle, Icon } from '../../styles/Components/Common'
import { ContentBox, CollapsableHeader } from '../../styles/Components/ContentBox'
import { a11yOnClick } from '../../utils/helpers'

const AnimationDiv = styled.div`
  margin: 0;
  padding: 0;
`

const ActionLink = styled(Icon)`
  font-size: 1.7rem;
  color: #6c757d;
`

const Subtitle = styled.span`
  font-size: 1em;
  color: #36353480;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

class Collapsable extends Component {
  state = {
    isChanged: false,
    isOpen: false
  }

  isOpen () {
    return Boolean(this.state.isChanged ? this.state.isOpen : this.props.startsOpen).valueOf()
  }

  handleClick = () => {
    this.setState({
      isChanged: true,
      isOpen: !this.isOpen()
    })
  }

  render () {
    const { disabled, title, subtitle, children, id } = this.props
    const styles = disabled ? { cursor: 'default' } : { cursor: 'pointer' }
    const link = this.isOpen()
      ? <ActionLink className='mdi mdi-minus-circle-outline' />
      : <ActionLink className='mdi mdi-plus-circle-outline' />

    return (
      <ContentBox styles={styles} id={id}>
        <CollapsableHeader {...a11yOnClick(this.handleClick)} aria-haspopup='true' aria-expanded={this.isOpen()} style={styles}>
          <TitleContainer>
            <SecondaryTitle className='nm'>{title}</SecondaryTitle>
            <Subtitle>{subtitle || ''}</Subtitle>
          </TitleContainer>
          {disabled ? null : link}
        </CollapsableHeader>
        {disabled
          ? null
          : (<Collapse in={this.isOpen()} timeout={600}>
            <AnimationDiv>
              {children}
            </AnimationDiv>
          </Collapse>)}
      </ContentBox>
    )
  }
}

export default Collapsable
