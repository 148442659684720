import React, { Component } from 'react'
import styled from 'styled-components'
import { Icon } from '../../styles/Components/Common'

const Wrapper = styled.div`
  position: absolute;
  top: ${props => props.position.top}px;
  left: ${props => props.position.left}px;
  right: ${props => props.position.right}px;
  margin: 0 auto;
  display: ${props => props.active ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  z-index: 999;
`

const Container = styled.div`
  display: flex;
  max-width: 80%;
  background-color: ${props => props.color};
  border-radius: 0.25em 0.25em;
  overflow: hidden
  box-shadow: 0px 4px 12px -2px rgba(0,0,0,0.5);
  cursor: pointer;
`

const Message = styled.span`
  font-size: 18px;
  color: white;
  flex: 1;
  padding: 6px 8px;
  overflow-y: auto;
`

const CloseContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: ${props => props.color};
  i { font-size: 18px; color: white; }
`

class BannerDisplay extends Component {
  render () {
    const { active, color, message, position, onClick } = this.props
    return (
      <Wrapper position={position} active={active}>
        <Container color={color}>
          <Message aria-live='assertive' role='alert'>
            {message}
          </Message>
          <CloseContainer onClick={onClick} color={color} aria-hidden='true'>
            <Icon className='fas fa-times' />
          </CloseContainer>
        </Container>
      </Wrapper>
    )
  }
}

BannerDisplay.defaultProps = {
  position: {
    top: 8,
    left: 0,
    right: 0
  }
}

export default BannerDisplay
