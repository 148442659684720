import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`

`

class MobileTable extends Component {
  renderRows = () => {
    const { data, rowComponent: Row, handleRowClick } = this.props

    return data.map((row, index, arr) => {
      const first = index === 0

      return (
        <Row
          key={row.id}
          id={row.id}
          first={first}
          onClick={handleRowClick}
          {...row}
        />
      )
    })
  }

  render () {
    return (
      <Container>
        {this.renderRows()}
      </Container>
    )
  }
}

export default MobileTable
