import mirror, { actions } from 'mirrorx'

const setColors = (state, colors) => ({
  ...state,
  colors
})

const setBreakpoint = (state, { isMobile, breakpoint }) => ({
  ...state,
  isMobile: isMobile,
  breakpoint: breakpoint
})

const requestFile = (state) => ({
  ...state,
  fileError: null
})

const requestSuccess = (state) => ({
  ...state,
  fileError: null
})

const requestFailure = (state, fileError) => ({
  ...state,
  fileError
})

mirror.model({
  name: 'meta',
  initialState: {
    breakpoint: 3,
    colors: {
      primary: '#007bFF',
      secondary: '#6c757d',
      success: '#28a745',
      info: '#17a2b8',
      warning: '#ffc107',
      danger: '#dc3545',
      light: '#f8f9fa',
      dark: '#343a40'
    },
    isMobile: false,
    fileError: null
  },
  reducers: {
    setColors,
    setBreakpoint,
    requestFile,
    requestSuccess,
    requestFailure
  },
  effects: {
    setBreakpointIfChanged: ({ isMobile, breakpoint }, getState) => {
      const current = getState().meta
      if (current.breakpoint !== breakpoint || current.isMobile !== isMobile) actions.meta.setBreakpoint({ isMobile, breakpoint })
    }
  }
})
