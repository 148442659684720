import styled from 'styled-components'

const Box = styled.div`
  margin: 20px 0;
  padding: 20px 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(100, 100, 100, 0.4);
  border-radius: 4px;
  box-sizing: border-box;
`

export default Box
