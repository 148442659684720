import React from 'react'

function withBasicForm (WrappedComponent) {
  return class extends React.Component {
    constructor (props) {
      super(props)
      this.state = {}
    }

    syncState = newState => {
      this.setState({
        ...newState
      })
    }

    /**
     * Special Impact filter made for financial aid
     */
    handleButtonImpactToggle = name => val => {
      const value =
      this.state[name] === null || this.state[name] === undefined
        ? 1
        : undefined
      this.setState({
        [name]: value
      })
    }

    handleButtonImpactSet = name => val => {
      const value = val
        ? 1
        : -1
      this.setState({
        [name]: value
      })
    }

    /**
     * Custom Toggle Buttons we utilize
     */
    handleButtonToggle = name => val => {
      const value =
        this.state[name] === null || this.state[name] === undefined
          ? true
          : undefined
      this.setState({
        [name]: value
      })
    }

    handleButtonBoolSet = name => val => {
      this.setState({
        [name]: val
      })
    }

    handleMultiSelectChange = name => array => {
      const value = (array || []).map(v => v.value)
      this.setState({
        [name]: value
      })
    }

    /**
     * Main form handler
     */
    handleInputChange = event => {
      const target = event.target
      const value = target.type === 'checkbox' ? target.checked : target.value
      const name = target.name

      this.setState({
        [name]: value
      })
    }

    render () {
      return (
        <WrappedComponent
          handleInputChange={this.handleInputChange}
          handleMultiSelectChange={this.handleMultiSelectChange}
          handleButtonToggle={this.handleButtonToggle}
          handleButtonBoolSet={this.handleButtonBoolSet}
          handleButtonImpactToggle={this.handleButtonImpactToggle}
          handleButtonImpactSet={this.handleButtonImpactSet}
          syncState={this.syncState}
          formState={this.state}
          {...this.props}
        />
      )
    }
  }
}

export default withBasicForm
