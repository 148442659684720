import React, { Component } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import SimpleTable from '../Table/SimpleTable/SimpleTable'
import Box from '../Styled/Box'
import styled from 'styled-components'
import { getValue } from '../../utils/helpers'
import dayjs from 'dayjs'
import { connect, actions } from 'mirrorx'
import Editor from './Editor'
import Controls from './Controls'
import MobileTable from './MobileTable'
import DefaultRow from './DefaultRow'
import DefaultEditHeader from './DefaultEditHeader'
import { Overlay } from '../../styles/Components/Common'

const TableBox = styled(Box)`
  padding: 20px;
  overflow: auto;
  flex: 1;
`

const MobileTableBox = styled(Box)`
  padding: 10px 0;
  margin: 0;
  overflow: auto;
  flex: 1;
`

const MobileEditBox = styled(Box)`
  padding: 20px;
  margin: 0;
  overflow: auto;
  flex: 1;
`

const EditBox = styled(Box)`
  padding: 20px;
  flex: 1;
  overflow-y: auto;
`

const FlexCol = styled(Col)`
  display: flex;
`

class ListEditor extends Component {
  state = {
    search: '',
    showDetails: false,
    edit: false
  }

  form = React.createRef()

  toggleEdit = () => {
    const { edit } = this.state
    this.setEdit({ to: !edit })
  }

  setEdit = ({ to: value }) => {
    if (this.state.edit !== value) {
      this.setState({ edit: value })
    }
  }

  setDetailsVisible = ({ to: value }) => {
    if (this.state.showDetails !== value) {
      this.setState({ showDetails: value })
    }
  }

  getCurrent = id => {
    const { name, getCurrent } = this.props.dataSource
    actions[name][getCurrent](id)
  }

  getList = () => {
    const { name, getList } = this.props.dataSource
    actions[name][getList]()
  }

  handleSearch = ({ name, value }) => {
    this.setState({ search: (value || '').toLowerCase() })
  }

  handleAdd = () => {
    const { history, newResourcePath } = this.props
    const { name, clearCurrent } = this.props.dataSource
    this.setEdit({ to: true })
    const currentPath = getValue('history.location.pathname', this.props)
    if (currentPath !== newResourcePath) {
      history.push && history.push(newResourcePath)
      const source = actions[name]
      source && source[clearCurrent]()
      this.form.current && this.form.current.clearForm()
    }
  }

  handleRowClick = id => {
    const { history, root } = this.props
    if (this.state.edit) return
    history.push && history.push(`${root}/${id}`)
    this.getCurrent(id)
  }

  handleClose = () => {
    const { history, root } = this.props
    this.setEdit({ to: false })
    history.push && history.push(root)
  }

  renderDetails = () => {
    const { showDetails, edit } = this.state
    const { Form, loadingOne, resource, addNew, history, dataSource, root, isMobile, Header, actions, list } = this.props
    const id = getValue('id', this.props)

    if (!showDetails) {
      return null
    }

    if (isMobile) {
      return (
        <FlexCol xs={12} className='h-100'>
          <MobileEditBox>
            <Editor
              id={id}
              root={root}
              edit={edit}
              list={list}
              Form={Form}
              addNew={addNew}
              history={history}
              actions={actions}
              formRef={this.form}
              resource={resource}
              loading={loadingOne}
              dataSource={dataSource}
              Header={Header || DefaultEditHeader}
              toggleEdit={this.toggleEdit}
              onHeaderClick={this.toggleEdit}
              onCloseClick={this.handleClose}
            />
          </MobileEditBox>
        </FlexCol>
      )
    }

    return (
      <FlexCol xs={6} className='h-100'>
        <EditBox>
          <Editor
            id={id}
            root={root}
            edit={edit}
            Form={Form}
            list={list}
            addNew={addNew}
            history={history}
            actions={actions}
            formRef={this.form}
            resource={resource}
            loading={loadingOne}
            dataSource={dataSource}
            Header={Header || DefaultEditHeader}
            toggleEdit={this.toggleEdit}
            onHeaderClick={this.toggleEdit}
            onCloseClick={this.handleClose}
          />
        </EditBox>
      </FlexCol>
    )
  }

  searchAndDeleted = item => {
    const { tableHeaders } = this.props
    const { search } = this.state

    const searchColumns = tableHeaders.map(col => col.accessor)
    return !item.deleted && searchColumns.some(acc => {
      const value = String(getValue(acc, item) || '').toLowerCase()
      return value.includes(search)
    })
  }

  componentDidMount () {
    const id = getValue('match.params.id', this.props)
    if (id) this.getCurrent(id)
    this.getList()
  }

  componentDidUpdate (prevProps) {
    const currentId = getValue('match.params.id', this.props)
    const { addNew, dataSource } = this.props
    const detailsNotVisible = !this.state.showDetails
    const addNewChangedToTrue = addNew && addNew !== prevProps.addNew
    const idInRoute = currentId !== undefined
    const source = actions[dataSource]

    if (addNewChangedToTrue) {
      source && source.clearCurrent()
      this.form.current && this.form.current.clearForm()
    }

    if (addNewChangedToTrue || (detailsNotVisible && currentId !== undefined)) {
      this.setDetailsVisible({ to: true })
      source && source.clearCurrent()
      return
    }

    if (!addNew) {
      const idChanged = currentId !== getValue('match.params.id', prevProps)

      if (idInRoute && idChanged) {
        this.setEdit({ to: false })
      }

      this.setDetailsVisible({ to: idInRoute })
    } else {
      this.setDetailsVisible({ to: true })
      this.setEdit({ to: true })
    }
  }

  renderTable = () => {
    const { loading, list, tableHeaders, resource, isMobile, rowComponent, actions } = this.props
    const { showDetails, edit } = this.state
    if (isMobile) {
      return showDetails ? null : (
        <FlexCol className='h-100' xs={12}>
          <MobileTableBox>
            <Controls
              style={{ margin: '0 8px' }}
              actions={actions}
              resource={resource}
              handleAdd={this.handleAdd}
              handleSearch={this.handleSearch}
            />
            <MobileTable
              loading={loading}
              handleRowClick={this.handleRowClick}
              rowComponent={rowComponent}
              data={list.filter(this.searchAndDeleted)}
            />
          </MobileTableBox>
        </FlexCol>
      )
    } else {
      const selectedId = getValue('props.match.params.id', this)

      return (
        <FlexCol className='h-100' xs={showDetails ? 6 : 12}>
          {edit ? <Overlay /> : null}
          <TableBox aria-hidden={edit}>
            <Controls
              actions={actions}
              resource={resource}
              handleAdd={this.handleAdd}
              handleSearch={this.handleSearch}
            />
            <SimpleTable
              selectedId={selectedId}
              loading={loading}
              headers={tableHeaders}
              handleRowClick={this.handleRowClick}
              data={list.filter(this.searchAndDeleted)}
            />
          </TableBox>
        </FlexCol>
      )
    }
  }

  render () {
    const { isMobile } = this.props
    const style = { padding: 0 }
    return (
      <Container
        style={isMobile ? style : {}}
        fluid
        className='h-100'
      >
        <Row className='h-100'>
          {this.renderTable()}
          {this.renderDetails()}
        </Row>
      </Container>
    )
  }
}

ListEditor.defaultProps = {
  Controls: () => null,
  Editor: () => null,
  list: [],
  loading: false,
  loadingOne: false,
  error: null,
  errorOne: null,
  actions: {},
  tableHeaders: [
    { display: 'Name', accessor: 'name' },
    { display: 'Created', accessor: 'created' },
    { display: 'Last Modified', accessor: 'modified' }
  ]
}

const mapStateToProps = (state, props) => {
  const sourceName = props.dataSource.name
  const store = getValue(sourceName, state)
  if (!store) {
    throw new Error(`No redux store found for resource ${sourceName}`)
  }
  const list = state[sourceName].list.map(item => {
    return {
      ...item,
      created: dayjs(item.created).format('MM/DD/YYYY'),
      modified: dayjs(item.modified).format('MM/DD/YYYY h:mma')
    }
  })
  const actions = getValue('actions', store)

  return {
    list,
    actions,
    tableHeaders: props.tableHeaders,
    error: state[sourceName].error,
    loading: state[sourceName].loading,
    errorOne: state[sourceName].errorOne,
    loadingOne: state[sourceName].loadingOne,
    isMobile: state.meta.isMobile
  }
}

ListEditor.defaultProps = {
  rowComponent: DefaultRow
}

export default connect(mapStateToProps)(ListEditor)
