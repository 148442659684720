export const voteBuilder = {
  basis: {},
  addVote: vote => obj => ({
    ...obj,
    vote
  }),
  addReason: reason => obj => ({
    ...obj,
    reason
  }),
  addId: id => obj => ({
    ...obj,
    id
  }),
  addType: type => obj => ({
    ...obj,
    type
  }),
  addUser: user => obj => ({
    ...obj,
    user
  }),
  addGeneric: name => value => obj => ({
    ...obj,
    name: value
  })
}
