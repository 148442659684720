import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import FormManager from '../Form/FormManager'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import TextArea from '../Form/TextArea'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import { FormButton } from '../../styles/Components/Common'
import { omit } from '../../utils/helpers'
import { actions, connect } from 'mirrorx'
import ErrorAlert from '../ErrorAlert/ErrorAlert'

const FormTitle = styled.h4`
  font-size: 1.2em;
  padding-bottom: 15px;
  font-weight: bold;
`

const Question = styled.p`
  margin-bottom: 7px;
`

const LessonsLearned = ({ release, errors }) => {
  const form = React.createRef()

  const postForm = async data => {
    await actions.release.createLessonsLearned({ releaseid: release.id, data })
  }

  const closeChange = async () => {
    await actions.change.closeChange(release.change.id)
  }

  const handleSubmit = async ({ event, data }) => {
    if (form.current.checkValidity()) {
      // Close the release
      await postForm(omit(data, 'close'))

      // Close the change if requested
      await data.close && closeChange()
    }
  }

  const handleCancel = (touched, resetForm) => () => {
    const prompt = 'Are you sure you want to cancel the lessons learned?'
    if (!touched || (touched && window.confirm(prompt))) {
      resetForm && resetForm()
      form.current.reset()
    }
  }

  const additionalReleasesAllowed = release.change.type === 'normal'
  return (
    <Container>
      <Col xs={12}>
        <FormTitle>Post Implementation Review</FormTitle>
      </Col>
      <Row>
        <FormManager initialValues={{}}>
          {({
            handleChange,
            values,
            touched,
            validated,
            onSubmit,
            resetForm
          }) => (
            <Col xs={12} lg={8}>
              <Form
                ref={form}
                noValidate
                validated={validated}
                style={{ width: '100%' }}
              >
                <Col xs={12}>
                  <TextArea
                    name='lessons'
                    label='Lessons Learned'
                    optional
                    onChange={handleChange}
                  />
                </Col>
                {additionalReleasesAllowed && <Col xs={12}>
                  <Question>
                    Should the original change request stay open?
                  </Question>
                  <div className='form-check py-3'>
                    <input
                      className='form-check-input'
                      style={{ marginTop: '0.2rem' }}
                      type='radio'
                      onChange={() => handleChange({ name: 'close', value: false })}
                      value='false'
                      id='keep-open'
                      name='close'
                      required
                    />
                    <label
                      className='form-check-label'
                      htmlFor='keep-open'>
                      Keep change request open. Additional releases pending.
                    </label>
                  </div>
                  <div className='form-check pb-3'>
                    <input
                      className='form-check-input'
                      style={{ marginTop: '0.2rem' }}
                      type='radio'
                      onChange={() => handleChange({ name: 'close', value: true })}
                      value='true'
                      id='close-please'
                      name='close'
                      required
                    />
                    <label
                      className='form-check-label'
                      htmlFor='close-please'
                    >
                      Close change request. No additional releases needed.
                    </label>
                    <div className='invalid-feedback'>
                      Please choose an answer.
                    </div>
                    <div className='valid-feedback'>Looks good!</div>
                  </div>
                </Col>}
                <ErrorAlert errors={errors} />
                <Col xs={12}>
                  <ButtonToolbar className='my-3'>
                    <FormButton
                      onClick={onSubmit(handleSubmit)}
                    >
                      Submit
                    </FormButton>
                    <FormButton
                      className='muted'
                      onClick={handleCancel(touched, resetForm)}
                    >
                      Cancel
                    </FormButton>
                  </ButtonToolbar>
                </Col>
              </Form>
            </Col>
          )}
        </FormManager>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  const errors = Array.of(state.release.error)
  return {
    errors
  }
}

export default connect(mapStateToProps)(LessonsLearned)
