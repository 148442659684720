import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import DashRow from './DashRow'
import { connect } from 'mirrorx'
import EmptyState from '../../Empty/EmptyState'

const Title = styled.h3`
  font-size: ${props => props.theme.dashboard.table.title.fontSize};
`

const DashList = ({ title, history, user, data, emptyState, actionable = false }) => {
  const handleRowClick = (type, id) => () => {
    history.push(`/${type}/${id}`)
  }

  const renderRow = (item, index) => {
    return (
      <DashRow
        key={`${item.id}-${item.modelName}`}
        item={item}
        user={user}
        actionable={actionable}
        onClick={handleRowClick}
      />
    )
  }

  if (!data.length) {
    return <EmptyState {...emptyState} />
  }

  return (
    <Container fluid>
      <Title>{title}</Title>
      {data.map(renderRow)}
    </Container>
  )
}
const mapStateToProps = state => {
  const user = state.auth.current
  const loading = !Object.keys(user).length
  return {
    user,
    loading
  }
}

export default connect(mapStateToProps)(DashList)
