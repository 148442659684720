import React from 'react'
import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import dayjs from 'dayjs'
import { countVotes, get, initCap } from '../../../utils/helpers'
import SimpleEmptyState from '../../Table/SimpleTable/SimpleEmptyState'

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Text = styled.li`
  margin: 2px;
`

const Label = styled.span`
  font-weight: bold;
  font-size: 14px;
`

const ResponsiveTable = ({ history, changes }) => {
  const handleCardClick = ({ type, id }) => {
    id && history.push(`/${type}/${id}`)
  }

  const ChangeCard = ({ change: request, handleCardClick }) => (
    <Card
      style={{ margin: '5px 0' }}
      tabIndex='0'
      onClick={() => handleCardClick({ type: request.modelName, id: request.id })}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleCardClick({ type: request.modelName, id: request.id })
        }
      }}
    >
      <Card.Header>#{request.id} {request.title || request.change.title}</Card.Header>
      <Card.Body>
        <List>
          <Label>Team</Label>
          <Text>
            {get(['team', 'name'], request) ||
              get(['change', 'team', 'name'], request)}
          </Text>
          <Label>Type</Label>
          <Text>{initCap(request.type)}</Text>
          <Label>Prod Team</Label>
          <Text>
            {get(['prodteam', 'name'], request) ||
              get(['change', 'prodteam', 'name'], request)}
          </Text>
          <Label>Status</Label>
          <Text>{initCap(request.status)}</Text>
          <Label>Opened</Label>
          <Text>{dayjs(request.created).format('MM-DD-YYYY')}</Text>
          {request.expires && (
            <>
              <Label>Expires</Label>
              <Text>{dayjs(request.expires).format('MM-DD-YYYY')}</Text>
            </>
          )}
          {request.modelName === 'change' && (
            <>
              <Label>Releases</Label>
              <Text>{request.releases.length}</Text>
            </>
          )}
          <Label>Votes</Label>
          <Text>{countVotes(request)}</Text>
        </List>
      </Card.Body>
    </Card>
  )

  const renderChangeCard = change => (
    <ChangeCard
      handleCardClick={handleCardClick}
      change={change}
      key={Math.random().toString()}
    />
  )

  return (
    <>
      {changes.map(renderChangeCard)}
      {changes.length === undefined || changes.length <= 0 ? (
        <SimpleEmptyState height='220px' />
      ) : null}
    </>
  )
}

export default ResponsiveTable
