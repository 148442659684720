import React from 'react'
import { ListEditorConnect } from '../ListEditorProvider'
import { StyledHeaderInContentBox, PaddedInContentBox } from '../../../styles/Components/ContentBox'
import { CloseButton, Icon } from '../../../styles/Components/Common'
import { LinkButton } from '../../../styles/Components/FormButtons'
import { Loader } from '../../../styles/Components/Loader'
import { ListEditorForm } from './Form'

class Editor extends React.Component {
  render () {
    const { item, editing, addNew, loading, error, FormFields, Detail, closeEditor, setEditing } = this.props
    return <>
      <StyledHeaderInContentBox level='2' title={item.name || (addNew ? 'Create New' : ' ')}>
        {(!editing || error) && <CloseButton alt={`Close ${item.name || ''}`} onClick={closeEditor} />}
      </StyledHeaderInContentBox>
      {loading || error ? (
        <PaddedInContentBox>
          <Loader error={error} />
        </PaddedInContentBox>
      ) : (<>
        {editing ? FormFields && (
          <PaddedInContentBox>
            <ListEditorForm FormFields={FormFields} />
          </PaddedInContentBox>
        ) : Detail && (<>
          <PaddedInContentBox>
            <Detail item={item} />
          </PaddedInContentBox>
          <PaddedInContentBox>
            <LinkButton onClick={setEditing}><Icon className='fas fa-pencil-alt' /> Edit</LinkButton>
          </PaddedInContentBox>
        </>)}
      </>)}
    </>
  }
}

export default ListEditorConnect(state => {
  return {
    item: state.item,
    editing: state.editing,
    addNew: state.editing && !state.selected,
    loading: state.loading,
    error: state.error,
    closeEditor: state.closeEditor,
    setEditing: state.setEditing
  }
})(Editor)
