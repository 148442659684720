import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  cursor: pointer;

  &:hover {
    background-color: #DEDEDE80;
  }
`
const Separator = styled.div`
  background-color: #A0A0A0A0;
  height: 1px;
  margin: 0 0 0 12px;
`

const Title = styled.span`
  font-size: 22px;
  color: #363534;
`

const Arrow = styled.i`
  font-size: 22px;
  color: #A0A0A0A0;
`

class TeamRow extends Component {
  onClick = () => {
    const { onClick, id } = this.props
    onClick && onClick(id)
  }

  render () {
    const { name, first } = this.props
    return (
      <>
        {first ? <Separator /> : null}
        <Container onClick={this.onClick}>
          <Title>{name}</Title>
          <Arrow className='fas fa-caret-right' />
        </Container>
        <Separator />
      </>
    )
  }
}

export default TeamRow
