// https://calvinandhobbes.fandom.com/wiki/Transmogrifier
import { CHANGE_TYPES, RELEASE_TYPES } from './constants'

const DOCUMENT_TYPES = {
  request: 'Client Request',
  signoff: 'Client Signoff',
  other: 'Other'
}

export const displayDocumentType = type => DOCUMENT_TYPES[type] || 'Document Type'
export const displayChangeType = type => CHANGE_TYPES[type]
export const displayReleaseType = type => RELEASE_TYPES[type]
