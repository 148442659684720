/* global FormData */
import axios from 'axios'
import NProgress from 'nprogress'
import qs from 'qs'

class API {
  constructor () {
    this.baseURL = (process.env.BASE_URL || '') + '/api/'
    this.onUpload = { onUploadProgress: this.updateProgress }
    this.changeAPI = this.baseURL
      ? axios.create({
        baseURL: this.baseURL,
        ...this.onUpload
      })
      : axios.create({ ...this.onUpload })

    this.changeAPI.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' })

    this.changeAPI.interceptors.response.use(
      res => res,
      err => {
        const { status } = err.response || {}
        switch (status) {
          case 401:
            document.cookie = 'login_redirect=' + window.location.href + '; path=/;'
            window.location.href = '/api/login'
            return Promise.reject(new Error('unauthenticated'))
          default:
            return Promise.reject(err.response ? err.response.data : err)
        }
      }
    )
  }

  async get (endpoint, params = {}) {
    const { data } = await this.changeAPI.get(endpoint, { params })
    return data || {}
  }

  async post (endpoint, body = {}) {
    const { data } = await this.changeAPI.post(endpoint, body)
    return data || {}
  }

  async put (endpoint, body = {}) {
    const { data } = await this.changeAPI.put(endpoint, body)
    return data || {}
  }

  async delete (endpoint, payload = {}) {
    const { data } = await this.changeAPI({
      method: 'DELETE',
      url: endpoint,
      data: payload
    })
    return data || {}
  }

  // the data object passed to this function should contain an
  // `uploads` field that consists of an array of javascript File objects
  // see https://developer.mozilla.org/en-US/docs/Web/API/File
  async postWithFiles (endpoint, data) {
    const formData = new FormData()
    const postData = { ...data }
    postData.uploads = []
    for (const file of data.uploads) {
      formData.append('uploads', file)
      postData.uploads.push({
        filename: file.name,
        type: file.docType
      })
    }
    formData.append('data', JSON.stringify(postData))
    const { data: ret } = await this.changeAPI.post(endpoint, formData, {
      headers: { 'Content-Type': 'mulipart/form-data' }
    })
    return ret || {}
  }

  pauseProgress () {
    this.pausedProgress = true
  }

  updateProgress = ({ loaded, total }) => {
    if (!this.pausedProgress) NProgress.set(loaded / total)
  }

  resumeProgress () {
    this.pausedProgress = false
  }

  createQuestion ({ id, body, type }) {
    return this.changeAPI.post(`${type}/${id}/question`, { body })
  }

  answerQuestion ({ id, body }) {
    return this.changeAPI.post(`question/${id}/answer`, { body })
  }

  getChanges (params) {
    return this.get('change', params)
  }

  getChange (id) {
    return this.get(`change/${id}`)
  }

  createChange (data) {
    return this.postWithFiles('change', data)
  }

  cancelChange (id, data) {
    return this.changeAPI.delete(`change/${id}`, data)
  }

  closeChange (id) {
    return this.changeAPI.post(`change/${id}/close`, {})
  }

  getChangePerms () {
    return this.get('change?id=1')
  }

  getNeedsVoteChanges () {
    return this.get('change?needsmyvote=change')
  }

  getNeedsVoteReleases () {
    return this.get('release?needsmyvote=true')
  }

  getNeedsVoteStandards () {
    return this.get('standard?needsmyvote=true')
  }

  getNeedsAction (type) {
    return Promise.all([
      this.get(`change?needsaction=${type}`),
      this.get(`standard?needsaction=${type}`)
    ])
  }

  getAwaitingAction (type) {
    return Promise.all([
      this.get(`change?awaitingaction=${type}`),
      this.get(`standard?awaitingaction=${type}`)
    ])
  }

  getReleases (params) {
    return this.get('release', params)
  }

  getRelease (id) {
    return this.get(`release/${id}`)
  }

  createRelease (changeid, data) {
    return this.postWithFiles(`change/${changeid}/release`, data)
  }

  cancelRelease (id, data) {
    return this.changeAPI.delete(`release/${id}`, data)
  }

  createReleaseReport (releaseid, data) {
    return this.changeAPI.post(`release/${releaseid}/report`, data)
  }

  createLessonsLearned (releaseid, data) {
    return this.changeAPI.post(`release/${releaseid}/close`, data)
  }

  getTeams () {
    return this.get('team')
  }

  getTeam (id) {
    return this.get(`team/${id}`)
  }

  postTeam (team) {
    return this.post('team', {
      name: team.name,
      shortname: team.shortname,
      department: team.department,
      users: team.users,
      cab: team.cab,
      defaultprodteam: team.defaultprodteam,
      manager: team.manager,
      releasecab: {
        id: 1
      }
    })
  }

  putTeam (team) {
    return this.put(`team/${team.id}`, {
      name: team.name,
      shortname: team.shortname,
      department: team.department,
      users: team.users,
      cab: team.cab,
      defaultprodteam: team.defaultprodteam,
      manager: team.manager,
      releasecab: {
        id: 1
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  deleteTeam (team) {
    return this.delete(`team/${team.id}`)
  }

  getClients () {
    return this.get('client')
  }

  getClient (id) {
    return this.get(`client/${id}`)
  }

  postClient (client) {
    return this.post('client', { name: client.name })
  }

  putClient (client) {
    return this.put(
      `client/${client.id}`,
      { name: client.name },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }

  deleteClient (client) {
    return this.delete(`client/${client.id}`)
  }

  getSystems () {
    return this.get('system')
  }

  getSystem (id) {
    return this.get(`system/${id}`)
  }

  postSystem (system) {
    return this.post('system', { ...system, important: !!system.important })
  }

  putSystem (system) {
    return this.put(`system/${system.id}`, { ...system, important: !!system.important })
  }

  deleteSystem (system) {
    return this.delete(`system/${system.id}`)
  }

  getAllUsers () {
    return this.get('user', { includeorphans: 'true' })
  }

  getUsers () {
    return this.get('user')
  }

  getUser (id) {
    return this.get(`user/${id}`)
  }

  getCurrentUser () {
    return this.get('user/me')
  }

  logoutUser () {
    return this.get('logout')
  }

  postUser ({ fullname, username, admin }) {
    const body = {
      fullname,
      username,
      admin
    }
    return this.post('user', body)
  }

  putUser ({ id, fullname, username, admin, releaseProxy }) {
    const body = {
      fullname,
      username,
      admin,
      releaseProxy: (releaseProxy || {}).value
    }
    return this.put(`user/${id}`, body)
  }

  deleteUser ({ id }) {
    return this.delete(`user/${id}`)
  }

  getStandards (params) {
    return this.get('standard', params)
  }

  getStandard (id) {
    return this.get(`standard/${id}`)
  }

  createStandard (data) {
    return this.postWithFiles('standard', data)
  }

  deleteStandard (id, params) {
    return this.delete(`standard/${id}`, params)
  }

  getImpacts () {
    return this.get('impact')
  }

  getImpact (id) {
    return this.get(`impact/${id}`)
  }

  postVote ({ id, type, vote }) {
    return this.changeAPI.post(`${type}/${id}/vote`, vote)
  }
}

export default new API()
