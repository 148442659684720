import mirror, { actions } from 'mirrorx'
import api from '../api/api'

const mergeDocuments = (list) => {
  const docs = []
  for (let i = 0; i < list.length; i++) {
    docs.push(...list[i].documents)
  }
  return { documents: docs }
}

mirror.model({
  name: 'dashboard',
  initialState: {
    active: {
      list: [],
      personal: true,
      hasFetched: false,
      loading: false
    },
    pending: {
      list: [],
      personal: true,
      hasFetched: false,
      loading: false
    },
    voting: {
      changes: [],
      releases: [],
      standards: [],
      hasFetched: false,
      loading: false
    },
    error: null,
    actions: {},
    hasFetchedPerms: false
  },
  reducers: {
    activeToggle: state => ({
      ...state,
      active: {
        ...state.active,
        personal: !state.active.personal
      }
    }),
    pendingToggle: state => ({
      ...state,
      pending: {
        ...state.pending,
        personal: !state.pending.personal
      }
    }),
    needsVoteChangesSuccess: (state, { documents: changes = [] }) => ({
      ...state,
      error: null,
      voting: {
        ...state.voting,
        hasFetched: true,
        changes
      }
    }),
    needsVoteReleasesSuccess: (state, { documents: releases = [] }) => ({
      ...state,
      error: null,
      voting: {
        ...state.voting,
        hasFetched: true,
        releases
      }
    }),
    needsVoteStandardsSuccess: (state, { documents: standards = [] }) => ({
      ...state,
      error: null,
      voting: {
        ...state.voting,
        hasFetched: true,
        standards
      }
    }),
    requestData: (state) => ({
      ...state,
      error: null
    }),
    requestNeedsAction: (state) => ({
      ...state,
      active: {
        ...state.active,
        loading: true
      }
    }),
    requestAwaitingAction: (state) => ({
      ...state,
      pending: {
        ...state.pending,
        loading: true
      }
    }),
    requestFailure: (state, error) => ({
      ...state,
      error
    }),
    needsActionSuccess: (state, { documents: list = [] }) => ({
      ...state,
      active: {
        ...state.active,
        hasFetched: true,
        loading: false,
        list
      }
    }),
    needsActionFailure: (state, error) => ({
      ...state,
      active: {
        ...state.active,
        hasFetched: false,
        loading: false
      },
      error
    }),
    awaitingActionSuccess: (state, { documents: list = [] }) => ({
      ...state,
      pending: {
        ...state.pending,
        hasFetched: true,
        loading: false,
        list
      }
    }),
    awaitingActionFailure: (state, error) => ({
      ...state,
      pending: {
        ...state.pending,
        hasFetched: false,
        loading: false
      },
      error
    }),
    requestPerms: (state) => ({
      ...state,
      actions: {},
      hasFetchedPerms: false
    }),
    permsSuccess: (state, actions) => ({
      ...state,
      actions,
      hasFetchedPerms: true
    }),
    permsFailure: (state, error) => ({
      ...state,
      hasFetchedPerms: false,
      actions: {},
      error: error
    })
  },
  effects: {
    async getNeedsVoteChanges () {
      actions.dashboard.requestData()
      try {
        actions.dashboard.needsVoteChangesSuccess(await api.getNeedsVoteChanges())
      } catch (error) {
        actions.dashboard.requestFailure({ error: error.message })
      }
    },
    async getNeedsVoteReleases () {
      actions.dashboard.requestData()
      try {
        actions.dashboard.needsVoteReleasesSuccess(await api.getNeedsVoteReleases())
      } catch (error) {
        actions.dashboard.requestFailure({ error: error.message })
      }
    },
    async getNeedsVoteStandards () {
      actions.dashboard.requestData()
      try {
        actions.dashboard.needsVoteStandardsSuccess(await api.getNeedsVoteStandards())
      } catch (error) {
        actions.dashboard.requestFailure({ error: error.message })
      }
    },
    async getNeedsAction (_, getState) {
      actions.dashboard.requestNeedsAction()
      try {
        const type = getState().dashboard.active.personal ? 'me' : 'team'
        actions.dashboard.needsActionSuccess(mergeDocuments(await api.getNeedsAction(type)))
      } catch (error) {
        actions.dashboard.needsActionFailure({ error: error.message })
      }
    },
    async getAwaitingAction (_, getState) {
      actions.dashboard.requestAwaitingAction()
      try {
        const type = getState().dashboard.pending.personal ? 'me' : 'team'
        actions.dashboard.awaitingActionSuccess(mergeDocuments(await api.getAwaitingAction(type)))
      } catch (error) {
        actions.dashboard.awaitingActionFailure({ error: error.message })
      }
    },
    async getChangePerms () {
      actions.dashboard.requestPerms()
      try {
        actions.dashboard.permsSuccess((await api.getChangePerms()).actions)
      } catch (error) {
        actions.dashboard.permsFailure({ error: error.message })
      }
    },
    toggle (type) {
      switch (type) {
        case 'active':
          actions.dashboard.activeToggle()
          break
        case 'pending':
          actions.dashboard.pendingToggle()
          break
        default:
          break
      }
    }
  }
})
