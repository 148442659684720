import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import { generatestring } from '../../utils/helpers'

const Input = styled(Form.Control)`
  border-radius: 25px;
  height: 80%;
  border-color: black;
  &::placeholder {
    text-align: center;
  }
`

class Search extends Component {
  constructor () {
    super()
    this.labelid = generatestring()
  }

  onChange = e => {
    const { onChange } = this.props
    onChange && onChange(e.target)
  }

  render () {
    const { value, placeholder, style } = this.props
    return (
      <Form.Group style={{ ...style }}>
        <label className='sr-only' htmlFor={this.labelid}>Search</label>
        <Input
          id={this.labelid}
          value={value}
          onChange={this.onChange}
          placeholder={`${placeholder}`}
        />
      </Form.Group>
    )
  }
}

export default Search
