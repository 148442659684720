import { Component } from 'react'
import ReactDOM from 'react-dom'

/**
 * @Documentation https://reactjs.org/docs/portals.html
 */
class Portal extends Component {
  constructor (props) {
    super(props)
    this.portalRoot = document.getElementById('portal')
    this.el = document.createElement('div')
  }

  componentDidMount () {
    this.portalRoot.appendChild(this.el)
  }

  componentWillUnmount () {
    this.portalRoot.removeChild(this.el)
  }

  render () {
    const { children } = this.props
    return ReactDOM.createPortal(children, this.el)
  }
}

export default Portal
