import React from 'react'
import styled from 'styled-components'
import MyWorkMyTeam from './MyWorkMyTeam'

const Title = styled.p`
  font-size: ${props => props.theme.dashboard.header.fontSize};
  margin: 0;
  user-select: none;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.dashboard.header.background};
  padding: ${props => props.theme.dashboard.header.padding};
  color: ${props => props.theme.dashboard.header.textColor};
  border-radius: 4px 4px 0 0;
`

const ActionIcon = styled.div`
  font-size: ${props => props.theme.dashboard.header.icon.fontSize};
  color: ${props => props.theme.dashboard.header.icon.iconColor};
`

const BoxHeader = ({ title, personal, type, isMobile, isOpen, onClick }) => {
  const renderSecondary = () => {
    if (isMobile) {
      return <ActionIcon className={'fas ' + (isOpen ? 'fa-angle-up' : 'fa-angle-down')} />
    }

    if (personal !== undefined) {
      return <MyWorkMyTeam personal={personal} type={type} />
    }

    return null
  }
  return (
    <HeaderRow onClick={isMobile ? onClick : undefined}>
      <Title>{title}</Title>
      {renderSecondary()}
    </HeaderRow>
  )
}

export default BoxHeader
