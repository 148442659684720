import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import SimpleHeader from './SimpleHeader'
import SimpleBody from './SimpleBody'
import TableLoader from '../../Loading/TableLoader'
import SimpleEmptyState from './SimpleEmptyState'

class SimpleTable extends Component {
  state = {
    sort: {
      accessor: null,
      order: 'none'
    }
  }

  handleDelete = (id) => {
    console.log(`Deleting ${id}`)
  }

  handleSort = (accessor) => {
    const { sort } = this.state
    if (accessor !== sort.accessor) {
      sort.order = 'ascending'
      sort.accessor = accessor
    } else {
      if (sort.order === 'ascending') {
        sort.order = 'descending'
      } else if (sort.order === 'descending') {
        sort.order = 'none'
      } else if (sort.order === 'none') {
        sort.order = 'ascending'
      }
    }
    this.setState({ sort })
  }

  render () {
    const { headers, data, handleRowClick, styles, loading, errors, selectedId } = this.props

    const headerStyle = styles.header || {}
    const bodyStyle = styles.body || {}

    const { sort } = this.state
    return (
      <>
        <Table responsive hover style={styles}>
          <SimpleHeader
            style={headerStyle}
            headers={headers}
            sortProps={sort}
            handleSort={this.handleSort}
          />
          {loading
            ? <TableLoader headers={headers} errors={errors} />
            : (
              <SimpleBody
                style={bodyStyle}
                data={data}
                headers={headers}
                sortProps={sort}
                handleRowClick={handleRowClick}
                selectedId={selectedId}
              />)}
        </Table>
        {!loading && (data.length === undefined || data.length <= 0) ? <SimpleEmptyState /> : null}
      </>
    )
  }
}

SimpleTable.defaultProps = {
  styles: {
    backgroundColor: '#FFFFFF',
    header: {
      borderBottom: 0,
      borderTop: 0,
      content: {
        fontVariant: 'small-caps',
        fontSize: 18
      }
    }
  }
}

export default SimpleTable
