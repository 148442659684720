const { Machine, actions } = require('xstate')
const { assign } = actions

const addFiles = assign({
  fileCount: (ctx, event) => ctx.fileCount + event.value
})

const removeOneFile = assign({
  fileCount: (ctx, event) => {
    if (ctx.fileCount <= 1) return 0
    return ctx.fileCount - 1
  }
})

const removeAllFiles = assign({
  fileCount: () => 0
})

const enableError = assign({
  errorMessage: (ctx, event) => event.value
})

const disableError = assign({
  errorMessage: () => null
})

const ACTIONS = {
  toggleDrag: () => ({ type: 'TOGGLE_DRAG' }),
  enableError: (message) => ({ type: 'ENABLE_ERROR', value: message }),
  disableError: () => ({ type: 'DISABLE_ERROR' }),
  selectInternal: () => ({ type: 'SELECT_INTERNAL' }),
  selectExternal: () => ({ type: 'SELECT_EXTERNAL' }),
  addFiles: (count) => ({ type: 'ADD_FILES', value: count }),
  removeFile: () => ({ type: 'REMOVE_FILE' }),
  removeAllFiles: () => ({ type: 'REMOVE_ALL_FILES' })
}

const uploadMachine = Machine({
  id: 'upload',
  type: 'parallel',
  context: {
    fileCount: 0,
    errorMessage: null
  },
  states: {
    drag: {
      initial: 'off',
      states: {
        on: {
          on: { TOGGLE_DRAG: 'off' }
        },
        off: {
          on: { TOGGLE_DRAG: 'on' }
        }
      }
    },
    error: {
      initial: 'off',
      states: {
        on: {
          on: {
            DISABLE_ERROR: { target: 'off', actions: [disableError] }
          }
        },
        off: {
          on: {
            ENABLE_ERROR: { target: 'on', actions: [enableError] }
          }
        }
      }
    },
    client: {
      initial: 'internal',
      states: {
        internal: {
          on: {
            SELECT_EXTERNAL: 'external'
          }
        },
        external: {
          on: {
            SELECT_INTERNAL: 'internal'
          }
        }
      }
    },
    files: {
      initial: 'empty',
      states: {
        empty: {
          on: {
            ADD_FILES: [
              {
                target: 'many',
                actions: [addFiles],
                cond: (ctx, event) => {
                  return event.value > 1
                }
              },
              {
                target: 'one',
                actions: [addFiles]
              }
            ]
          }
        },
        one: {
          on: {
            REMOVE_FILE: { target: 'empty', actions: [removeOneFile] },
            ADD_FILES: { target: 'many', actions: [addFiles] }
          }
        },
        many: {
          on: {
            REMOVE_ALL_FILES: {
              target: 'empty',
              actions: [removeAllFiles]
            },
            REMOVE_FILE: [
              {
                target: 'one',
                cond: (ctx, event) => {
                  return ctx.fileCount > 1
                },
                actions: [removeOneFile]
              },
              {
                target: 'empty',
                actions: [removeOneFile]
              }
            ]
          }
        }
      }
    }
  }
})

module.exports = {
  uploadMachine,
  actions: ACTIONS
}
