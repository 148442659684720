import React from 'react'
import DashTable from '../../Table/DashboardTable/DashTable'
import DashList from '../../Table/DashboardTable/DashList'
import Loader from '../../Loading/Loader'

const headers = [
  { display: 'Request #', accessor: 'id' },
  { display: 'Title', accessor: 'title' }
]

const PendingDisplay = ({ pendingWork: { list, loading }, history, errors, isMobile }) => {
  const emptyState = {
    quote: 'Enjoy your peace of mind',
    description: 'There are no requests waiting on other people.'
  }

  if (loading) {
    return <Loader errors={errors} />
  }
  return isMobile
    ? <DashList history={history} emptyState={emptyState} data={list} />
    : <DashTable history={history} emptyState={emptyState} headers={headers} data={list} />
}
export default PendingDisplay
