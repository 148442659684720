import React from 'react'
import EmptyState from '../Empty/EmptyState'

const NotFound = () => {
  const options = {
    quote: 'You seem to be lost in space',
    description: 'Go back to your dashboard',
    linkto: '/',
    highlight: true,
    viewBox: '0 20 294.53 160.79',
    height: '350px'
  }

  return (
    <EmptyState {...options} />
  )
}
export default NotFound
