import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import FormManager from '../Form/FormManager'
import FormSelect from '../Form/FormSelect/FormSelect'
import API from '../../api/api'
import { actions } from 'mirrorx'
import Banner from '../../utils/banner'
import TextEdit from '../Form/TextEdit'
import FormButtons from '../Form/FormButtons'
import SpecialImpact from './SpecialImpact'
import { withTheme } from 'styled-components'

const sendSuccess = (message) => {
  Banner.sendSuccess({ message, duration: 3000 })
}

const sendError = (message) => {
  Banner.sendError({ message, duration: 3000 })
}

class SystemForm extends Component {
  handleSubmit = async ({ data, event }) => {
    event && event.preventDefault()
    const name = data.name || ''
    const id = data.id || null
    console.log('data: ', data)

    if (name.length && name.length <= 0) return

    if (id) {
      this.submitUpdatedSystem(data)
    } else {
      this.submitNewSystem(data)
    }
  }

  submitNewSystem = async (data) => {
    try {
      const res = await API.postSystem(data)
      sendSuccess(`Successfully created ${data.name}`)
      const { root, history } = this.props

      history.push(`${root}/${res.id}`)

      actions.system.getSystems()
      actions.system.getSystem(res.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  submitUpdatedSystem = async (data) => {
    try {
      await API.putSystem(data)
      sendSuccess(`Successfully updated ${data.name}`)
      this.props.toggleEdit()
      actions.system.getSystems()
      actions.system.getSystem(data.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  onCancel = () => {
    const { edit, addNew, toggleEdit } = this.props
    if (edit) {
      toggleEdit()
      this.resetForm()
    }

    if (edit && addNew) {
      this.props.handleClose()
    }
  }

  getInputStyle = () => {
    const { theme } = this.props
    let style = {}
    if (this.props.edit === false) {
      style = {
        border: 'solid 1px #F0F0F0',
        borderRadius: '0.25em',
        paddingLeft: '10px',
        backgroundColor: '#F8F9FA'
      }
    } else {
      style = {
        border: `solid 1px ${theme.common.colors.lightBlue}`
      }
    }
    return style
  }

  initialValues = () => {
    const { data } = this.props
    return {
      name: data.name,
      important: data.important,
      subscribers: data.subscribers || [],
      id: data.id || null
    }
  }

  form = React.createRef()

  clearForm = () => {
    const form = this.form.current
    if (!form) return

    form.updateValues({ name: 'id', value: null }, { name: 'name', value: '' }, { name: 'important', value: null }, { name: 'subscribers', value: [] })
    this.users && this.users.clearValue()
  }

  resetForm = () => {
    const form = this.form.current
    if (!form) return
    form.resetForm()
  }

  handleDelete = async () => {
    const { data, toggleEdit, userCanDelete, history, root } = this.props
    if (userCanDelete && data.id) {
      await API.deleteSystem(data)
      sendSuccess(`Successfully deleted ${data.name}`)
      toggleEdit()
      actions.system.getSystems()
      history.push && history.push(root)
    }
  }

  onClickSubmit = (onSubmit) => () => {
    const submitEdit = onSubmit(this.handleSubmit)
    submitEdit()
  }

  onClickEdit = () => {
    const { userCanEdit, toggleEdit, edit, addNew } = this.props
    if (!edit && (userCanEdit || addNew)) {
      toggleEdit()
    }
  }

  render () {
    const { edit, userCanEdit, userCanDelete, handleClose } = this.props

    return (
      <FormManager ref={this.form} initialValues={this.initialValues()}>
        {
          ({ values, handleChange, onSubmit, resetForm, touched, validated }) => {
            return (
              <Form
                onSubmit={onSubmit(this.handleSubmit)}
                validated={validated}
              >
                <Form.Group>
                  <TextEdit
                    required
                    label='System Name'
                    feedback='A system is required to have a name'
                    readOnly={!edit}
                    plaintext={!edit}
                    style={this.getInputStyle()}
                    name='name'
                    placeholder='enter system name'
                    value={values.name || ''}
                    onChange={handleChange}
                  />
                  <SpecialImpact
                    name='important'
                    value={values.important}
                    isActive={edit}
                    activeLabel={() => 'Is this system flagged for auditing?'}
                    inactiveLabel={(isImportant) => {
                      if (isImportant) return 'This system is flagged for auditing.'
                      return ''
                    }}
                    onChange={({ name, value }) => handleChange({ name, value })}
                  />
                  <br />
                  <FormSelect
                    label='Subscribers'
                    ref={c => {
                      this.users = c && c.getWrappedInstance()
                    }}
                    emptyMessage={`${values.name || 'This system'} has no subscribers`}
                    placeholder='find and add subscribers...'
                    isDisabled={!edit}
                    source='team'
                    options='editData.users'
                    getSource='initEdit'
                    hideDropdown={!edit}
                    values={values.subscribers}
                    dataMap={user => ({
                      id: user.id,
                      value: user.username,
                      label: user.fullname
                    })}
                    onChange={users => {
                      handleChange({
                        name: 'subscribers',
                        value: users.map(user => ({ id: user.id, fullname: user.label, username: user.value }))
                      })
                    }}
                  />
                </Form.Group>
                <FormButtons
                  edit={edit}
                  userCanEdit={userCanEdit}
                  showDelete={edit && userCanDelete}
                  onClickDelete={this.handleDelete}
                  onClickCancel={this.onCancel}
                  onClickClose={handleClose}
                  onClickSubmit={this.onClickSubmit(onSubmit)}
                  onClickEdit={this.onClickEdit}
                />
              </Form>
            )
          }
        }
      </FormManager>
    )
  }
}

export default withTheme(SystemForm)
