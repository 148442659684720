import React from 'react'
import { actions, connect } from 'mirrorx'
import Loader from '../Loading/Loader'
import StandardInfo from './StandardInfo'
import VotingCollapsable from '../Voting/VotingCollapsable'
import ExpiringActions from './ExpiringActions'
import { ContentBoxContainer } from '../../styles/Components/ContentBox'

class StandardDetails extends React.Component {
  componentDidMount () {
    const { id } = this.props.match.params
    actions.standard.getStandard(id)
  }

  renderDetails () {
    const { standard } = this.props
    return (
      <ContentBoxContainer>
        <StandardInfo standard={standard} />
        <VotingCollapsable doc={standard} />
        <ExpiringActions doc={standard} history={this.props.history} />
      </ContentBoxContainer>
    )
  }

  render () {
    const { loading, errors } = this.props
    return loading ? <Loader errors={errors} /> : this.renderDetails()
  }
}

const mapStateToProps = state => {
  const standard = state.standard.current
  const loading = !Object.keys(standard).length
  const errors = Array.of(state.standard.error)
  return {
    loading,
    standard,
    errors
  }
}
export default connect(mapStateToProps)(StandardDetails)
