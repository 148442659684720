import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import $ from 'jquery'
import { useHistory } from 'react-router-dom'
import { connect, actions } from 'mirrorx'
import BootStrapNavDropdown from 'react-bootstrap/NavDropdown'
import SignOutModal from '../Portal/SignOutModal'

const Container = styled.header`
  position: ${props => (props.isMobile ? 'fixed' : 'absolute')};
  background-color: ${props => props.theme.header.background};
  box-shadow: ${props => props.theme.header.boxShadow};
  align-items: center;
  padding: 0 0 0 16px;
  min-height: 65px;
  max-height: 65px;
  display: flex;
  width: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: ${props => (props.isMobile && !props.collapsed ? 250 : 0)}px;
`

const HamburgerIcon = styled.div`
  font-size: 2.5em;
  color: ${props => props.theme.header.hamburger.inactiveColor};

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: ${props => props.theme.header.hamburger.hoverColor};
  }
`

const AnimatedContainer = styled.div`
  display: none;
`

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const NavDropdown = styled(BootStrapNavDropdown)`
  span {
    color: ${props => props.theme.header.actionLink.textColor};
    user-select: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      color: ${props => props.theme.common.colors.lightBlue};
      text-decoration: none;
    }
  }

`

const Header = ({ className, user, isMobile, collapsed }) => {
  const [modalOpen, setModal] = useState(false)
  const history = useHistory()

  const handleModalOpen = () => {
    setModal(true)
  }

  const handleModalClose = () => {
    setModal(false)
  }

  const handleMenuKeyPress = useCallback(e => {
    if (e.key === 'Enter') {
      this.hamburgerClick()
    }
  }, [])

  const hamburgerClick = useCallback(() => {
    actions.layout.toggleSidebar()
  }, [])

  const handleUserNavigation = useCallback(() => {
    history.push(`/users/${user.id}`)
  }, [user.id, history])

  useEffect(() => {
    $('#user-main').show()
  }, [])

  return (
    <>
      <Container
        className={className}
        isMobile={isMobile}
        collapsed={collapsed}
      >
        <HamburgerIcon
          className='ion-android-menu'
          tabIndex='0'
          onKeyDown={handleMenuKeyPress}
          onClick={hamburgerClick}
        />
        <UserContainer>
          <AnimatedContainer id='user-main'>
            <NavDropdown title={<span tabIndex={modalOpen ? '-1' : '0 '}>{user.fullname || 'Sign Out'}</span>}>
              <NavDropdown.Item onClick={handleUserNavigation}>
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleModalOpen}>
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          </AnimatedContainer>
        </UserContainer>
      </Container>
      {modalOpen ? <SignOutModal closeModal={handleModalClose} /> : null}
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.current,
    isMobile: state.meta.isMobile,
    collapsed: state.layout.sidebarCollapsed
  }
}

export default connect(mapStateToProps)(Header)
