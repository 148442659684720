import React, { PureComponent } from 'react'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import { ToggleButton } from '../../styles/Components/Common'

class YesNoButtonSelect extends PureComponent {
  handleChange = value => e => {
    const { name, onChange } = this.props
    e.preventDefault()
    onChange && onChange({ name, value })
  }

  selected = (value) => {
    if (value) return 'selected'
    return ''
  }

  render () {
    const { name, value } = this.props
    return (
      <ToggleButtonGroup
        type='radio'
        size='sm'
        name={name}
        feedback='Please choose an answer'
        required
      >
        <ToggleButton
          onClick={this.handleChange(true)}
          className={this.selected(value)}
          required
        >
          Yes
        </ToggleButton>
        <ToggleButton
          onClick={this.handleChange(false)}
          className={this.selected(!value)}
          required
        >
          No
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }
}

export default YesNoButtonSelect
