export const CHANGE_TYPES = {
  normal: 'Normal',
  expedited: 'Expedited',
  standard: 'Standard'
}

export const RELEASE_TYPES = {
  normal: 'Normal',
  expedited: 'Expedited'
}

export const REQUEST_STATUS = {
  review: 'review',
  approved: 'approved',
  release: 'release',
  preclosure: 'preclosure',
  denied: 'denied',
  cancelled: 'cancelled',
  closed: 'closed',
  reported: 'reported'
}

export const REQUEST_ICONS = {
  change: 'icon-change-orbit',
  release: 'icon-release-rocket',
  standard: 'icon-masters-key'
}

export const CHANGE_STATUS = {
  review: 'In Review',
  approved: 'Approved',
  release: 'Release',
  preclosure: 'Preclosure',
  denied: 'Denied',
  cancelled: 'Cancelled',
  closed: 'Closed'
}

export const RELEASE_STATUS = {
  review: 'In Review',
  approved: 'Approved',
  reported: 'Reported',
  denied: 'Denied',
  cancelled: 'Cancelled',
  closed: 'Closed'
}

export const STANDARD_STATUS = {
  review: 'In Review',
  approved: 'Approved',
  denied: 'Denied',
  cancelled: 'Cancelled'
}

export const IMPACT_IDS = {
  finAid: 1
}

export const DASH_TITLES = {
  voting: 'NEEDS MY APPROVAL',
  active: 'THINGS I CAN DO',
  pending: 'WAITING ON OTHERS'
}

export const LISTEDITOR_TYPES = {
  system: {
    singular: 'System',
    plural: 'Systems'
  },
  client: {
    singular: 'Client',
    plural: 'Clients'
  },
  user: {
    singular: 'User',
    plural: 'Users'
  },
  team: {
    singular: 'Team',
    plural: 'Teams'
  }
}

export const VOTING_TYPES = {
  approved: {
    display: 'Approve',
    displayPast: 'Approved',
    action: 'approve'
  },
  denied: {
    display: 'Deny',
    displayPast: 'Denied',
    action: 'deny'
  },
  na: {
    display: 'N/A',
    displayPast: 'N/A',
    action: 'notapplicable'
  },
  pending: {
    display: 'Pending'
  }
}

export const UPLOAD_TYPES = {
  request: 'Client Request',
  signoff: 'Client Signoff',
  other: 'Other'
}

export const FILTERS = {
  startdate: 'Start Date',
  enddate: 'End Date',
  q: 'Title / Description',
  prodonly: 'Prod Only',
  type: 'Type',
  status: 'Status',
  creator: 'Ticket Owner',
  devteam: 'Change Team',
  prodteam: 'Prod Team',
  system: 'Systems',
  client: 'Primary Client',
  commplan: 'Communication Plan',
  downtime: 'Downtime',
  impact: 'Financial Aid Impact',
  lonewolf: 'Lone Wolf',
  team: 'Team'
}

export const EXPLANATIONS = {
  lonewolf:
    'Lone Wolf: When the primary developer is also the person who moved the change into production.'
}

export const REFRESH_SECONDS = 2
