import React from 'react'
import dayjs from 'dayjs'
import SimpleTable from '../../Table/SimpleTable/SimpleTable'
import { countVotes, initCap } from '../../../utils/helpers'

const StandardsList = ({ standards, history }) => {
  const handleRowClick = id => {
    id && history.push(`/standard/${id}`)
  }

  const flattenStandard = standard => {
    const { id, title, status, expires, team, prodteam } = standard
    return {
      id,
      title,
      status: initCap(status),
      teamName: team.name,
      prodTeam: prodteam.name,
      expires: dayjs(expires).format('MM-DD-YYYY'),
      votes: countVotes(standard)
    }
  }

  const flatStandards = standards.map(standard => flattenStandard(standard))
  const columns = [
    { display: '#', accessor: 'id' },
    { display: 'Title', accessor: 'title' },
    { display: 'Team', accessor: 'teamName' },
    { display: 'Prod Team', accessor: 'prodTeam' },
    { display: 'Status', accessor: 'status' },
    { display: 'Expires', accessor: 'expires' },
    { display: 'Votes', accessor: 'votes' }
  ]
  return (
    <SimpleTable
      headers={columns}
      data={flatStandards}
      handleRowClick={handleRowClick}
    />
  )
}

export default StandardsList
