import mirror, { actions } from 'mirrorx'
import api from '../api/api'
import { cacheTime } from '../utils/helpers'
import { REFRESH_SECONDS } from '../utils/constants'

mirror.model({
  name: 'change',
  initialState: {
    lastRefresh: cacheTime(),
    hasFetchedCurrent: false,
    voteError: null,
    lastPage: null,
    loading: null,
    error: null,
    actions: {},
    current: {},
    sort: []
  },
  reducers: {
    requestChange: state => ({
      ...state,
      current: {},
      hasFetchedCurrent: false,
      loading: true,
      error: null
    }),
    requestCancel: state => ({
      ...state,
      loading: true,
      error: null
    }),
    requestClose: state => ({
      ...state,
      loading: true,
      error: null
    }),
    changeSuccess: (state, data) => ({
      ...state,
      loading: false,
      hasFetchedCurrent: true,
      lastRefresh: cacheTime(),
      error: null,
      current: data
    }),
    changeFailure: (state, error) => ({
      ...state,
      hasFetchedCurrent: false,
      loading: false,
      error
    }),
    voteSuccess: (state, data) => ({
      ...state,
      loading: false,
      lastRefresh: cacheTime(),
      voteError: null,
      current: data
    }),
    voteFailure: (state, voteError) => ({
      ...state,
      loading: false,
      voteError
    }),
    formInit: state => ({
      ...state,
      error: null
    }),
    createInit: state => ({
      ...state,
      loading: true,
      error: null
    }),
    createSuccess: (state, data) => ({
      ...state,
      loading: false,
      lastRefresh: cacheTime(),
      voteError: null,
      error: null,
      current: data
    }),
    createQuestionSuccess: (state, data) => {
      const current = { ...state.current }
      current.questions.push(data)
      return {
        ...state,
        loading: false,
        error: null,
        voteError: null,
        current
      }
    },
    createFailure: (state, error) => ({
      ...state,
      loading: false,
      error: error
    }),
    cancelSuccess: (state, data) => ({
      ...state,
      loading: false,
      error: null
    }),
    cancelFailure: (state, error) => ({
      ...state,
      loading: false,
      error: error
    }),
    closeSuccess: (state, data) => ({
      ...state,
      loading: false,
      error: null,
      hasFetchedCurrent: true,
      lastRefresh: cacheTime(),
      current: data
    }),
    closeFailure: (state, error) => ({
      ...state,
      loading: false,
      error: error
    }),
    createAnswerSuccess: (state, { data, id }) => {
      const current = { ...state.current }
      const index = current.questions.findIndex(q => q.id === id)
      index >= 0 && current.questions[index].answers.push(data)
      return {
        ...state,
        loading: false,
        voteError: null,
        error: null,
        current: current
      }
    },
    useCache: state => ({
      ...state,
      loading: false
    })
  },
  effects: {
    async getChange (id, getState) {
      const { change } = getState()
      const empty = !Object.keys(change.current).length
      const different = change.current.id !== parseInt(id)
      const stale = cacheTime() - change.lastRefresh > REFRESH_SECONDS
      if (empty || different || stale) {
        actions.change.requestChange()
        try {
          actions.change.changeSuccess(await api.getChange(id))
        } catch (error) {
          actions.change.changeFailure({ error: error.message })
        }
      } else {
        actions.change.useCache()
      }
    },
    async createChange (data) {
      actions.change.createInit()
      try {
        actions.change.createSuccess(await api.createChange(data))
      } catch (error) {
        actions.change.createFailure({
          error: error.message,
          validation: error.validationerrors
        })
      }
    },
    async createQuestion (data) {
      try {
        const { data: question } = await api.createQuestion(data)
        actions.change.createQuestionSuccess(question)
      } catch (error) {
        actions.change.createFailure({ error: error.message })
      }
    },
    async cancelChange ({ id, reason }) {
      actions.change.requestCancel()
      try {
        await api.cancelChange(id, { data: { reason } })
        actions.change.changeSuccess(await api.getChange(id))
      } catch (error) {
        actions.change.cancelFailure({ error: error.message })
      }
    },
    async closeChange (changeid) {
      actions.change.requestClose()
      try {
        const ret = await api.closeChange(changeid)
        actions.change.closeSuccess(ret.data)
      } catch (error) {
        actions.change.closeFailure({ error: error.message })
      }
    },
    async answerQuestion (data) {
      try {
        const answer = await api.answerQuestion(data)
        actions.change.createAnswerSuccess({ data: answer.data, id: data.id })
      } catch (error) {
        actions.change.createFailure({ error: error.message })
      }
    },
    async postVote (data) {
      try {
        actions.change.voteSuccess((await api.postVote(data)).data)
      } catch (error) {
        actions.change.voteFailure({ error: error.message })
      }
    }
  }
})
