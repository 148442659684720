import React, { Component } from 'react'
import SimpleHeaderCell from './SimpleHeaderCell'
import styled from 'styled-components'

const Cell = styled.td``

class SimpleCell extends Component {
  getSortDirection = () => {
    const { sortProps, accessor } = this.props
    let direction = 'none'
    if (sortProps.accessor === accessor) {
      if (sortProps.order === 'ascending') {
        direction = 'up'
      } else if (sortProps.order === 'descending') {
        direction = 'down'
      }
    }
    return direction
  }

  render () {
    const { header, content, handleSort, accessor, sortOn, style, handleRowClick, wrapaggressive } = this.props

    let cell = <Cell onClick={handleRowClick} style={(wrapaggressive ? { wordBreak: 'break-word' } : {})}>{content}</Cell>

    if (header) {
      cell = (
        <SimpleHeaderCell
          style={style}
          accessor={accessor}
          sortOn={sortOn}
          cellContent={() => <span style={style.content}>{content.toLowerCase()}</span>}
          handleSort={handleSort}
          direction={this.getSortDirection()}
        />
      )
    }

    return cell
  }
}

export default SimpleCell
