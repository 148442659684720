import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { pick } from 'txstate-utils'
import TeamSelect from './TeamSelect'
import FormHeader from './FormHeader'
import TicketTitle from './TicketTitle'
import PrimaryClient from './PrimaryClient'
import DocumentUpload from '../Form/DocumentUpload/DocumentUpload'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Question from './Question'
import ChangeType from '../Form/ButtonSelect'
import TextArea from '../Form/TextArea'
import { Prompt } from 'react-router'
import FormManager from '../Form/FormManager'
import { displayChangeType } from '../../utils/transmogrifier'
import { CHANGE_TYPES } from '../../utils/constants'
import SpecialImpact from './SpecialImpact'
import TagSelect from '../Form/TagSelect'
import { actions, connect } from 'mirrorx'
import Loader from '../Loading/Loader'
import ErrorAlert from '../ErrorAlert/ErrorAlert'
import StandardSelect from './StandardSelect'
import ChangeSubInfo from './ChangeSubInfo'
import { get } from '../../utils/helpers'
import Box from '../Styled/Box'
import { FormButton } from '../../styles/Components/Common'

const COLS = 12
const changeTypes = Object.keys(CHANGE_TYPES)

const ChangeBox = styled(Box)`
  margin: ${({ isMobile }) => isMobile ? '0' : '20px 0'};
`

const SectionTitle = styled.h2`
  font-size: 1.4em;
  padding-bottom: 5px;
`

class ChangeRequest extends Component {
  componentDidMount () {
    actions.change.formInit()
    actions.team.getTeams()
    actions.client.getClients()
    actions.system.getSystems()
    actions.impact.getImpacts()
    actions.standardList.getStandards({ devteam: 'mine', status: 'approved', startdate: '2000-01-01T00:00:00.000Z' })
  }

  componentDidUpdate (prevProps) {
    const { newChange } = this.props
    if (newChange && prevProps.newChange !== newChange) {
      this.formRedirect(this.props.newChange)
    }
  }

  state = {
    validationFailed: null
  }

  initialFormState = props => ({
    commplan: false,
    prodonly: false,
    downtime: false,
    standard: '',
    title: '',
    actionrisk: '',
    inactionrisk: '',
    client: { id: 0 },
    team: {
      id: get(['user', 'team', 'id'], props)
    },
    prodteam: {
      id: get(['user', 'team', 'defaultprodteam', 'id'], props)
    },
    type: 'normal',
    systems: [],
    uploads: [],
    impacts: []
  })

  form = React.createRef()

  documentUpload = React.createRef()

  specialImpacts = React.createRef()

  affectedSystems = React.createRef()

  formIsValid = () => {
    const specialImpactsAnswered = this.specialImpacts.current.isValid()
    const formIsValid = this.form.current.checkValidity()
    const documentUploadHasRequiredFiles = this.documentUpload.current.isValid()
    const affectedSystemsAreSelected = this.affectedSystems.current.isValid()

    return (
      formIsValid &&
      specialImpactsAnswered &&
      documentUploadHasRequiredFiles &&
      affectedSystemsAreSelected
    )
  }

  onSubmit = ({ event, data }) => {
    event.preventDefault()
    event.stopPropagation()
    if (this.formIsValid()) {
      this.postForm(data)
    } else {
      this.setState({
        validationFailed: true
      })
    }
  }

  postForm = async data => {
    let standard
    if (typeof data.standard !== 'undefined') {
      const id = parseInt(data.standard)
      if (id > 0) standard = { id: parseInt(data.standard) }
    }
    const payload = {
      ...data,
      systems: pick(data.systems, 'id'),
      standard
    }
    await actions.change.createChange(payload)
  }

  formRedirect (change) {
    const { history } = this.props
    history.push(`/change/${change.id}`)
  }

  onCancel = e => {
    const { history } = this.props
    history && history.goBack()
  }

  render () {
    const { loading, errors } = this.props
    return loading ? <Loader errors={errors} /> : this.renderForm()
  }

  renderForm () {
    const {
      owner,
      user,
      teams,
      clients,
      acceptedFileTypes,
      approvedStandards,
      specialimpacts,
      systems,
      errors,
      isMobile
    } = this.props

    return (
      <Container style={isMobile ? { padding: 0 } : null}>
        <ChangeBox isMobile={isMobile}>
          <FormManager initialValues={this.initialFormState(this.props)}>
            {({ handleChange, values, touched, validated, onSubmit }) => (
              <>
                <Form
                  ref={this.form}
                  noValidate
                  validated={validated}
                  style={{ width: '100%' }}
                >
                  <Prompt
                    message={location =>
                      !touched ||
                      (touched && location.pathname.match(/\/change\/[0-9]+$/i))
                        ? true
                        : 'You will lose your data if you leave this page'}
                  />
                  <Container>
                    <Col xs={COLS}>
                      <FormHeader
                        title='CREATE A CHANGE REQUEST'
                        owner={owner}
                      />
                    </Col>
                  </Container>
                  <Container>
                    <ChangeSubInfo
                      fullname={user.fullname}
                      team={get(['team', 'name'], user) || 'None'}
                    />
                  </Container>
                  <Container
                    className='py-2'
                    style={{ background: '#F8F9FA' }}
                  >
                    <Col xs={COLS}>
                      <ChangeType
                        label='Request Type'
                        isMobile={isMobile}
                        name='type'
                        value={values.type}
                        values={changeTypes}
                        valueToDisplay={displayChangeType}
                        onChange={handleChange}
                      />
                    </Col>
                    {values.type === 'standard' ? (
                      <Col xs={COLS}>
                        <StandardSelect
                          label='Master Standard'
                          name='standard'
                          values={approvedStandards}
                          onChange={handleChange}
                          disabled={!approvedStandards.length}
                        />
                        {!approvedStandards.length ? (
                          <div className='alert alert-warning'>There are no approved master standards for your team.</div>
                        ) : null}
                      </Col>
                    ) : null}
                    <Col xs={COLS}>
                      <Question
                        isMobile={isMobile}
                        label='Production Only'
                        value={values.prodonly}
                        name='prodonly'
                        onChange={handleChange}
                      />
                    </Col>
                  </Container>
                  <Container className='py-3'>
                    <Col xs={COLS}>
                      <SectionTitle>TICKET DETAILS</SectionTitle>
                      <TicketTitle
                        id='title'
                        name='title'
                        value={values.title}
                        label={<i className='fas fa-ticket-alt' />}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TeamSelect
                        name='prodteam'
                        label='Production Team'
                        value={values.prodteam.id}
                        values={teams}
                        required
                        feedback='A production team is required. It can be your own team.'
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TextArea
                        label='Change Request Details'
                        name='description'
                        placeholder='Outline the change details and explain why this change is necessary'
                        feedback='Please enter a detailed description of this change request'
                        value={values.description}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <Question
                        isMobile={isMobile}
                        name='commplan'
                        label='Communication Plan Needed'
                        value={values.commplan}
                        onChange={handleChange}
                      />
                    </Col>
                  </Container>
                  <Container className='py-3'>
                    <Col xs={COLS}>
                      <SectionTitle>IMPACT</SectionTitle>
                    </Col>
                    <Col xs={COLS}>
                      <Question
                        isMobile={isMobile}
                        name='downtime'
                        label='Will this change require downtime?'
                        value={values.downtime}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TagSelect
                        formValidated={validated}
                        name='systems'
                        ref={c => {
                          this.affectedSystems.current = c && c.getWrappedInstance()
                        }}
                        options={systems}
                        onChange={handleChange}
                        value={values.systems?.map(s => ({ value: s.id, label: s.name }))}
                        invalid={
                          this.state.validationFailed && !values.systems.length
                        }
                        feedback='Please select at least one affected system'
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TextArea
                        name='impacted'
                        label='What is the impact of this change?'
                        placeholder='Describe the impact of this change'
                        instructions='Consider who will be impacted by this change, positively or negatively'
                        feedback='Please describe the impact of this change'
                        value={values.impacted}
                        onChange={handleChange}
                      />
                    </Col>
                  </Container>
                  <Container>
                    <Col xs={COLS}>
                      <SectionTitle>CLIENT</SectionTitle>
                      <PrimaryClient
                        name='client'
                        value={values.client}
                        values={clients}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <DocumentUpload
                        name='uploads'
                        ref={this.documentUpload}
                        formValidated={validated}
                        fileTypes={acceptedFileTypes}
                        required={values.client.id !== 0 && ['expedited', 'normal'].includes(values.type)}
                        requiredDoc={acceptedFileTypes[0].id}
                        values={values.uploads}
                        onChange={handleChange}
                      />
                    </Col>
                  </Container>
                  <Container
                    className='py-2'
                    style={{ background: '#F8F9FA' }}
                  >
                    <Col xs={COLS}>
                      <SpecialImpact
                        name='impacts'
                        formValidated={validated}
                        ref={this.specialImpacts}
                        required
                        value={values.impacts}
                        values={specialimpacts}
                        onChange={handleChange}
                        isActive={values.type !== 'standard'}
                        feedback='Please state whether this impacts Financial Aid'
                        label='Will this change have a primary impact on Financial Aid?'
                        requestedImpact={1}
                      />
                    </Col>
                  </Container>
                </Form>
                <ErrorAlert errors={errors} />
                <Container>
                  <Col xs={COLS}>
                    <ButtonToolbar className='pt-3'>
                      <FormButton
                        onClick={onSubmit(this.onSubmit)}
                      >
                        Submit
                      </FormButton>
                      <FormButton
                        className='muted'
                        onClick={this.onCancel}
                      >
                        Cancel
                      </FormButton>

                    </ButtonToolbar>
                  </Col>
                </Container>
              </>
            )}
          </FormManager>
        </ChangeBox>
      </Container>
    )
  }
}

ChangeRequest.defaultProps = {
  acceptedFileTypes: [
    { name: 'Change Request', id: 'request' },
    { name: 'Other', id: 'other' }
  ]
}

const mapStateToProps = state => {
  const internalProject = {
    id: 0,
    name: 'Internal Project'
  }
  const teams = state.team.list
  const newChange = state.change.current
  const clients = [internalProject, ...state.client.list]
  const approvedStandards = state.standardList.list
  const systems = state.system.list
  const user = state.auth.current
  const specialimpacts = state.impact.list
  const loading =
    !teams.length ||
    !clients.length ||
    !systems.length ||
    !state.standardList.hasFetchedList ||
    !specialimpacts.length ||
    !Object.keys(user).length
  const errors = Array.of(
    state.team.error,
    state.change.error,
    state.client.error,
    state.system.error,
    state.impact.error,
    state.standard.error,
    state.user.errorCurrent
  )
  return {
    isMobile: state.meta.isMobile,
    teams,
    newChange,
    approvedStandards,
    clients,
    systems,
    user,
    specialimpacts,
    loading,
    errors
  }
}
export default connect(mapStateToProps)(ChangeRequest)
