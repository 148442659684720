import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'

class TextArea extends Component {
  onChange = e => {
    const { onChange } = this.props
    onChange && onChange(e.target)
  }

  render () {
    const {
      value, label, placeholder,
      feedback, instructions, optional,
      labelStyle, name, forwardRef
    } = this.props

    return (
      <Form.Group>
        <Form.Label htmlFor={name} style={labelStyle}>{label}</Form.Label>
        <Form.Control
          ref={forwardRef}
          name={name}
          required={!optional}
          onChange={this.onChange}
          as='textarea'
          rows='3'
          placeholder={placeholder}
          value={value}
          id={name}
        />
        {instructions ? <Form.Text className='text-muted'>{instructions}</Form.Text> : null}
        {feedback ? <Form.Control.Feedback type='invalid'>{feedback}</Form.Control.Feedback> : null}
      </Form.Group>
    )
  }
}

export default TextArea
