import React from 'react'
import styled from 'styled-components'
import { connect } from 'mirrorx'
import { DateWithIcon } from '../../styles/Components/Common'
import dayjs from 'dayjs'
import { isEmpty } from 'txstate-utils'
import { FullWidthInContentBox } from '../../styles/Components/ContentBox'

const DetailPairsBox = styled(FullWidthInContentBox)`
  padding: 30px;
  background-color: #F8F9FA;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  font-size: 0.9rem;
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    padding: 20px;
  }
`

const Left = styled.span`
  font-weight: bold;
`

const Right = styled.span`
`

const DetailPairsColumn = styled.div`
  &.columns3 { width: 33.333%; }
  &.columns2 { width: 50%; }
`

const DetailPairsEntry = styled.div`
`

export const DetailPairs = ({ pairs: potentiallyemptypairs, breakpoint }) => {
  const pairs = potentiallyemptypairs.filter(({ value }) => !isEmpty(value))
  let cols
  if (breakpoint <= 1) cols = [pairs]
  else if (breakpoint <= 3 || pairs.length === 2 || pairs.length === 4) {
    const percol = Math.ceil(pairs.length / 2.0)
    cols = [pairs.slice(0, percol), pairs.slice(percol)]
  } else {
    const percol = Math.ceil(pairs.length / 3.0)
    const secondcol = Math.ceil((pairs.length - percol) / 2.0) + percol
    cols = [
      pairs.slice(0, percol),
      pairs.slice(percol, secondcol),
      pairs.slice(secondcol)
    ]
  }
  return (
    <DetailPairsBox>
      {cols.map((col, idx) => {
        return (
          <DetailPairsColumn key={idx} className={'columns' + cols.length}>
            {col.map(({ name, value, notDate }) => {
              return (
                <DetailPairsEntry key={name}>
                  <Left>{name}: </Left>
                  <Right>{!notDate && dayjs(value).isValid() ? <DateWithIcon date={value} /> : value}</Right>
                </DetailPairsEntry>
              )
            })}
          </DetailPairsColumn>
        )
      })}
    </DetailPairsBox>
  )
}

const mapstatetoprops = state => {
  return { breakpoint: state.meta.breakpoint }
}

export default connect(mapstatetoprops)(DetailPairs)
