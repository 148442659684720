import React from 'react'
import { boolToHuman, listToCommas } from '../../utils/helpers'
import { IMPACT_IDS } from '../../utils/constants'
import DetailPairs from '../RequestDetailSharedComponents/DetailPairs'

const ReleaseSummary = ({ release }) => {
  const financialImpact = release.change.impacts.some(({ id }) => id === IMPACT_IDS.finAid)
  const customer = release.change.client.name ? release.change.client : { name: 'Internal' }
  const change = release.change
  const pairs = [
    { name: 'Release Owner', value: release.creator.fullname },
    { name: 'Change Team', value: change.team.name },
    ...(change.prodteam.id === change.team.id ? [
      { name: 'Primary Developer', value: release.developer.fullname },
      { name: 'Prod Developer', value: release.reviewer.fullname }
    ] : [
      { name: 'Prod Team', value: change.prodteam.name }
    ]),
    { name: 'Primary Customer', value: customer.name },
    { name: 'Submitted', value: release.created },
    ...(release.votecomplete ? [{ name: 'Approved', value: release.votecomplete }] : []),
    { name: 'Intended', value: release.intended },
    { name: 'Systems', value: listToCommas(change.systems), notDate: true },
    { name: 'Prod Only', value: boolToHuman(change.prodonly) },
    { name: 'Financial Aid Impact', value: boolToHuman(financialImpact) },
    { name: 'Needs Downtime', value: boolToHuman(change.downtime) },
    { name: 'Needs Communication', value: boolToHuman(change.commplan) }
  ]
  return (
    <DetailPairs pairs={pairs} />
  )
}

export default ReleaseSummary
