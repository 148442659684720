import React, { Component } from 'react'
import styled from 'styled-components'
import { ActionButton } from '../../styles/Components/Common'

const PlusIcon = styled.i`
  font-size: 1.25rem;
`

class NewChange extends Component {
  onClick = () => {
    const { onClick } = this.props
    onClick && onClick()
  }

  render () {
    return (
      <ActionButton className='create d-flex align-items-center' onClick={this.onClick}>
        New Change Request
        &nbsp; &nbsp;
        <PlusIcon className='ion-android-add'>
          <span className='sr-only'>Create a new change request</span>
        </PlusIcon>
      </ActionButton>
    )
  }
}

export default NewChange
