import React from 'react'
import { SecondaryTitle } from '../../styles/Components/Common'
import { initCap, get } from '../../utils/helpers'

const small = {
  fontSize: '.9rem'
}

const CancelledMessage = ({ closure, status }) => {
  if (!get(['reason'], closure)) {
    return null
  }

  return (
    <>
      <SecondaryTitle className='decorative-bottom'>
        {initCap(status)} by: {get(['user', 'fullname'], closure)}
      </SecondaryTitle>
      <p style={small}>{closure.reason}</p>
    </>
  )
}
export default CancelledMessage
