import React from 'react'
import styled from 'styled-components'
import { Icon } from './Common'

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button:last-child {
    margin-right: 0px;
  }
`

export const LinkButton = styled.button`
  border: 2px solid ${props => props.theme.common.colors.lightBlue};
  background: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  padding: .375rem 1rem;

  &:hover, &:active {
    background-color: ${props => props.theme.common.colors.lightBlue};
    color: #fff;
  }
`

export const SaveButton = styled.button.attrs({
  type: 'button'
})`
  background-color: ${props => props.theme.common.colors.activeBlue};
  color: #FFF;
  border: 1px solid ${props => props.theme.common.colors.activeBlue};
  box-shadow: 0 2px 0 0 ${props => props.theme.common.colors.activeBlue};
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  padding: .375rem 1.5rem;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;

  &:hover, &:active {
    background-color: ${props => props.theme.common.colors.mainBlue};
  }
`

export const CancelButton = styled(SaveButton)`
  background-color: #FFF;
  color: ${props => props.theme.common.colors.darkestGray};
  border: 1px solid ${props => props.theme.common.colors.lavender};
  box-shadow: 0 2px 0 0 ${props => props.theme.common.colors.lavender};

  &:hover, &:active {
    background-color: ${props => props.theme.common.colors.activeBlue};
    color: #FFF;
  }
`

export const DangerButton = styled(SaveButton)`
  background: none;
  color: ${props => props.theme.common.colors.raspberry};
  border: 1px solid ${props => props.theme.common.colors.raspberry};
  box-shadow: 0 2px 0 0 ${props => props.theme.common.colors.darkRaspberry};

  &:hover, &:active {
    background-color: ${props => props.theme.common.colors.raspberry};
    color: #FFF;
  }
`

const StyledCreateButton = styled(LinkButton)`
  background: ${props => props.theme.common.colors.lightBlue};
  color: #fff;
  box-shadow: 0 2px 0 ${props => props.theme.common.colors.lightBlueShadow};
  width: auto;
  display: flex;
  align-items: center;
  &:hover, &:active {
    background: transparent;
    color: ${props => props.theme.common.colors.lightBlue};
  }
  i {
    margin-left: 10px;
    font-size: 1.25rem;
  }
`

export const CreateButton = ({ children, ...props }) => (
  <StyledCreateButton {...props}>{children} <Icon className='ion-android-add' /></StyledCreateButton>
)
