import React from 'react'
import styled from 'styled-components'
import { StyledHeaderInContentBox, PaddedInContentBox } from '../../../styles/Components/ContentBox'
import Table from './Table'
import { CreateButton } from '../../../styles/Components/FormButtons'
import { ListEditorConnect } from '../ListEditorProvider'

const SearchBox = styled.input.attrs({
  className: 'form-control',
  type: 'search',
  placeholder: 'Search...'
})`
  max-width: 30%;
`

class List extends React.PureComponent {
  render () {
    const { title, search, StyledTable, Row, HeaderRow, setAddingNew, updateSearch } = this.props
    return <>
      <StyledHeaderInContentBox title={title}>
        <SearchBox name='search' value={search} onChange={(e) => updateSearch(e.target.value)} aria-label='Filter List by Search String' />
        <CreateButton onClick={setAddingNew}>Create New</CreateButton>
      </StyledHeaderInContentBox>
      <PaddedInContentBox>
        <Table StyledTable={StyledTable} Row={Row} HeaderRow={HeaderRow} />
      </PaddedInContentBox>
    </>
  }
}

export default ListEditorConnect(state => ({ search: state.search, setAddingNew: state.setAddingNew, updateSearch: state.updateSearch }))(List)
