import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import FormManager from '../Form/FormManager'
import FormSelect from '../Form/FormSelect/FormSelect'
import ManagerSelect from './ManagerSelect'
import { actions } from 'mirrorx'
import API from '../../api/api'
import { getValue } from '../../utils/helpers'
import Banner from '../../utils/banner'
import TextEdit from '../Form/TextEdit'
import FormButtons from '../Form/FormButtons'
import { withTheme } from 'styled-components'

const sendSuccess = (message) => {
  Banner.sendSuccess({ message, duration: 3000 })
}

const sendError = (message) => {
  Banner.sendError({ message, duration: 3000 })
}

class TeamForm extends Component {
  form = React.createRef()
  manager = React.createRef()
  cabUsers = null
  users = null

  initialValues = () => {
    const {
      id = null,
      name = '',
      shortname = '',
      manager = {},
      department = '',
      users = [],
      cab = []
    } = this.props.data
    return {
      id,
      name,
      shortname,
      manager,
      department,
      users,
      cab
    }
  }

  validate = (team) => {
    let message = ''
    const hasName = team.name && team.name.length > 0
    const hasShortName = team.shortname && team.shortname.length > 0
    const hasDepartment = team.department && team.department.length > 0
    const hasManager = this.managerIsValid(team)
    const managerIsInTeam = this.managerIsOnTeam(team)

    if (!hasManager) {
      message = 'You have to select a manager to continue'
    } else if (!managerIsInTeam) {
      message = 'You have to select a manager from your team members to continue'
    }

    const result = {
      isValid: hasName && hasShortName && hasDepartment && hasManager && managerIsInTeam,
      message
    }
    return result
  }

  managerIsValid = (team) => {
    const hasManager = !!getValue('manager.id', team)
    return hasManager
  }

  managerIsOnTeam = (team) => {
    return team.users.some(user => user.fullname === team.manager.fullname)
  }

  managerValidation = (team) => {
    const hasManager = this.managerIsValid(team)
    const managerIsOnTeam = this.managerIsOnTeam(team)
    return hasManager && managerIsOnTeam
  }

  handleSubmit = async ({ data, event }) => {
    event && event.preventDefault()

    const id = data.id || null
    const team = this.validate(data)
    if (!team.isValid) {
      return
    }
    if (id) {
      this.submitUpdatedTeam(data)
    } else {
      this.submitNewTeam(data)
    }
  }

  submitNewTeam = async (team) => {
    try {
      const res = await API.postTeam(team)
      sendSuccess(`Successfully created ${team.name}`)
      const { root, history } = this.props

      history.push(`${root}/${res.id}`)
      actions.team.getTeams()
      actions.team.getTeam(res.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  submitUpdatedTeam = async (team) => {
    try {
      const res = await API.putTeam(team)
      sendSuccess(`Successfully updated ${team.name}`)
      this.props.toggleEdit()
      actions.team.getTeams()
      actions.team.getTeam(res.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  getInputStyle = () => {
    const { theme, edit } = this.props
    let style = {
      marginBottom: 8
    }
    if (edit === false) {
      style = {
        ...style,
        border: 'solid 1px #F0F0F0',
        borderRadius: '0.25em',
        paddingLeft: '10px',
        backgroundColor: '#F8F9FA'
      }
    } else {
      style = {
        ...style,
        border: `solid 1px ${theme.common.colors.lightBlue}`
      }
    }
    return style
  }

  onClickSubmit = (onSubmit) => () => {
    onSubmit(this.handleSubmit)()
  }

  onClickEdit = () => {
    const { userCanEdit, toggleEdit, edit, addNew } = this.props
    if (!edit && (userCanEdit || addNew)) {
      toggleEdit()
    }
  }

  clearForm = () => {
    const form = this.form.current
    const manager = this.manager.current
    if (!form) return

    form.updateValues(
      { name: 'id', value: null },
      { name: 'name', value: '' },
      { name: 'shortname', value: '' },
      { name: 'manager', value: {} },
      { name: 'department', value: '' },
      { name: 'users', value: [] },
      { name: 'cab', value: [] }
    )
    this.cabUsers && this.cabUsers.clearValue()
    this.users && this.users.clearValue()
    manager && manager.clearValue()
  }

  resetForm = () => {
    const form = this.form.current
    if (!form) return
    form.resetForm()
  }

  onCancel = () => {
    const { edit, addNew, toggleEdit } = this.props
    if (edit) {
      toggleEdit()
      this.resetForm()
    }

    if (edit && addNew) {
      this.props.handleClose()
    }
  }

  handleDelete = async () => {
    const { data, toggleEdit, userCanDelete, history, root } = this.props
    if (userCanDelete && data.id) {
      await API.deleteTeam(data)
      sendSuccess(`Successfully deleted ${data.name}`)
      toggleEdit()
      actions.team.getTeams()
      history.push && history.push(root)
    }
  }

  render () {
    const { edit, userCanDelete, userCanEdit, handleClose } = this.props

    return (
      <FormManager
        ref={this.form}
        initialValues={this.initialValues()}
      >
        {
          ({ values, handleChange, onSubmit, resetForm, touched, validated }) => {
            return (
              <Form
                onSubmit={onSubmit(this.handleSubmit)}
                validated={validated}
              >
                <Form.Group>
                  <TextEdit
                    required
                    name='name'
                    readOnly={!edit}
                    plaintext={!edit}
                    value={values.name || ''}
                    label='Team Name'
                    feedback='A team is required to have a name'
                    style={this.getInputStyle()}
                    placeholder={'enter the team\'s name'}
                    onChange={handleChange}
                  />
                  <TextEdit
                    required
                    name='shortname'
                    readOnly={!edit}
                    plaintext={!edit}
                    label='Short Name'
                    feedback='A shortened identifier is required for every team'
                    style={this.getInputStyle()}
                    value={values.shortname || ''}
                    placeholder={'enter the team\'s short name'}
                    onChange={handleChange}
                  />
                  <TextEdit
                    required
                    name='department'
                    readOnly={!edit}
                    plaintext={!edit}
                    label='Department'
                    feedback='A team is required to be in a department'
                    style={this.getInputStyle()}
                    value={values.department || ''}
                    placeholder={'enter the team\'s department'}
                    onChange={handleChange}
                  />
                  <br />
                  <FormSelect
                    label='Team Members'
                    ref={c => {
                      this.users = c && c.getWrappedInstance()
                    }}
                    emptyMessage={`${values.name || 'This team'} has no team members`}
                    placeholder='find and add team Members...'
                    isDisabled={!edit}
                    source='team'
                    options='editData.users'
                    getSource='initEdit'
                    hideDropdown={!edit}
                    values={values.users}
                    dataMap={user => ({
                      id: user.id,
                      value: user.username,
                      label: user.fullname
                    })}
                    onChange={users => {
                      handleChange({
                        name: 'users',
                        value: users.map(user => ({ id: user.id, fullname: user.label, username: user.value }))
                      })
                    }}
                  />
                  <ManagerSelect
                    ref={this.manager}
                    isValid={validated ? this.managerValidation(values) : null}
                    errorMessage={this.validate(values).message}
                    placeholder='find and select a team manager...'
                    name='manager'
                    edit={edit}
                    onChange={({ name, value }) => {
                      if (value === null) value = {}
                      handleChange({
                        name: name,
                        value: {
                          id: value.id,
                          username: value.value,
                          fullname: value.label
                        }
                      })
                    }}
                    value={values.manager}
                    managers={values.users}
                  />
                  <FormSelect
                    label='Team CAB Members'
                    ref={c => {
                      this.cabUsers = c && c.getWrappedInstance()
                    }}
                    isDisabled={!edit}
                    source='team'
                    emptyMessage='There are no Team CAB members.'
                    placeholder='find and select team CAB members'
                    options='editData.users'
                    getSource='initEdit'
                    dataMap={cabUser => ({
                      id: cabUser.id,
                      value: cabUser.username,
                      label: cabUser.fullname
                    })}
                    hideDropdown={!edit}
                    values={values.cab}
                    onChange={cab => {
                      handleChange({
                        name: 'cab',
                        value: cab.map(user => ({
                          username: user.value,
                          fullname: user.label,
                          id: user.id
                        }))
                      })
                    }}
                  />
                </Form.Group>
                <FormButtons
                  edit={edit}
                  userCanEdit={userCanEdit}
                  showDelete={edit && userCanDelete}
                  onClickDelete={this.handleDelete}
                  onClickCancel={this.onCancel}
                  onClickClose={handleClose}
                  onClickSubmit={this.onClickSubmit(onSubmit)}
                  onClickEdit={this.onClickEdit}
                />
              </Form>
            )
          }
        }
      </FormManager>
    )
  }
}

export default withTheme(TeamForm)
