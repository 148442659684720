import mirror from 'mirrorx'

export const common = {
  colors: {
    mainBackground: '#F4F6FE',
    lightBlue: '#50B8E4',
    lightBlueShadow: '#1c86b3',
    lavender: '#B4C2F1',
    activeBlue: '#0068DB',
    mainBlue: '#0050A8',
    darkBlue: '#36426B',
    darkestGray: '#32353c',
    darkGray: '#828896',
    lightGray: '#D9D9D9',
    offWhite: '#F8F9FA',
    trueBlack: '#222',
    raspberry: '#CA2F41',
    darkRaspberry: '#BC2C3D',
    tangerine: '#FABE5C',
    pink: '#F56C89'
  }
}

mirror.model({
  name: 'theme',
  initialState: {
    common,
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    },
    mobilebreakpoint: 992,
    header: {
      background: '#ffffff',
      boxShadow: '0 2px 4px rgba(0,0,0,.5)',
      hamburger: {
        inactiveColor: '#1F263E',
        hoverColor: '#50B8E4'
      },
      actionLink: {
        textColor: '#1F263E'
      }
    },
    dashboard: {
      header: {
        background: '#1F263E',
        padding: '12px',
        textColor: '#ffffff',
        fontSize: '1rem',
        icon: {
          iconColor: '#ffffff',
          fontSize: '1.7rem'
        }
      },
      content: {
        background: '#ffffff',
        padding: '12px 0',
        myWorkMyTeam: {
          mobile: {
            boxShadow: 'inset 0px -4px 0px 0px #50B8E4'
          }
        }
      },
      table: {
        title: {
          fontSize: '1.1rem',
          textColor: '#000000'
        },
        header: {
          background: '#F8F9FB',
          borderRight: '1px solid #F8F9FB',
          borderLeft: '1px solid #F8F9FB',
          textColor: '#54606B',
          fontSize: '.9rem'
        },
        row: {
          border: '1px solid #C9CFD4'
        },
        icon: {
          textColor: common.colors.lightBlue,
          fontSize: '1.7rem'
        },
        actionButton: {
          border: `2px solid ${common.colors.lightBlue}`,
          backgroundColor: common.colors.mainBackground
        }
      }
    },
    sidebar: {
      background: '#1F263E',
      title: {
        textColor: '#ffffff',
        paddingTop: '20px',
        textAlign: 'center',
        fontSize: '1.0rem',
        marginBottom: '.6rem'
      },
      hr: {
        opacity: '0.1',
        border: '1px solid #F8F9FB',
        width: '75%',
        sub: {
          width: '60%'
        }
      },
      selectedMenu: {
        iconColor: '#50B8E4',
        background: '#303750',
        textColor: '#ffffff',
        boxShadow: 'inset 5px 0px 0px 0px #70BCFD'
      },
      inactiveMenu: {
        iconColor: '#B0B3BB',
        background: 'none',
        textColor: '#B0B3BB'
      },
      subSection: {
        // Systems Mangement Section
        background: '#141827',
        textColor: '#ffffff',
        title: {
          textAlign: 'center',
          fontSize: '1.1rem',
          textColor: '#ffffff',
          marginTop: '1rem',
          marginBottom: '.6rem'
        },
        logo: {
          height: '70px',
          width: '180px',
          marginTop: '10px',
          marginBottom: '15px'
        },
        hr: {
          opacity: '0.3',
          border: '0 solid #F8F9FB',
          width: '75%',
          sub: {
            width: '50%'
          }
        },
        selectedMenu: {
          iconColor: '#50B8E4',
          background: '#303750',
          textColor: '#ffffff',
          boxShadow: '-3px 0 10px #70BCFD',
          paddingLeft: '10px'
        }
      }
    }
  },
  reducers: {
    setBreakpoints: (state, breakpoints) => ({
      ...state,
      breakpoints,
      mobilebreakpoint: breakpoints.lg
    })
  },
  effects: {}
})
