import React, { Component } from 'react'
import styled from 'styled-components'
import Search from './Search'
import Add from './Add'
import { connect } from 'mirrorx'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ smallScreen }) => smallScreen ? 'column' : 'row'};
  flex-wrap: wrap;
`

const Title = styled.span`
  font-size: 24px;
  text-transform: capitalize;
  margin-right: 25px;
`

const PullLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  flex-direction: row;
`

const Divider = styled.div`
  height: 1.5px;
  width: 100%;
  background-color: #AFAFAF;
  margin: 12px 0 32px 0;
`

class Controls extends Component {
  render () {
    const { handleSearch, handleAdd, resource, style, breakpoint, isMobile, actions } = this.props
    const smallScreen = breakpoint <= 1
    const searchStyle = {
      marginBottom: 0,
      flex: 1,
      maxWidth: smallScreen ? '100%' : '150px'
    }

    const addStyle = {}
    if (smallScreen) {
      addStyle.width = '100%'
      addStyle.marginTop = '8px'
    }

    return (
      <>
        <Container style={{ ...style, marginBottom: isMobile ? 8 : 0 }} smallScreen={smallScreen}>
          <PullLeft>
            <Title>{`${resource}s`}</Title>
            <Search style={searchStyle} placeholder='Search' onChange={handleSearch} />
          </PullLeft>
          {actions.create ? <Add style={addStyle} label={`Add ${resource}`} onClick={handleAdd} /> : null}
        </Container>
        {isMobile ? null : <Divider />}
      </>
    )
  }
}

const mapStateToProps = state => ({
  breakpoint: state.meta.breakpoint,
  isMobile: state.meta.isMobile
})

export default connect(mapStateToProps)(Controls)
