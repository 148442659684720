import React from 'react'
import { ContentBox } from '../../styles/Components/ContentBox'
import RequestTitle from '../RequestDetailSharedComponents/RequestTitle'
import ChangeSummary from './ChangeSummary'
import ChangeDescription from './ChangeDescription'

const ChangeInfo = ({ change, hasDetails }) => {
  return (
    <ContentBox>
      <RequestTitle doc={change} />
      <ChangeSummary change={change} />
      <ChangeDescription change={change} />
    </ContentBox>
  )
}

export default ChangeInfo
