import React, { Component } from 'react'
import { FormButton as Button } from '../../styles/Components/Common'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
`

const LeftButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`

class FormButtons extends Component {
  onClickCancel = () => {
    const { onClickCancel } = this.props
    onClickCancel && onClickCancel()
  }

  onClickClose = () => {
    const { onClickClose } = this.props
    onClickClose && onClickClose()
  }

  onClickSubmit = () => {
    const { onClickSubmit } = this.props
    onClickSubmit && onClickSubmit()
  }

  onClickEdit = () => {
    const { onClickEdit } = this.props
    onClickEdit && onClickEdit()
  }

  onClickDelete = () => {
    const { onClickDelete } = this.props
    onClickDelete && onClickDelete()
  }

  render () {
    const { edit, userCanEdit, cancelLabel, closeLabel, submitLabel, editLabel, deleteLabel, showDelete } = this.props
    return (
      <Container>
        <LeftButtons>
          {edit || userCanEdit ? <>
            <Button onClick={edit ? this.onClickSubmit : this.onClickEdit} style={{ marginRight: 15 }}>
              {edit ? submitLabel : editLabel}
            </Button>
            <Button className='muted'
              onClick={edit ? this.onClickCancel : this.onClickClose}
              style={{ marginRight: 15 }}
            >
              {edit ? cancelLabel : closeLabel}
            </Button>
          </> : null}
        </LeftButtons>
        {showDelete ? <Button
          className='danger'
          onClick={this.onClickDelete}
        >{deleteLabel}</Button> : null}
      </Container>
    )
  }
}

FormButtons.defaultProps = {
  submitLabel: 'Submit',
  cancelLabel: 'Cancel',
  editLabel: 'Edit',
  deleteLabel: 'Delete',
  closeLabel: 'Close'
}

export default FormButtons
