import React, { Component } from 'react'

class FormManager extends Component {
  state = {
    values: this.props.initialValues || {},
    touched: false,
    validated: false
  }

  handleChange = (target) => {
    if (!this.state.touched) {
      this.setState({ touched: true }, this.updateValues(target))
    } else {
      this.updateValues(target)
    }
  }

  updateValues = (...args) => {
    const { values } = this.state
    args.forEach(arg => {
      const { name, value } = arg
      if (name === null || name === undefined || (typeof name === 'string' && name.length === 0)) {
        throw new TypeError(`You must have a 'name' when setting ${JSON.stringify(value)}`)
      }
      values[name] = value
    })

    this.setState({ values })
  }

  onSubmit = onChildSubmit => e => {
    this.setState({ validated: true })
    onChildSubmit && onChildSubmit({ event: e, data: this.state.values, resetForm: this.resetForm })
  }

  resetForm = () => {
    this.setState({
      values: this.props.initialValues || {},
      touched: false,
      validated: false
    })
  }

  render () {
    const childProps = {
      handleChange: this.handleChange,
      values: this.state.values,
      touched: this.state.touched,
      onSubmit: this.onSubmit,
      validated: this.state.validated,
      resetForm: this.resetForm
    }

    return (
      <>
        {this.props.children(childProps)}
      </>
    )
  }
}

export default FormManager
