import React from 'react'
import styled from 'styled-components'

const Row = styled.tr`
  background-color: ${props => props.theme.dashboard.table.header.background};
  border-right: ${props => props.theme.dashboard.table.header.borderRight};
  border-left: ${props => props.theme.dashboard.table.header.borderLeft};
  color: ${props => props.theme.dashboard.table.header.textColor};
  font-size: ${props => props.theme.dashboard.table.header.fontSize};
`

const DashHeader = ({ headers, actionable }) => {
  const renderHeader = (item, index) => (
    <th
      style={index === 0 ? { whiteSpace: 'nowrap' } : undefined}
      key={`${item.display}-${index}`}
    >
      {item.display}
    </th>
  )

  const tableHeaders = headers.map(renderHeader)
  return (
    <thead>
      <Row>
        {tableHeaders}
        {actionable ? <th>Action Required</th> : <th>&nbsp;</th>}
      </Row>
    </thead>
  )
}

DashHeader.defaultProps = {
  headers: []
}

export default DashHeader
