import React, { Component } from 'react'
import styled from 'styled-components'
import {
  Box,
  TextLink,
  FormButton,
  Label,
  Icon
} from '../../styles/Components/Common'
import { withURLFilter } from '../../utils/filterProvider'
import FilterDisplay from '../Filter/FilterDisplay'
import 'daterangepicker'
import $ from 'jquery'
import FilterIcon from '../Filter/FilterIcon'
import dayjs from 'dayjs'

const Container = styled.div`
  width: 100%;
  height: 100% !important;
  position: ${props => (props.isMobile ? 'relative' : 'static')};
  top: ${props => (props.isMobile ? '0' : '0')};
  padding: ${props => (props.isMobile ? '0' : '15px 15px')};
`

const ListBox = styled(Box)`
  margin: 0;
  overflow-y: auto;
`

const Title = styled.h1`
  font-size: 2em;
  margin: ${props => (props.isMobile ? '7px 0' : '0')};
`

const Nav = styled.nav`
  display: grid;
  grid-template-columns: ${props =>
    props.isMobile ? '1fr' : 'repeat(2, 1fr)'};
  align-items: flex-end;
  padding-bottom: 15px;
  border-bottom: 2px solid ${props => props.theme.common.colors.lightGray};
`

const AlignRight = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
`

const PaddingBox = styled.div`
  padding: ${props => (props.isMobile ? '7px' : '15px')};
`

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const TableContainer = styled.div`
  width: ${props => (props.isMobile ? '100%' : '95%')};
`

const FilterRow = styled.div`
  display: grid;
  grid-gap: 15px;
  padding: 10px 0;
  padding: ${props => (props.isMobile ? '10px 0 0 0 ' : '10px 35px')};
  grid-auto-flow: ${props => (props.isMobile ? 'row' : 'column')};
  align-items: flex-end;

  &.spread {
    justify-content: space-between;
  }
`

const Vbox = styled.div`
  display: flex;
  flex-direction: column;
`

const DateBox = styled.input`
  border: 2px solid ${props => props.theme.common.colors.lightBlue};
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 5px;
  width: 200px;
`

const Calendar = styled.span`
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: ${props => props.theme.common.colors.lightBlue};
  border: 2px solid ${props => props.theme.common.colors.lightBlue};
  cursor: pointer;
`

const CalendarIcon = styled(Icon)`
  font-size: 1.2rem;
  color: #fff;
`

class ListContainer extends Component {
  componentDidMount () {
    const { state } = this.props
    window.jQuery = $
    $(() => {
      $('#daterange').daterangepicker({
        startDate: state.startdate,
        endDate: state.enddate
      })
    })

    $('#daterange').on('apply.daterangepicker', (e, picker) => {
      this.onDateChosen(picker)
    })

    $('#daterange').on('keypress', e => {
      const ENTER_KEY = 13
      if (e.which === ENTER_KEY) {
        const picker = $('#daterange').data('daterangepicker')
        this.onDateChosen(picker)
      }
    })
  }

  componentDidUpdate (prevProps) {
    const { startdate: oldStart, enddate: oldEnd } = prevProps.state
    const { startdate, enddate } = this.props.state
    if (oldStart !== startdate || oldEnd !== enddate) {
      $(() => {
        const picker = $('#daterange').data('daterangepicker')
        if (picker) {
          picker.setStartDate(dayjs(startdate).format('MM/DD/YYYY'))
          picker.setEndDate(dayjs(enddate).format('MM/DD/YYYY'))
        }
      })
    }
  }

  componentWillUnmount () {
    $('#daterange').off('apply.daterangepicker')
    $('#daterange').off('keypress')
  }

  onDateChosen (picker) {
    this.props.handleDateChosen({
      start: picker.startDate.format('MM/DD/YYYY'),
      end: picker.endDate.format('MM/DD/YYYY')
    })
    this.props.onSubmit()
  }

  handleClick () {
    $('#daterange').trigger('click')
  }

  render () {
    const { title, children, isMobile, toggleModal, dateRange, actionButton } = this.props
    return (
      <>
        <Container isMobile={isMobile}>
          <ListBox className='h-100'>
            <PaddingBox>
              <Nav aria-label='navigation' isMobile={isMobile}>
                <Title isMobile={isMobile}>{title}</Title>
                {isMobile ? null : (
                  <AlignRight>
                    <TextLink
                      className='actionable v-center'
                      href={`/api/${
                        this.props.uri
                      }.csv${this.props.asQueryString()}`}
                    >
                      Download CSV &nbsp;
                      <i
                        style={{ fontSize: '1.4rem' }}
                        className='icon-download'
                      />
                    </TextLink>
                  </AlignRight>
                )}
              </Nav>
              <FilterRow className='spread px-0' isMobile={isMobile}>
                <Vbox>
                  {actionButton && actionButton}
                  {dateRange && (
                    <>
                      <Label htmlFor='daterange'>DATE RANGE</Label>
                      <div className='input-group'>
                        <DateBox
                          type='text'
                          name='daterange'
                          id='daterange'
                          placeholder='Enter a date'
                        />
                        <div className='input-group-append'>
                          <Calendar className='input-group-text' onClick={this.handleClick}>
                            <CalendarIcon
                              className='far fa-calendar-alt'
                              alt='Date Picker Calendar'
                            />
                          </Calendar>
                        </div>
                      </div>
                    </>
                  )}
                </Vbox>
                <FormButton
                  onClick={toggleModal}
                  className='actionable py-0 nm wide'
                >
                  <FilterIcon height='42' width='42' color='#222' />
                  ALL FILTERS
                </FormButton>
              </FilterRow>
              <FilterRow isMobile={isMobile}>
                <FilterDisplay
                  filters={this.props.asObject()}
                  onSubmit={this.props.onSubmit}
                  onClick={this.props.handleFilterRemove}
                />
              </FilterRow>
              <CenteredContainer>
                <TableContainer isMobile={isMobile}>{children}</TableContainer>
              </CenteredContainer>
            </PaddingBox>
          </ListBox>
        </Container>
      </>
    )
  }
}

export default withURLFilter(ListContainer)
