import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import dayjs from 'dayjs'
import timezone from 'dayjs-ext/plugin/timeZone'
import { getBrowserTimeZone } from '../../utils/helpers'
dayjs.extend(timezone)

class DatePicker extends Component {
  onChange = (e) => {
    const { onChange } = this.props
    const utcDate = dayjs(e.target.value, { timeZone: getBrowserTimeZone() })

    onChange && onChange({ name: e.target.name, value: utcDate })
  }

  beforeMinDate = () => {
    const { value, min } = this.props
    if (!min) return false
    const selectedDate = dayjs(value).startOf('day')
    const minDate = dayjs(min).startOf('day')
    return selectedDate.isBefore(minDate)
  }

  afterMaxDate = () => {
    const { value, max } = this.props
    if (!max) return false
    const selectedDate = dayjs(value).startOf('day')
    const maxDate = dayjs(max).startOf('day')
    return selectedDate.isAfter(maxDate)
  }

  isInvalid = () => {
    return this.beforeMinDate() || this.afterMaxDate()
  }

  isValid = () => {
    return !this.isInvalid()
  }

  getFeedbackMessage = () => {
    const { beforeMinFeedback, afterMaxFeedback } = this.props
    if (this.beforeMinDate()) {
      return beforeMinFeedback
    }
    if (this.afterMaxDate()) {
      return afterMaxFeedback
    }
  }

  render () {
    const { value, min, max, label, name, labelStyle, required } = this.props
    const isInvalid = this.isInvalid()
    return (
      <Form.Group>
        <Form.Label style={labelStyle}>
          {label}
        </Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id='basic-addon1'>
              <i className='far fa-calendar-alt' />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required={required}
            type='date'
            isInvalid={isInvalid}
            min={min}
            max={max}
            step={1}
            name={name}
            onChange={this.onChange}
            placeholder='mm/dd/yyyy'
            defaultValue={value || ''}
          />
          <Form.Control.Feedback type='invalid'>{this.getFeedbackMessage()}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    )
  }
}

DatePicker.defaultProps = {
  max: dayjs('2099-12-31').startOf('day'),
  min: null,
  beforeMinFeedback: `Release date must be after ${dayjs().format('MM/DD/YYYY')}`,
  afterMaxFeedback: 'Release date must be realistic'
}

export default DatePicker
