import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { isBlank } from 'txstate-utils'
import api from '../../api/api'
import { FilteringProvider, FilteringConnect } from '../AlternateProvider/Provider'

export const ListEditorContext = React.createContext()

const equalKeys = (a, b, keys) => {
  return keys.some(key => a[key] !== b[key])
}

const initialState = {
  editing: false,
  loading: false,
  error: null,
  list: [],
  search: '',
  selected: undefined,
  item: {}
}

class Provider extends FilteringProvider {
  constructor () {
    super()
    this.state = initialState
  }

  /* reducers */
  loading () {
    return this.update(state => ({
      ...state,
      loading: true,
      error: null
    }))
  }

  success (data) {
    return this.update(state => ({
      ...state,
      loading: false,
      error: null,
      list: data.documents,
      item: data.documents.find(itm => itm.id === state.selected) || {}
    }))
  }

  error (error) {
    return this.update(state => ({
      ...state,
      loading: false,
      error: error,
      list: [],
      item: {}
    }))
  }

  setSearchString (str) {
    return this.update(state => ({
      ...state,
      search: str || ''
    }))
  }

  updateList (item) {
    return this.update(state => ({
      ...state,
      list: state.list.map(itm => itm.id === item.id ? item : itm),
      item: item
    }))
  }

  /* Effects */
  async getList () {
    try {
      await this.loading()
      const params = {}
      const search = this.state.search
      if (!isBlank(search)) params.q = search
      const data = await api.get(this.props.endpoint, params)
      if (this.state.search === search) { // if state has changed during the query, ignore results
        await this.success(data)
      }
    } catch (e) {
      await this.error(e)
    }
  }

  onSave = data => {
    if (this.state.selected) {
      this.updateList(data)
    } else {
      this.setSelected(data.id)
      this.refresh()
    }
  }

  /* update state from URL */
  updateFromUrl () {
    const match = this.props.match
    return this.update(state => {
      const editing = match.path.endsWith('/edit') || match.path.endsWith('/new')
      const newstate = {
        ...state,
        selected: parseInt(match.params.id) || 0,
        editing: editing
      }
      // returning null to the setState reducer tells setState to bail out, this way we avoid infinite
      // loop of componentDidUpdate -> state change -> componentDidUpdate
      if (equalKeys(state, newstate, ['selected', 'editing'])) return null
      newstate.item = newstate.list.find(itm => itm.id === newstate.selected) || {}
      return newstate
    })
  }

  refresh () {
    return this.getList()
  }

  setSelected = id => {
    this.props.history.push('/' + this.props.endpoint + (id ? '/' + id : ''))
  }

  updateSearch = async str => {
    await this.setSearchString(str)
    await this.getList()
    if (this.state.list.length === 1 && !this.state.selected) this.setSelected(this.state.list[0].id)
    else if (this.state.selected && !this.state.list.some(itm => itm.id === this.state.selected)) {
      if (this.state.list.length) this.setSelected(this.state.list[0].id)
      else this.closeEditor()
    }
  }

  closeEditor = () => {
    this.props.history.push('/' + this.props.endpoint)
  }

  setEditing = () => {
    this.props.history.push('/' + this.props.endpoint + '/' + this.state.selected + '/edit')
  }

  setAddingNew = () => {
    this.props.history.push('/' + this.props.endpoint + '/new')
  }

  cancelEditing = () => {
    this.setSelected(this.state.selected)
  }

  apiSave = async (item) => {
    const data = item.id ? await api.put(this.props.endpoint + '/' + item.id, item)
      : await api.post(this.props.endpoint, item)
    return data
  }

  apiValidate = async (item) => {
    api.pauseProgress()
    const data = await api.post(this.props.endpoint + '/validate', item)
    api.resumeProgress()
    return data
  }

  apiDelete = async (item) => {
    const data = await api.delete(this.props.endpoint + '/' + item.id)
    this.closeEditor()
    this.refresh()
    return data
  }

  async componentDidMount () {
    await this.updateFromUrl()
    await this.refresh()
  }

  async componentDidUpdate () {
    await this.updateFromUrl()
  }

  render () {
    return <ListEditorContext.Provider value={{
      ...this.state,
      refresh: this.refresh,
      setSelected: this.setSelected,
      updateSearch: this.updateSearch,
      closeEditor: this.closeEditor,
      setEditing: this.setEditing,
      setAddingNew: this.setAddingNew,
      cancelEditing: this.cancelEditing,
      apiSave: this.apiSave,
      apiValidate: this.apiValidate,
      apiDelete: this.apiDelete,
      onSave: this.onSave
    }}>
      {this.props.children}
    </ListEditorContext.Provider>
  }
}

export const ListEditorProvider = withRouter(props => (
  <Switch>
    <Route path={`${props.match.url}/new`} render={({ match }) => <Provider {...props} match={match} />} />
    <Route path={`${props.match.url}/:id/edit`} render={({ match }) => <Provider {...props} match={match} />} />
    <Route path={`${props.match.url}/:id`} render={({ match }) => <Provider {...props} match={match} />} />
    <Route path={`${props.match.url}`} render={({ match }) => <Provider {...props} match={match} />} />
  </Switch>
))

export const ListEditorConnect = FilteringConnect(ListEditorContext.Consumer)
