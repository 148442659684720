import React, { Component } from 'react'
import Collapsable from '../Collapsable/Collapsable'
import Voting from './Voting'
import QuestionForm from '../ChangeDetails/QuestionForm'

class VotingCollapsable extends Component {
  render () {
    const { doc } = this.props
    const votingClosed = doc.status !== 'review'
    const title = votingClosed
      ? 'Approvals Complete'
      : 'Approvals in Progress'
    return doc.type === 'normal' || doc.modelName === 'standard' ? (
      <Collapsable
        startsOpen={!votingClosed}
        title={title}
      >
        <Voting change={doc} votingClosed={votingClosed} />
        <QuestionForm change={doc} />
      </Collapsable>
    ) : null
  }
}

export default VotingCollapsable
