import React, { Component } from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import ChangeList from '../ChangeList/ChangeList'
import ReleaseList from '../ReleaseList/ReleaseList'
import StandardList from '../StandardList/StandardList'
import ChangeRequest from '../ChangeRequest/ChangeRequest'
import ReleaseRequest from '../ReleaseRequest/ReleaseRequest'
import ChangeDetails from '../ChangeDetails/ChangeDetails'
import ReleaseDetails from '../ReleaseDetails/ReleaseDetails'
import StandardDetails from '../StandardDetails/StandardDetails'
import StandardRequest from '../StandardRequest/StandardRequest'
import RenewStandardRequest from '../StandardRequest/RenewStandardRequest'
import ListEditor from '../ListEditor/ListEditor'
import ClientForm from '../ClientForm/ClientForm'
import SystemForm from '../SystemForm/SystemForm'
import UserForm from '../UserForm/UserForm'
import UserRow from '../UserForm/UserRow'
import TeamForm from '../TeamForm/TeamForm'
import TeamRow from '../TeamForm/TeamRow'
import NotFound from '../NotFound/NotFound'
import SystemListEditor from '../AlternateListEditorTypes/System/SystemListEditor'

const InnerPage = styled.main`
  position: relative;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const OuterPage = styled.div`
  position: absolute;
  overflow: hidden auto;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  color: #363534;
`

const Container = ({ children, ...props }) => (
  <OuterPage {...props}><InnerPage>{children}</InnerPage></OuterPage>
)

const ClientList = (props) => (
  <ListEditor
    {...props}
    dataSource={{
      name: 'client',
      getList: 'getClients',
      getCurrent: 'getClient',
      clearCurrent: 'clearCurrent'
    }}
    Form={ClientForm}
    newResourcePath='/clients/new'
    resource='Client'
    root='/clients'
    tableHeaders={[
      { display: 'Name', accessor: 'name' },
      { display: 'Last Modified', accessor: 'modified' }
    ]}
  />
)

const SystemList = (props) => (
  <ListEditor
    {...props}
    dataSource={{
      name: 'system',
      getList: 'getSystems',
      getCurrent: 'getSystem',
      clearCurrent: 'clearCurrent'
    }}
    Form={SystemForm}
    newResourcePath='/systems/new'
    resource='System'
    root='/systems'
    tableHeaders={[
      { display: 'Name', accessor: 'name' },
      { display: 'Last Modified', accessor: 'modified' }
    ]}
  />
)

const UserList = (props) => (
  <ListEditor
    {...props}
    dataSource={{
      name: 'user',
      getList: 'getUsers',
      getCurrent: 'getUser',
      clearCurrent: 'clearCurrent'
    }}
    Form={UserForm}
    newResourcePath='/users/new'
    resource='User'
    root='/users'
    tableHeaders={[
      { display: 'Name', accessor: 'fullname' },
      { display: 'NetID', accessor: 'username' },
      { display: 'Team', accessor: 'team.name' },
      { display: 'Active', accessor: 'orphaned', extractor: user => user.orphaned ? 'No' : 'Yes' },
      { display: 'Admin', accessor: 'admin', extractor: user => user.admin ? 'Yes' : 'No' }
    ]}
    rowComponent={UserRow}
  />
)

const TeamList = (props) => (
  <ListEditor
    {...props}
    dataSource={{
      name: 'team',
      getList: 'getTeams',
      getCurrent: 'getTeam',
      clearCurrent: 'clearCurrent'
    }}
    Form={TeamForm}
    newResourcePath='/teams/new'
    resource='Team'
    root='/teams'
    tableHeaders={[
      { display: 'Name', accessor: 'name' },
      { display: 'Short Name', accessor: 'shortname' },
      { display: 'Last Modified', accessor: 'modified' }
    ]}
    rowComponent={TeamRow}
  />
)

class Content extends Component {
  render () {
    const { children, className } = this.props
    return (
      <Container id='main-container' className={className}>
        {children}
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route path='/change' component={(props) => (
            <Switch>
              <Route path='/change/create' component={ChangeRequest} />
              <Route path='/change/:id' component={ChangeDetails} />
              <Route component={ChangeList} />
            </Switch>
          )} />
          <Route path='/release' component={(props) => (
            <Switch>
              <Route path='/release/create' component={ReleaseRequest} />
              <Route path='/release/:id' component={ReleaseDetails} />
              <Route component={ReleaseList} />
            </Switch>
          )} />
          <Route path='/standard' component={(props) => (
            <Switch>
              <Route path='/standard/create' component={StandardRequest} />
              <Route path='/standard/renew/:id' component={RenewStandardRequest} />
              <Route path='/standard/:id' component={StandardDetails} />
              <Route component={StandardList} />
            </Switch>
          )} />
          <Route path='/clients' component={(props) => (
            <Switch>
              <Route path='/clients/new' render={(props) => <ClientList addNew {...props} />} />
              <Route path='/clients/:id' component={ClientList} />
              <Route component={ClientList} />
            </Switch>
          )} />
          <Route path='/systems' component={(props) => (
            <Switch>
              <Route path='/systems/new' render={(props) => <SystemList addNew {...props} />} />
              <Route path='/systems/:id' component={SystemList} />
              <Route component={SystemList} />
            </Switch>
          )} />
          <Route path='/system' component={SystemListEditor} />
          <Route path='/users' component={(props) => (
            <Switch>
              <Route path='/users/new' render={(props) => <UserList addNew {...props} />} />
              <Route path='/users/:id' component={UserList} />
              <Route component={UserList} />
            </Switch>
          )} />
          <Route path='/teams' component={(props) => (
            <Switch>
              <Route path='/teams/new' render={(props) => <TeamList addNew {...props} />} />
              <Route path='/teams/:id' component={TeamList} />
              <Route component={TeamList} />
            </Switch>
          )} />
          <Route component={NotFound} />
        </Switch>
      </Container>
    )
  }
}

export default Content
