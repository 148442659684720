import React from 'react'
import { TextLink, Icon } from '../../styles/Components/Common'
import { DetailText } from '../../styles/Components/ContentBox'

const UploadDisplay = ({ uploads = [], title }) => {
  return (
    <DetailText title={title} text={uploads.length ? '' : 'None'}>
      {uploads.map(upload => (
        <TextLink
          className='download'
          key={upload.checksum + upload.filename}
          href={`/api/file/${upload.checksum}/${encodeURIComponent(upload.filename)}`}
          target='_blank'
        >
          <Icon className='far fa-file-pdf' /> {upload.filename}
        </TextLink>
      ))}
    </DetailText>
  )
}

export default UploadDisplay
