import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import TeamSelect from '../ChangeRequest/TeamSelect'
import FormHeader from '../ChangeRequest/FormHeader'
import TicketTitle from '../ChangeRequest/TicketTitle'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Question from '../ChangeRequest/Question'
import TextArea from '../Form/TextArea'
import { Prompt } from 'react-router'
import { getValue } from '../../utils/helpers'
import FormManager from '../Form/FormManager'
import SpecialImpact from '../ChangeRequest/SpecialImpact'
import TagSelect from '../Form/TagSelect'
import { actions, connect } from 'mirrorx'
import { FormButton } from '../../styles/Components/Common'
import Loader from '../Loading/Loader'
import ErrorAlert from '../ErrorAlert/ErrorAlert'
import PrimaryClient from '../ChangeRequest/PrimaryClient'
import DocumentUpload from '../Form/DocumentUpload/DocumentUpload'

const COLS = 12

const Box = styled.div`
  margin: 20px 0;
  padding: 20px 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(100, 100, 100, 0.4);
  border-radius: 3px;
  box-sizing: border-box;
`

const SectionTitle = styled.h2`
  font-size: 1.4em;
  padding-bottom: 5px;
`

class StandardRequest extends Component {
  componentDidMount () {
    const { id } = this.props.match.params
    actions.standard.formInit()
    actions.standard.getStandard(id)
    actions.team.getTeams()
    actions.client.getClients()
    actions.system.getSystems()
    actions.impact.getImpacts()
  }

  state = {
    validationFailed: null
  }

  form = React.createRef()

  specialImpacts = React.createRef()

  initialFormState = props => {
    const clientId = (
      props.clients.find(c => {
        if (!props.standard.client) return false
        return c.name === props.standard.client.name
      }) || {}
    ).id

    const prodTeamId = (
      props.teams.find(c => {
        if (!props.standard.prodteam) return false
        return c.name === props.standard.prodteam.name
      }) || {}
    ).id

    const impacts = props.standard.impacts.map(() => ({ id: 1 }))

    return {
      title: props.standard.title || '',
      commplan: props.standard.commplan || false,
      prodonly: props.standard.prodonly || false,
      downtime: props.standard.downtime || false,
      actionrisk: props.standard.actionrisk || '',
      inactionrisk: props.standard.inactionrisk || '',
      client: { id: clientId },
      team: { id: getValue('team.team.id', props.user) },
      prodteam: { id: prodTeamId },
      systems: props.standard.systems || [],
      impacts,
      uploads: [],
      description: props.standard.description || '',
      instructions: props.standard.instructions || '',
      commplandetails: props.standard.commplandetails || '',
      impacted: props.standard.impacted || ''
    }
  }

  formIsValid = () => {
    const specialImpactsAnswered = this.specialImpacts.current.isValid()
    const formIsValid = this.form.current.checkValidity()

    return formIsValid && specialImpactsAnswered
  }

  onSubmit = ({ event, data }) => {
    event.preventDefault()
    event.stopPropagation()

    if (this.formIsValid()) {
      this.postForm(data)
    } else {
      this.setState({
        validationFailed: true
      })
    }
  }

  postForm = async data => {
    const { id } = this.props.match.params
    data.systems = data.systems.map(({ id }) => ({ id }))
    const newStandard = await actions.standard.renewStandard({ ...data, renewedId: id })
    if (newStandard) this.formRedirect(newStandard)
  }

  formRedirect (standard) {
    const { history } = this.props
    history.push(`/standard/${standard.id}`)
  }

  onCancel = e => {
    const { history } = this.props
    history && history.goBack()
  }

  render () {
    const { loading, errors } = this.props
    return loading ? <Loader errors={errors} /> : this.renderForm()
  }

  renderForm () {
    const {
      user,
      owner,
      teams,
      clients,
      specialimpacts,
      acceptedFileTypes,
      systems,
      errors
    } = this.props
    const prodteam = getValue('team.defaultprodteam.id', user)
    return (
      <Container>
        <Box>
          <FormManager initialValues={this.initialFormState(this.props)}>
            {({ handleChange, values, touched, validated, onSubmit }) => {
              return (
                <>
                  <Form
                    ref={this.form}
                    noValidate
                    validated={validated}
                    style={{ width: '100%' }}
                  >
                    <Prompt
                      message={location =>
                        !touched ||
                        (touched &&
                          location.pathname.match(/\/standard\/[0-9]+$/i))
                          ? true
                          : 'You will lose your data if you leave this page'}
                    />
                    <Container>
                      <Col xs={COLS}>
                        <FormHeader
                          title='RENEW MASTER STANDARD'
                          owner={owner}
                        />
                      </Col>
                    </Container>
                    <Container
                      className='py-2'
                      style={{ background: '#F8F9FA' }}
                    >
                      <Col xs={COLS}>
                        <Question
                          label='Production Only'
                          value={values.prodonly}
                          name='prodonly'
                          onChange={handleChange}
                        />
                      </Col>
                    </Container>
                    <Container className='py-3'>
                      <Col xs={COLS}>
                        <SectionTitle>TICKET DETAILS</SectionTitle>
                        <TicketTitle
                          id='title'
                          name='title'
                          value={values.title}
                          label={<i className='fas fa-ticket-alt' />}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={COLS}>
                        <TeamSelect
                          name='prodteam'
                          label='Production Team'
                          required
                          feedback='A production team is required. It can be your own team.'
                          value={values.prodteam.id}
                          values={teams}
                          userTeam={prodteam}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={COLS}>
                        <TextArea
                          label='Standard Request Details'
                          name='description'
                          placeholder='Outline the standard details and explain why this standard is necessary'
                          feedback='Please enter a detailed description of this standard request'
                          value={values.description}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={COLS}>
                        <TextArea
                          name='instructions'
                          label='Instructions for Production Team'
                          feedback="Your prod team instructions can't be blank"
                          onChange={handleChange}
                          value={values.instructions}
                        />
                      </Col>
                      <Col xs={COLS}>
                        <Question
                          name='commplan'
                          label='Communication Plan Needed'
                          value={values.commplan}
                          onChange={handleChange}
                        />
                      </Col>
                      {values.commplan ? (
                        <Col className='pt-3' xs={COLS}>
                          <TextArea
                            name='commplandetails'
                            label='Communication Plan Details'
                            feedback="Your communication plan details can't be blank"
                            onChange={handleChange}
                            value={values.commplandetails}
                          />
                        </Col>
                      ) : null}
                    </Container>
                    <Container className='py-3'>
                      <Col xs={COLS}>
                        <SectionTitle>IMPACT</SectionTitle>
                      </Col>
                      <Col xs={COLS}>
                        <Question
                          name='downtime'
                          label='Will this standard require downtime?'
                          value={values.downtime}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={COLS}>
                        <TagSelect
                          name='systems'
                          options={systems}
                          onChange={handleChange}
                          value={values.systems.map(s => ({ value: s.id, label: s.name }))}
                          invalid={
                            this.state.validationFailed &&
                            !values.systems.length
                          }
                          feedback='Please select at least one affected system'
                        />
                      </Col>
                      <Col xs={COLS}>
                        <TextArea
                          name='impacted'
                          label='What is the impact of this standard?'
                          placeholder='Describe the impact of this standard'
                          instructions='Consider who will be impacted by this standard, positively or negatively'
                          feedback='Please describe the impact of this standard'
                          value={values.impacted}
                          onChange={handleChange}
                        />
                      </Col>
                    </Container>
                    <Container>
                      <Col xs={COLS}>
                        <SectionTitle>CLIENT</SectionTitle>
                        <PrimaryClient
                          name='client'
                          value={values.client}
                          values={clients}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={COLS}>
                        <DocumentUpload
                          name='uploads'
                          ref={this.documentUpload}
                          formValidated={validated}
                          fileTypes={acceptedFileTypes}
                          values={values.uploads}
                          onChange={handleChange}
                          addFilesMessage='Click to upload a document'
                        />
                      </Col>
                    </Container>
                    <Container
                      className='py-2'
                      style={{ background: '#F8F9FA' }}
                    >
                      <Col xs={COLS}>
                        <SpecialImpact
                          touched
                          required
                          isActive
                          name='impacts'
                          requestedImpact={1}
                          ref={this.specialImpacts}
                          formValidated={validated}
                          value={values.impacts}
                          values={specialimpacts}
                          onChange={handleChange}
                          feedback='Please state whether this impacts Financial Aid'
                          label='Will this change have a primary impact on Financial Aid?'
                        />
                      </Col>
                    </Container>
                  </Form>
                  <ErrorAlert errors={errors} />
                  <Container>
                    <Col xs={COLS}>
                      <ButtonToolbar className='pt-3'>
                        <FormButton onClick={onSubmit(this.onSubmit)}>
                          Submit
                        </FormButton>
                        <FormButton className='muted' onClick={this.onCancel}>
                          Cancel
                        </FormButton>
                      </ButtonToolbar>
                    </Col>
                  </Container>
                </>
              )
            }}
          </FormManager>
        </Box>
      </Container>
    )
  }
}

StandardRequest.defaultProps = {
  acceptedFileTypes: [
    { name: 'Standard Request', id: 'request' },
    { name: 'Other', id: 'other' }
  ]
}

const mapStateToProps = state => {
  const internalProject = {
    id: 0,
    name: 'Internal Project'
  }

  const standard = state.standard.current
  const teams = state.team.list
  const clients = [internalProject, ...state.client.list]
  const systems = state.system.list
  const user = state.auth.current
  const specialimpacts = state.impact.list
  const loading =
    !teams.length ||
    !clients.length ||
    !systems.length ||
    !specialimpacts.length ||
    !Object.keys(user).length ||
    !Object.keys(standard).length
  const errors = Array.of(
    state.team.error,
    state.standard.error,
    state.client.error,
    state.system.error,
    state.impact.error,
    state.user.errorCurrent,
    state.standard.error
  )

  return {
    teams,
    standard,
    clients,
    systems,
    user,
    specialimpacts,
    loading,
    errors
  }
}
export default connect(mapStateToProps)(StandardRequest)
