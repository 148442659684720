import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import UploadDisplay from '../Uploads/UploadDisplay'
import { PaddedInContentBox, DetailText } from '../../styles/Components/ContentBox'
import { SubjectTitle } from '../../styles/Components/Common'

const ChangeRequestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 7px;
  .change-description { margin: 0 }
`

const MasterStandardLink = styled.div`
  font-size: 0.8rem;
  margin-left: 0.5rem;
`

const ChangeDescription = ({ change }) => {
  return (
    <PaddedInContentBox>
      <ChangeRequestContainer>
        <SubjectTitle className='change-description'>Description</SubjectTitle>
        {change.standard && change.standard.id &&
          <MasterStandardLink>(<Link to={'/standard/' + change.standard.id}>View Master Standard</Link>)</MasterStandardLink>}
      </ChangeRequestContainer>
      <DetailText style={{ clear: 'both' }} text={change.description} />
      <DetailText title='Change Impact' text={change.impacted} />
      <UploadDisplay title='Documents' uploads={change.uploads} />
    </PaddedInContentBox>
  )
}

export default ChangeDescription
