import React from 'react'
import dayjs from 'dayjs'
import SimpleTable from '../../Table/SimpleTable/SimpleTable'
import { countVotes, initCap } from '../../../utils/helpers'

const ChangesTable = ({ changes, history }) => {
  const handleRowClick = id => {
    id && history.push(`/change/${id}`)
  }

  const formatData = change => {
    const {
      id,
      title,
      team,
      type,
      prodteam,
      status,
      created,
      releases
    } = change
    return {
      id,
      title,
      type: initCap(type),
      status: initCap(status),
      team,
      prodteam,
      created: dayjs(created).format('MM-DD-YYYY h:mm a'),
      releases: releases.length,
      votes: countVotes(change)
    }
  }

  const formattedChanges = changes.map(formatData)
  const columns = [
    { display: '#', accessor: 'id' },
    { display: 'Title', accessor: 'title', wrapaggressive: true },
    { display: 'Team', accessor: 'team.shortname' },
    { display: 'Type', accessor: 'type' },
    { display: 'Status', accessor: 'status' },
    { display: 'Prod Team', accessor: 'prodteam.shortname' },
    { display: 'Created', accessor: 'created' },
    { display: 'Releases', accessor: 'releases' },
    { display: 'Votes', accessor: 'votes' }
  ]
  return (
    <SimpleTable
      headers={columns}
      data={formattedChanges}
      handleRowClick={handleRowClick}
    />
  )
}

export default ChangesTable
