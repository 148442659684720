import mirror, { actions } from 'mirrorx'
import { cacheTime } from '../utils/helpers'
import { REFRESH_SECONDS } from '../utils/constants'
import api from '../api/api'

const requestStandard = state => ({
  ...state,
  hasFetchedCurrent: false,
  loading: true,
  current: {},
  error: null
})

const requestCancel = state => ({
  ...state,
  loading: true,
  error: null
})

const standardSuccess = (state, data) => ({
  ...state,
  loading: false,
  hasFetchedCurrent: true,
  lastRefresh: cacheTime(),
  voteError: null,
  error: null,
  current: data
})

const formInit = (state) => ({
  ...state,
  error: null
})

const cancelSuccess = (state, data) => ({
  ...state,
  loading: false,
  error: null,
  current: data
})

const standardFailure = (state, error) => ({
  ...state,
  hasFetchedCurrent: false,
  hasFetchedList: false,
  loading: false,
  error
})

const createQuestionSuccess = (state, data) => {
  const current = { ...state.current }
  current.questions.push(data)
  return {
    ...state,
    loading: false,
    voteError: null,
    error: null,
    current
  }
}

const createAnswerSuccess = (state, { data, id }) => {
  const current = { ...state.current }
  const index = current.questions.findIndex(q => q.id === id)
  index >= 0 && current.questions[index].answers.push(data)
  return {
    ...state,
    loading: false,
    voteError: null,
    error: null,
    current: current
  }
}

const createInit = (state) => ({
  ...state,
  loading: true,
  error: null
})

const createSuccess = (state, data) => ({
  ...state,
  loading: false,
  lastRefresh: cacheTime(),
  voteError: null,
  error: null,
  current: data
})

const createFailure = (state, error) => ({
  ...state,
  loading: false,
  error: error
})

const voteSuccess = (state, data) => ({
  ...state,
  loading: false,
  lastRefresh: cacheTime(),
  voteError: null,
  current: data
})

const voteFailure = (state, voteError) => ({
  ...state,
  loading: false,
  voteError
})

const useCache = state => ({
  ...state,
  loading: false
})

mirror.model({
  name: 'standard',
  initialState: {
    lastRefresh: cacheTime(),
    hasFetchedCurrent: false,
    voteError: null,
    loading: null,
    error: null,
    actions: {},
    current: {}
  },
  reducers: {
    requestStandard,
    standardSuccess,
    formInit,
    requestCancel,
    standardFailure,
    createAnswerSuccess,
    createQuestionSuccess,
    createInit,
    createSuccess,
    cancelSuccess,
    createFailure,
    voteSuccess,
    voteFailure,
    useCache
  },
  effects: {
    async getStandard (id, getState) {
      const { standard } = getState()
      const empty = !Object.keys(standard.current).length
      const different = standard.current.id !== parseInt(id)
      const stale = cacheTime() - standard.lastRefresh > REFRESH_SECONDS
      if (empty || different || stale) {
        actions.standard.requestStandard()
        try {
          const standard = await api.getStandard(id)
          actions.standard.standardSuccess(standard)
        } catch (error) {
          actions.standard.standardFailure({ error: error.message })
        }
      } else {
        actions.standard.useCache()
      }
    },
    async createStandard (data) {
      actions.standard.createInit()
      try {
        actions.standard.createSuccess(await api.createStandard(data))
      } catch (error) {
        actions.standard.createFailure({
          error: error.message,
          validation: error.validationerrors
        })
      }
    },
    async cancelStandard (data) {
      actions.standard.requestCancel()
      try {
        await api.deleteStandard(data.id, { reason: data.reason })
        actions.standard.standardSuccess(await api.getStandard(data.id))
      } catch (error) {
        actions.standard.createFailure({
          error: error.message,
          validation: error.validationerrors
        })
      }
    },
    async renewStandard (data) {
      actions.standard.createInit()
      try {
        const created = await api.createStandard(data)
        return created
      } catch (error) {
        actions.standard.createFailure({
          error: error.message,
          validation: error.validationerrors
        })
        return undefined
      }
    },
    async createQuestion (data) {
      try {
        const { data: question } = await api.createQuestion(data)
        actions.standard.createQuestionSuccess(question)
      } catch (error) {
        actions.standard.createFailure({ error: error.message })
      }
    },
    async answerQuestion (data) {
      try {
        const answer = await api.answerQuestion(data)
        actions.standard.createAnswerSuccess({ data: answer.data, id: data.id })
      } catch (error) {
        actions.standard.createFailure({ error: error.message })
      }
    },
    async postVote (data) {
      try {
        actions.standard.voteSuccess((await api.postVote(data)).data)
      } catch (error) {
        actions.standard.voteFailure({ error: error.message })
      }
    }
  }
})
