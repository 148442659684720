import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import YesNoButtonSelect from '../Form/YesNoButtonSelect'

const Question = ({ label, value, onChange, name, isMobile }) => {
  const cols = isMobile ? 12 : 6
  return (
    <Row>
      <Col xs={cols} lg={4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <span>{label}</span>
      </Col>
      <Col xs={cols} lg={3}>
        <YesNoButtonSelect
          name={name}
          value={value}
          onChange={onChange} />
      </Col>
    </Row>
  )
}

export default Question
