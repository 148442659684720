import React, { Component } from 'react'
import styled from 'styled-components'
import Loader from '../Loading/Loader'
import { getValue } from '../../utils/helpers'
import { actions, connect } from 'mirrorx'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

const Body = styled.div`
  align-self: stretch;
`

class Editor extends Component {
  componentDidUpdate (prevProps) {
    const { loading, item, addNew, formRef } = this.props
    const form = formRef.current
    const notLoading = !loading
    const loadingFinished = !loading && prevProps.loading

    const idChanged = item.id !== prevProps.item.id
    const idIsDefined = item.id !== undefined
    const idChangedDueToNavigation = idChanged && notLoading && idIsDefined
    if (idChangedDueToNavigation) {
      const { name, getCurrent } = this.props.dataSource
      const source = actions[name]
      if (!source) throw new Error(`No redux store found for resource ${name}`)
      source[getCurrent](item.id)
    }

    const changedToAddNew = addNew && !prevProps.addNew
    if (changedToAddNew) {
      form && form.clearForm()
    }
    if (loadingFinished) {
      form && form.resetForm()
    }
  }

  render () {
    const {
      resource,
      addNew,
      item,
      onHeaderClick,
      toggleEdit,
      onCloseClick,
      formRef,
      loading,
      edit,
      history,
      userCanEdit,
      userCanDelete,
      actions,
      root,
      Form,
      Header,
      list
    } = this.props

    return loading ? <Loader /> : (
      <Container>
        <Header
          title={item.fullname || item.name}
          showEditButton={(addNew || (item.actions && item.actions.edit)) && !edit}
          addNew={addNew}
          resource={resource}
          onClick={onHeaderClick}
          onCloseClick={onCloseClick}
        />
        <Body>
          <Form
            list={list}
            data={item}
            root={root}
            ref={formRef}
            addNew={addNew}
            actions={actions}
            history={history}
            toggleEdit={toggleEdit}
            userCanEdit={userCanEdit}
            handleClose={onCloseClick}
            userCanDelete={userCanDelete}
            edit={(userCanEdit || addNew) ? edit : false}
          />
        </Body>
      </Container>
    )
  }
}

class DefaultForm extends Component {
  resetForm () {}
  clearForm () {}

  render () {
    return null
  }
}

Editor.defaultProps = {
  Form: DefaultForm,
  edit: false
}

const mapStateToProps = (state, props) => {
  const item = state[props.dataSource.name].current
  const userCanEdit = !!getValue('actions.edit', item)
  const userCanDelete = !!getValue('actions.delete', item)
  const actions = {
    ...(getValue('actions', item) || {}),
    ...(getValue('actions', state[props.dataSource.name]))
  }

  return {
    item,
    userCanEdit,
    userCanDelete,
    actions
  }
}

export default connect(mapStateToProps)(Editor)
