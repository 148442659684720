import React, { Component } from 'react'
import Portal from '../Portal/Portal'
import { connect } from 'mirrorx'
import {
  ModalBackdrop,
  ModalSidebar,
  ModalDialog,
  ModalBody,
  ModalContent
} from '../../styles/Components/Modal'
import styled from 'styled-components'
import FilterIcon from './FilterIcon'

const CenteredText = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 0;
  user-select: none;
`

const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  margin-bottom: 1.5rem;
`

const CloseIcon = styled.i`
  font-size: 3rem;
  margin-top: 3px;
  justify-self: center;
  cursor: pointer;
  color: ${props => props.theme.common.colors.darkGray};

  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    justify-self: flex-start;
  }
`

class FilterModal extends Component {
  render () {
    return (
      <Portal>
        <ModalBackdrop onClick={this.props.closeModal} />
        <ModalSidebar className={this.props.isMobile ? 'mobile' : undefined}>
          <ModalDialog sidebar>
            <ModalContent sidebar>
              <ModalBody sidebar>
                <Header>
                  <CenterBox>
                    <CenteredText>
                      <FilterIcon height='42' width='42' color='#222' />
                      FILTER YOUR RESULTS
                    </CenteredText>
                  </CenterBox>
                  <CloseIcon
                    className='ion-ios-close-empty'
                    onClick={this.props.closeModal}
                  />
                </Header>
                {this.props.children}
              </ModalBody>
            </ModalContent>
          </ModalDialog>
        </ModalSidebar>
      </Portal>
    )
  }
}

const mapStateToProps = state => {
  const isMobile = state.meta.isMobile
  return {
    isMobile
  }
}

export default connect(mapStateToProps)(FilterModal)
