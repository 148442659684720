import React from 'react'
import styled from 'styled-components'
import { actions } from 'mirrorx'
import { a11yOnClick } from '../../utils/helpers'

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  margin-bottom: 20px;
`

const Button = styled.div`
  color: ${props =>
    props.selected ? props.theme.common.colors.lightBlue : 'inherit'};
  box-shadow: ${props =>
    props.selected
      ? props.theme.dashboard.content.myWorkMyTeam.mobile.boxShadow
      : 'none'};
  padding-bottom: 12px;
`

const MobileMyWorkMyTeam = ({ personal, type }) => {
  return (
    <Container>
      <Button
        selected={personal}
        {...(!personal ? a11yOnClick(() => actions.dashboard.toggle(type)) : {})}
      >
        My Work
      </Button>
      <Button
        selected={!personal}
        {...(personal ? a11yOnClick(() => actions.dashboard.toggle(type)) : {})}
      >
        My Team
      </Button>
    </Container>
  )
}
export default MobileMyWorkMyTeam
