import styled from 'styled-components'
import React from 'react'
import dayjs from 'dayjs'
import { isBlank } from 'txstate-utils'
import { Link as RouterLink } from 'react-router-dom'
import { onClickOrEnter } from '../../utils/helpers'

export const Box = styled.div`
  margin: 20px 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(100, 100, 100, 0.4);
  border-radius: 4px;
  box-sizing: border-box;
`

export const ActionButton = styled.button`
  border: ${props => props.theme.dashboard.table.actionButton.border};
  background: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  padding: .375rem 1rem;
  width: 160px;

  &:hover, &:active {
    background-color: ${props => props.theme.common.colors.lightBlue};
    color: #fff;
  }

  &.create {
    background: ${props => props.theme.common.colors.lightBlue};
    border: 2px solid ${props => props.theme.common.colors.lightBlue};
    box-shadow: 0 2px 0 ${props => props.theme.common.colors.lightBlueShadow};
    width: auto;
    color: #fff;
  }

  &:hover.create, &:active.create {
    background: transparent;
    border: 2px solid ${props => props.theme.common.colors.lightBlue};
    color: ${props => props.theme.common.colors.lightBlue};
  }
`

export const FormButton = styled.button`
  background-color: ${props => props.theme.common.colors.activeBlue};
  border: 1px solid ${props => props.theme.common.colors.activeBlue};
  box-shadow: 0 2px 0 0 ${props => props.theme.common.colors.activeBlue};
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  padding: .375rem 1.5rem;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  color: #FFF;

  &:hover, &:active {
    background-color: ${props => props.theme.common.colors.mainBlue};
  }

  &.muted {
    background-color: #FFF;
    color: ${props => props.theme.common.colors.darkestGray};
    border: 1px solid ${props => props.theme.common.colors.lavender};
    box-shadow: 0 2px 0 0 ${props => props.theme.common.colors.lavender};
  }

  &.muted:hover, &.muted:active {
    background-color: ${props => props.theme.common.colors.activeBlue};
    color: #FFF;
  }

  &.actionable {
    background: none;
    box-shadow: none;
    color: ${props => props.theme.common.colors.trueBlack};
    border: 2px solid ${props => props.theme.common.colors.lightBlue};
  }

  &.danger {
    background: none;
    color: ${props => props.theme.common.colors.raspberry};
    border: 1px solid ${props => props.theme.common.colors.raspberry};
    box-shadow: 0 2px 0 0 ${props => props.theme.common.colors.darkRaspberry};
  }

  &.danger:hover, &.danger:active {
    color: #fff;
    background-color: ${props => props.theme.common.colors.raspberry};
  }

  &.nm {
    margin-right: 0;
  }

  &.wide {
    padding-right: 2.1em;
    padding-left: 2.1rem;
  }
`

FormButton.defaultProps = {
  type: 'button'
}

export const CenteredBox = styled.div`
  display: flex;
  align-items: center;
`

export const MainTitle = styled.h1`
  color: ${props => props.theme.common.colors.mainBlue};
  margin-bottom: .9rem;
  font-size: 1.5rem;

  &.nm {
    margin: 0;
  }
`

export const SecondaryTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;

  &.nm {
    margin: 0;
  }

  &.decorative-bottom {
    padding-bottom: 8px;
    border-bottom: 1px solid ${props => props.theme.common.colors.lightGray};
  }
`

export const SubjectTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;

  &.nm {
    margin: 0;
  }
`

export const TextLink = styled.a`
  color: ${props => props.theme.common.colors.activeBlue};

  &.download {
    background: ${props => props.theme.common.colors.offWhite};
    padding: 8px 12px;
    text-align: left;
    display: inline-block;
  }

  &.actionable {
    text-transform: uppercase;
    background: none;
    border-radius: 4px;
    padding: .375rem 1.5rem;
    color: ${props => props.theme.common.colors.trueBlack};
    border: 2px solid ${props => props.theme.common.colors.lightBlue};
  }

  &.actionable:hover, &.actionable:active {
    text-decoration: none;
  }

  &.v-center {
    display: flex;
    align-items: center;
  }
`

export const ToggleButton = styled.button.attrs({
  type: 'button'
})`

  cursor: pointer;
  background: none;
  border: 1px solid ${props => props.theme.common.colors.mainBlue};
  color: ${props => props.theme.common.colors.mainBlue};
  width: 90px;
  padding: 10px 0;

  &:disabled {
    border: 1px solid ${props => props.theme.common.colors.lightGray};
    color: ${props => props.theme.common.colors.lightGray};
  }

  &.selected {
    background-color: ${props => props.theme.common.colors.activeBlue};
    border: 1px solid ${props => props.theme.common.colors.activeBlue};
    color: #fff;
  }

  &.left {
    border-radius: 4px 0 0 4px;
  }

  &.right {
    border-radius: 0 4px 4px 0;
  }

  &:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  &:first-of-type.smooth {
    border-right: none;
  }

  &:last-of-type.smooth {
    border-left: none;
  }

  &:hover:not(:disabled), &:active:not(:disabled) {
    background-color: ${props => props.theme.common.colors.mainBlue};
    border: 1px solid ${props => props.theme.common.colors.mainBlue};
    color: #fff;
  }
`

export const StatusPill = styled.div`
  background: ${props => props.theme.common.colors.lavender};
  color: ${props => props.theme.common.colors.trueBlack};
  text-align: center;
  user-select: none;
  font-size: .9rem;
  padding: .125rem 1rem;
  border-radius: 15px;
  margin-left: 8px;

  &.expedited {
    color: #fff;
    background-color: ${props => props.theme.common.colors.raspberry};
    border-radius: 0;
  }

  &.standard {
    color: ${props => props.theme.common.colors.darkestGray};
    background-color: ${props => props.theme.common.colors.lightGray};
    border-radius: 8px;
  }
`

export const Pill = styled.li`
  background-color: ${props => props.theme.common.colors.lavender};
  color: ${props => props.theme.common.colors.darkestGray};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: .25rem .5rem;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;

  &.active {
    background-color: ${props => props.theme.common.colors.lightBlue};
    color: #fff
  }

  &:hover, &:active, &:focus {
    background-color: ${props => props.theme.common.colors.lightBlue};
    outline: none;
    color: #fff
  }

  i {
    margin-left: 12px;
  }
`

export const FilterPill = props => (
  <Pill {...props}>
    {props.children}
    {props.onClick ? <Icon className='ion-close-round' alt='Remove' /> : null}
  </Pill>
)

export const Label = styled.label`
  color: ${props => props.theme.common.colors.darkGray};
`

export const Icon = ({ className, alt }) => (
  <i className={className} aria-hidden={alt ? 'false' : 'true'}>{alt ? (
    <span className='sr-only'>{alt}</span>) : null}
  </i>
)

export const GrayIcon = styled(Icon)`
  color: #6c757d;
`

export const DateWithIcon = ({ date }) => (
  <>{dayjs(date).format('MM/DD/YYYY')} <GrayIcon className='far fa-calendar-alt' /></>
)

export const InvalidFeedback = styled.p`
  display: ${props => !props.isFormValid ? 'block' : 'none'};
  color: ${props => props.theme.common.colors.raspberry};
  margin: .25rem 0 0 0;
  font-size: .8rem;
  width: 100%;
`

export const Link = props => {
  if (isBlank(props.href)) return <RouterLink {...props} {...onClickOrEnter(props.onClick)} />
  return <a {...props} href={props.href} {...onClickOrEnter(props.onClick)}>{props.children}</a>
}

// React Router's Link will pass all props to the DOM node which
// triggers an error if you use styled components with props for CSS
// any time we are doing styled(Link)`${props => ... }` we need to use this
// FilteringLink instead
export const StyledLink = props => (
  <Link className={props.className} to={props.to} href={props.href} target={props.target} onClick={props.onClick}>{props.children}</Link>
)

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #666666;
  opacity: 0.8;
`

const StyledInvisibleLink = styled.a.attrs({
  href: '#'
})`
  &, &:hover, &:active, &:visited {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
  }
`
export const InvisibleLink = props => (
  <StyledInvisibleLink {...props} {...onClickOrEnter((e) => { e.preventDefault(); props.onClick() })} />
)

export const CloseButton = styled(props => (
  <InvisibleLink {...onClickOrEnter(props.onClick)}><Icon className={`${props.className || ''} ion-android-close`} alt={props.alt || 'Close'} /></InvisibleLink>
))`
  font-size: 30px;
`
