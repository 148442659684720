import React from 'react'
import { ContentBox } from '../../styles/Components/ContentBox'
import RequestTitle from '../RequestDetailSharedComponents/RequestTitle'
import StandardSummary from './StandardSummary'
import StandardDescription from './StandardDescription'

const StandardInfo = ({ standard }) => {
  return (
    <ContentBox>
      <RequestTitle doc={standard} />
      <StandardSummary standard={standard} />
      <StandardDescription standard={standard} />
    </ContentBox>
  )
}
export default StandardInfo
