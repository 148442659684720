import React, { Component } from 'react'
import styled from 'styled-components'
import { Item } from './Item'
import { components } from 'react-select'

const Container = styled.ul`
  display: flex;
  padding: 0;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 ${({ hasValue }) => hasValue ? '8px' : '0'} 0;
`

const EmptyValues = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const EmptyLabel = styled.span`
  color: #363534;
  font-style: italic;
  padding: 8px 0 10px 0;
`

class Selected extends Component {
  handleRemove = value => () => {
    const { getValue, setValue } = this.props
    const values = getValue && getValue()
    setValue && setValue(values.filter(item => {
      return JSON.stringify(item) !== JSON.stringify(value)
    }))
  }

  render () {
    const { hasValue, getValue, hideDropdown, emptyMessage } = this.props
    const values = (getValue && getValue()) || []
    const hideRemove = hideDropdown
    return (
      <>
        <Container hasValue={hasValue}>
          {
            hasValue ? values.map((value, i) => {
              return (
                <Item
                  key={`${i}`}
                  label={value.label}
                  hideRemove={hideRemove}
                  onRemove={this.handleRemove(value)}
                />
              )
            }) : <EmptyValues><EmptyLabel>{emptyMessage}</EmptyLabel></EmptyValues>
          }
        </Container>
        {
          hideDropdown ? null : (
            <div style={{ flex: 1, marginBottom: 8 }}>
              <components.Control {...this.props} />
            </div>
          )
        }
      </>
    )
  }
}

export default Selected
