import React, { Component } from 'react'
import styled from 'styled-components'
import SimpleCell from './SimpleCell'
import { getValue } from '../../../utils/helpers'

const Row = styled.tr``

export class SimpleRow extends Component {
  handleRowClick = () => {
    const { handleRowClick, data } = this.props
    handleRowClick && handleRowClick(data.id)
  }

  createHeaderRow = (data, headers) => {
    const { handleSort, sortProps, style } = this.props
    return (
      <Row>
        {
          headers.map((header, i) => (
            <SimpleCell
              header
              style={style}
              key={header.display}
              sortProps={sortProps}
              accessor={header.accessor}
              sortOn={header.sortOn || header.accessor}
              handleSort={handleSort}
              content={header.display}
            />
          ))
        }
      </Row>
    )
  }

  createContentRow = (data, headers) => {
    const { style } = this.props
    const rowStyle = {}
    if (data.selected) rowStyle.backgroundColor = 'rgba(0, 0, 0, 0.075)'
    return (
      <tr style={rowStyle}>
        {
          headers.map((header, i) => {
            const content = header.extractor ? header.extractor(data) : getValue(header.accessor, data)
            const key = `${content}-${i}`
            return (
              <SimpleCell
                style={style}
                key={key}
                wrapaggressive={header.wrapaggressive}
                content={content}
                handleRowClick={this.handleRowClick}
              />
            )
          })
        }
      </tr>
    )
  }

  render () {
    const { headers, data, headerRow } = this.props
    if (headerRow) {
      return this.createHeaderRow(data, headers)
    } else {
      return this.createContentRow(data, headers)
    }
  }
}

export default SimpleRow
