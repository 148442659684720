import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import NewChange from './NewChange'
import { actions, connect } from 'mirrorx'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DashboardBox from './DashboardBox'
import { DASH_TITLES } from '../../utils/constants'
import VotingDisplay from './Sections/VotingDisplay'
import ActionDisplay from './Sections/ActionDisplay'
import PendingDisplay from './Sections/PendingDisplay'
import Loader from '../Loading/Loader'

class Dash extends Component {
  componentDidMount () {
    actions.dashboard.getNeedsVoteChanges()
    actions.dashboard.getNeedsVoteReleases()
    actions.dashboard.getNeedsVoteStandards()
    actions.dashboard.getNeedsAction()
    actions.dashboard.getAwaitingAction()
    actions.dashboard.getChangePerms()
  }

  shouldComponentUpdate (prevProps) {
    return !(this.props.loading && prevProps.loading && this.props.errors[0] === null)
  }

  componentDidUpdate (prevProps) {
    prevProps.activeWork.personal !== this.props.activeWork.personal &&
      actions.dashboard.getNeedsAction()
    prevProps.pendingWork.personal !== this.props.pendingWork.personal &&
      actions.dashboard.getAwaitingAction()
  }

  goToCreateChangeRequest = () => {
    const { history } = this.props
    history.push('/change/create')
  }

  render () {
    const { loading, pendingWork, activeWork, errors, isMobile, perms } = this.props
    if (loading) {
      return <Loader errors={errors} />
    }
    return (
      <Container fluid className='py-3 px-4'>
        {perms.create && <NewChange onClick={this.goToCreateChangeRequest} />}
        <Row className='pt-2'>
          <Col xs={12}>
            <DashboardBox
              title={DASH_TITLES.voting}
              isMobile={isMobile}
              startsOpen
            >
              <VotingDisplay {...this.props} />
            </DashboardBox>
          </Col>
          <Col xs={12} xl={6}>
            <DashboardBox
              title={DASH_TITLES.active}
              personal={activeWork.personal}
              type='active'
              isMobile={isMobile}
              startsOpen
            >
              <ActionDisplay {...this.props} />
            </DashboardBox>
          </Col>
          <Col xs={12} xl={6}>
            <DashboardBox
              title={DASH_TITLES.pending}
              personal={pendingWork.personal}
              type='pending'
              isMobile={isMobile}
              startsOpen
            >
              <PendingDisplay {...this.props} />
            </DashboardBox>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const voting = {
    releases: state.dashboard.voting.releases,
    changes: state.dashboard.voting.changes,
    standards: state.dashboard.voting.standards
  }
  const isMobile = state.meta.isMobile
  const pendingWork = state.dashboard.pending
  const activeWork = state.dashboard.active
  const perms = state.dashboard.actions
  const loading =
    !state.dashboard.hasFetchedPerms ||
    !state.dashboard.voting.hasFetched ||
    !state.dashboard.active.hasFetched
  const errors = Array.of(state.dashboard.error)

  return {
    loading,
    perms,
    isMobile,
    pendingWork,
    activeWork,
    errors,
    voting
  }
}
const Dashboard = connect(mapStateToProps)(Dash)

Dashboard.displayName = 'Dashboard'

export default withRouter(Dashboard)
