import React, { Component } from 'react'
import { actions, connect } from 'mirrorx'
import qs from 'qs'
import styled from 'styled-components'
import ListContainer from '../List/ListContainer'
import Loader from '../Loading/Loader'
import StandardsTable from './StandardsTable/StandardsTable'
import FilterModal from '../Filter/FilterModal'
import StandardsFilter from '../Filter/StandardsFilter'
import ResponsiveTable from '../ChangeList/ChangesTable/ResponsiveTable'
import { ActionButton } from '../../styles/Components/Common'

const PlusIcon = styled.i`
  font-size: 1.25rem;
`

class StandardList extends Component {
  componentDidMount () {
    this.queryStandards()
    actions.user.getUsers()
    actions.team.getTeams()
    actions.system.getSystems()
    actions.client.getClients()
  }

  queryStandards = () => {
    const params = qs.parse(this.props.location.hash.slice(1), { arrayFormat: 'repeat' })
    actions.standardList.getStandards(params)
  }

  handleCreate = () => {
    const { history } = this.props
    history.push('/standard/create')
  }

  render () {
    const { errors, loading, standards, history, isMobile, perms, filtersModal } = this.props
    return (
      <ListContainer
        title='Master Standards'
        uri='standard'
        toggleModal={actions.standardList.toggleFiltersModal}
        onSubmit={this.queryStandards}
        isMobile={isMobile}
        actionButton={(perms || {}).create ? (
          <ActionButton
            className='create'
            onClick={this.handleCreate}
          >
            New Master Standard &nbsp; &nbsp;
            <PlusIcon className='ion-android-add'>
              <span className='sr-only'>Create a new master standard</span>
            </PlusIcon>
          </ActionButton>
        ) : null}
      >
        {loading ? <Loader errors={errors} /> : (
          <>
            {isMobile
              ? <ResponsiveTable changes={standards} history={history} />
              : <StandardsTable standards={standards} history={history} />}
          </>
        )}
        {filtersModal && (
          <FilterModal closeModal={actions.standardList.toggleFiltersModal}>
            <StandardsFilter />
          </FilterModal>)}
      </ListContainer>
    )
  }
}

const mapStateToProps = state => {
  const standards = state.standardList.list
  const filtersModal = state.standardList.filtersModalOpen
  const perms = state.standardList.actions
  const isMobile = state.meta.isMobile
  const loading = !state.standardList.hasFetchedList || state.standardList.loading
  const errors = Array.of(state.standardList.error)
  return {
    standards,
    filtersModal,
    perms,
    isMobile,
    loading,
    errors
  }
}

export default connect(mapStateToProps)(StandardList)
