import mirror, { actions } from 'mirrorx'
import api from '../api/api'
import dayjs from 'dayjs'

mirror.model({
  name: 'releaseList',
  initialState: {
    hasFetchedList: false,
    filtersModalOpen: false,
    loading: null,
    error: null,
    list: []
  },
  reducers: {
    requestReleases: (state, id) => ({
      ...state,
      hasFetchedList: false,
      loading: true,
      error: null,
      list: []
    }),
    releasesSuccess: (state, data) => ({
      ...state,
      loading: false,
      hasFetchedList: true,
      error: null,
      lastPage: data.finalpage || 0,
      list: data.releases || []
    }),
    releasesFailure: (state, error) => ({
      ...state,
      hasFetchedList: false,
      loading: false,
      error
    }),
    filtersModalToggle: (state, filtersModalOpen) => ({
      ...state,
      filtersModalOpen
    })
  },
  effects: {
    async getReleases (params = {}, getState) {
      actions.releaseList.requestReleases()
      if (!params.startdate) {
        params.startdate = dayjs().subtract(1, 'year').toISOString()
      }
      try {
        const { documents: releases, finalpage } = await api.getReleases(params)
        actions.releaseList.releasesSuccess({ releases, finalpage })
      } catch (error) {
        actions.releaseList.releasesFailure({ error: error.message })
      }
    },
    toggleFiltersModal (_, getState) {
      const modalState = getState().releaseList.filtersModalOpen
      actions.releaseList.filtersModalToggle(!modalState)
    }
  }
})
