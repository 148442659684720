import React, { Component } from 'react'
import qs from 'qs'
import $ from 'jquery'
import ChangeInfo from './ChangeInfo'
import Collapsable from '../Collapsable/Collapsable'
import { actions, connect } from 'mirrorx'
import Loader from '../Loading/Loader'
import ReleaseRequest from '../ReleaseRequest/ReleaseRequest'
import CancelButton from '../RequestDetailSharedComponents/CancelButton'
import CloseButtonContainer from '../RequestDetailSharedComponents/CloseButtonContainer'
import ReleaseRow from './ReleaseRow'
import dayjs from 'dayjs'
import { displayReleaseType } from '../../utils/transmogrifier'
import VotingCollapsable from '../Voting/VotingCollapsable'
import { initCap } from '../../utils/helpers'
import CloseChange from '../ChangeRequest/CloseChange'
import { ContentBoxContainer } from '../../styles/Components/ContentBox'

class ChangeDetails extends Component {
  componentDidMount () {
    const { id } = this.props.match.params
    actions.change.getChange(id)
    const hash = qs.parse(this.props.location.hash.slice(1), { arrayFormat: 'repeat' })
    hash.scroll && this.handleScroll(hash.scroll)
  }

  handleScroll (scroll) {
    const target = $(`#${scroll}`).offset()
    if (target) {
      $('#main-container').animate({
        scrollTop: target.top
      }, 1000)
    }
  }

  componentDidUpdate (prevProps) {
    const { newRelease } = this.props
    if (newRelease && prevProps.newRelease !== newRelease) {
      this.formRedirect(this.props.newRelease)
    }
  }

  formRedirect (release) {
    const { history } = this.props
    history.push(`/release/${release.id}`)
  }

  renderReleaseCollapsable (change) {
    const { actions } = change
    const title = 'Create New Release'
    return actions.release ? (
      <Collapsable
        title={title}
        id='create-release'
        startsOpen
      >
        <ReleaseRequest change={change} />
      </Collapsable>
    ) : null
  }

  renderExistingReleases (change) {
    let releases = []
    if (Array.isArray(change.releases)) {
      releases = change.releases.map(release => {
        return (
          <ReleaseRow
            key={release.id}
            title={`${displayReleaseType(release.type) || initCap(release.type)} Release Request`}
            subtitle={`Submitted ${dayjs(release.created).format('MM-DD-YY')}`}
            href={'/release/' + release.id}
          />
        )
      })
    }
    return releases
  }

  renderDetails = () => {
    const { change } = this.props
    const releaseCollapsable = this.renderReleaseCollapsable(change)
    const existingReleases = this.renderExistingReleases(change)
    return (
      <ContentBoxContainer>
        <ChangeInfo change={change} topMargin />
        <VotingCollapsable doc={change} />
        {existingReleases}
        {releaseCollapsable}
        <CloseButtonContainer>
          {change.actions.close && <CloseChange changeid={change.id} />}
          {change.actions.delete && <CancelButton description onCancel={reason => actions.change.cancelChange({ id: change.id, reason })} question='Why are you cancelling this change request?' />}
        </CloseButtonContainer>
      </ContentBoxContainer>
    )
  }

  render () {
    const { loading, errors } = this.props
    return loading ? <Loader errors={errors} /> : this.renderDetails()
  }
}

const mapStateToProps = state => {
  const change = state.change.current
  const newRelease = state.release.current
  const loading = !Object.keys(change).length
  const errors = Array.of(state.change.error)
  return {
    change,
    newRelease,
    loading,
    errors
  }
}

export default connect(mapStateToProps)(ChangeDetails)
