import mirror, { actions } from 'mirrorx'
import api from '../api/api'
import { getValue } from '../utils/helpers'

const currentUserSuccess = (state, data) => ({
  loading: false,
  error: null,
  current: data
})

const currentUserFailure = (state, error) => ({
  ...state,
  error: error,
  loading: false
})

const usersEqual = (a, b) => a.id === b.id && a._version === b._version &&
  getValue('team.id', a) === getValue('team.id', b) &&
  getValue('team._version', a) === getValue('team._version', b)

mirror.model({
  name: 'auth',
  initialState: {
    loading: true,
    error: null,
    current: {}
  },
  reducers: {
    currentUserSuccess,
    currentUserFailure
  },
  effects: {
    async getCurrentUser (_, getState) {
      const current = getState().auth.current
      try {
        const data = await api.getCurrentUser()
        if (!current.id) actions.auth.currentUserSuccess(data)
        else if (!usersEqual(current, data)) {
          document.location.reload()
        }
      } catch (error) {
        if (error.message !== 'unauthenticated') actions.auth.currentUserFailure({ error: error.message })
      }
    }
  }
})
