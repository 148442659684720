import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import { actions } from 'mirrorx'
import { ContentBox, DetailText, HeaderInContentBox } from '../../styles/Components/ContentBox'
import { ButtonToolbar } from 'react-bootstrap'
import { FormButton } from '../../styles/Components/Common'
import CancelButton from '../RequestDetailSharedComponents/CancelButton'

const StandardInfo = ({ doc, history }) => {
  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    history.push(`/standard/renew/${doc.id}`)
  }, [doc.id, history])

  if (doc.status === 'review' || !doc.actions.delete || !doc.actions.create) return null

  return (
    <ContentBox>
      <HeaderInContentBox>
        <DetailText text={`Master standard expiring on ${dayjs(doc.expires).format('MM-DD-YYYY')}.`} />
        <ButtonToolbar>
          <FormButton
            onClick={handleSubmit}
          >
            Renew
          </FormButton>
          <CancelButton
            noContainerClassName
            className='muted'
            text='Cancel Early'
            onCancel={reason => actions.standard.cancelStandard({ id: doc.id, reason })}
            question='Why are you cancelling this master standard?'
          />
        </ButtonToolbar>
      </HeaderInContentBox>
    </ContentBox>
  )
}
export default StandardInfo
