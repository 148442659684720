import React, { Component } from 'react'
import styled from 'styled-components'
import $ from 'jquery'

const Input = styled.input`
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    width: 80%;
  }
`

class SearchFilter extends Component {
  componentDidMount () {
    this.timeout = setTimeout(() => {
      $('#list-search').focus()
    }, 300)
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  render () {
    const { value, name, onChange, onKeyDown } = this.props
    return (
      <div className='form-group'>
        <label htmlFor='list-search'>
          SEARCH TITLE OR DESCRIPTION
        </label>
        <Input
          type='text'
          name={name}
          className='form-control'
          placeholder='Search...'
          onChange={onChange}
          onKeyDown={onKeyDown}
          defaultValue={value}
          id='list-search'
          aria-label='Search'
        />
      </div>
    )
  }
}
export default SearchFilter
