import React from 'react'
import { boolToHuman, listToCommas } from '../../utils/helpers'
import { IMPACT_IDS } from '../../utils/constants'
import DetailPairs from '../RequestDetailSharedComponents/DetailPairs'

const StandardSummary = ({ standard }) => {
  const financialImpact = standard.impacts.some(({ id }) => id === IMPACT_IDS.finAid)
  const customer = standard.client && standard.client.name ? standard.client : { name: 'Internal' }
  const pairs = [
    { name: 'Creator', value: standard.creator.fullname },
    { name: 'Change Team', value: standard.team.name },
    { name: 'Prod Team', value: standard.prodteam.name },
    { name: 'Primary Customer', value: customer.name },
    { name: 'Submitted', value: standard.created },
    ...(standard.votecomplete ? [{ name: 'Approved', value: standard.votecomplete }] : []),
    { name: 'Systems', value: listToCommas(standard.systems) },
    { name: 'Prod Only', value: boolToHuman(standard.prodonly) },
    { name: 'Financial Aid Impact', value: boolToHuman(financialImpact) },
    { name: 'Needs Downtime', value: boolToHuman(standard.downtime) },
    { name: 'Needs Communication', value: boolToHuman(standard.commplan) }
  ]
  return (
    <DetailPairs pairs={pairs} />
  )
}
export default StandardSummary
