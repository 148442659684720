import React from 'react'
import { FilterPill } from '../../../styles/Components/Common'
import { a11yOnClick } from '../../../utils/helpers'

export const Item = (props) => {
  const extraprops = !props.hideRemove && props.onRemove ? a11yOnClick(props.onRemove) : {}
  return (
    <FilterPill {...extraprops}>
      <span>{props.label}</span>
    </FilterPill>
  )
}
