import React from 'react'
import { DetailText, PaddedInContentBox } from '../../styles/Components/ContentBox'
import DetailPairs from '../RequestDetailSharedComponents/DetailPairs'
import { isBlank } from 'txstate-utils'

const ReleaseReportSummary = ({ report }) => {
  return <>
    <DetailPairs pairs={[
      { name: 'Completed By', value: report.user.fullname || report.user.username },
      { name: 'Completed Date', value: report.date },
      { name: 'Release Date', value: report.releasedate }
    ]} />
    {!isBlank(report.notes) &&
      <PaddedInContentBox>
        <DetailText title='Notes' text={report.notes} />
        <DetailText title='SAP Transport' text={report.saptransport} />
      </PaddedInContentBox>}
  </>
}

export default ReleaseReportSummary
