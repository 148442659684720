import React from 'react'
import { a11yOnClick, getValue, daysAgo } from '../../../utils/helpers'
import styled from 'styled-components'
import actionResolver from '../../../utils/actionResolver'
import { ActionButton } from '../../../styles/Components/Common'
import { connect } from 'mirrorx'

const ActionLink = styled.i`
  color: ${props => props.theme.dashboard.table.icon.textColor};
  font-size: ${props => props.theme.dashboard.table.icon.fontSize};
  float: right;
  &:after {
    display: table;
    content: '';
    clear: both;
  }
`

const ActionRow = styled.tr`
  cursor: pointer;
  margin-bottom: 5px;
`

const Row = styled.tr`
  cursor: pointer;
`

const Cell = styled.td`
  border-bottom: ${props => props.theme.dashboard.table.row.border};
  border-top: ${props => props.theme.dashboard.table.row.border};
  word-break: break-word;
`

const ActivityButton = styled(ActionButton)`
  margin-top: -3px;
`

const DashBody = ({ headers, data, onRowClick, actionable, loading, user }) => {
  const handleClick = (modelName, id, scroll) => (e) => {
    e.stopPropagation()
    onRowClick && onRowClick({ modelName, id, scroll })
  }

  const createCell = row => (header, index) => {
    return (
      <Cell
        className={actionable && index === 0 ? 'left-cell' : ''}
        style={{ ...(header.nowrap ? { whiteSpace: 'nowrap' } : {}), ...(header.cleanbreak ? { wordBreak: 'normal' } : {}) }}
        key={index}
      >
        {header.daysago ? daysAgo(getValue(header.accessor, row)) : getValue(header.accessor, row)}
      </Cell>
    )
  }

  const createRegularRow = (item, index) => {
    const cells = headers.map(createCell(item))
    const action = actionResolver(item)
    return (
      <Row
        key={`${item.id}-${item.modelName}`}
        {...a11yOnClick(handleClick(action.type, action.id))}
      >
        {cells}
        <td>
          <ActionLink className='fas fa-angle-right' />
        </td>
      </Row>
    )
  }

  const createActionRow = (item, index) => {
    const cells = headers.map(createCell(item))
    const action = actionResolver(item)
    const scroll = action.text.replace(/ /g, '-').toLowerCase()
    return (
      <ActionRow key={item.id} {...a11yOnClick(handleClick(action.type, action.id))}>
        {cells}
        <Cell className='right-cell'>
          <ActivityButton {...a11yOnClick(handleClick(action.type, action.id, scroll))}>
            {action.text}
          </ActivityButton>
        </Cell>
      </ActionRow>
    )
  }
  const tableRows =
    !loading && data.map(actionable ? createActionRow : createRegularRow)
  return <tbody>{tableRows}</tbody>
}

DashBody.defaultProps = {
  data: [],
  headers: []
}

const mapStateToProps = state => {
  const user = state.auth.current
  const loading = !Object.keys(user).length
  return {
    user,
    loading
  }
}
export default connect(mapStateToProps)(DashBody)
