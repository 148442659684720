import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect, actions } from 'mirrorx'
import { Icon, StyledLink } from '../../styles/Components/Common'

const Container = styled(StyledLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 18px;

  box-shadow: ${props =>
    props.active ? props.theme.sidebar.selectedMenu.boxShadow : 'none'};
  background: ${props =>
    props.active ? props.theme.sidebar.selectedMenu.background : 'none'};

  &:hover, &:active {
    background: ${props => props.theme.sidebar.selectedMenu.background};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    background: ${props => props.theme.sidebar.selectedMenu.background};
  }
`

const Text = styled.span`
  color: ${props =>
    props.active
      ? props.theme.sidebar.selectedMenu.textColor
      : props.theme.sidebar.inactiveMenu.textColor};
  font-size: 1em;
  padding-left: ${props => props.subLabel && props.active ? '24px' : '8px'};
`

const SidebarIcon = styled(Icon)`
  font-size: 1.5em;
  color: ${props =>
    props.active
      ? props.theme.sidebar.selectedMenu.iconColor
      : props.theme.sidebar.inactiveMenu.iconColor};
`

class Label extends PureComponent {
  onClick = () => {
    if (this.props.isMobile && !this.props.href) actions.layout.toggleSidebar()
  }

  render () {
    const { text, collapsed, location: { pathname }, to, href, logo, subLabel, target } = this.props
    const isActive =
      pathname === to ||
      (pathname !== '/' && RegExp(pathname.split('/')[1], 'i').test(to))
    return (
      <Container
        href={href}
        to={to}
        onClick={this.onClick}
        active={isActive}
        target={target}
      >
        <SidebarIcon className={logo} active={isActive} alt={collapsed ? text : null} />
        {!collapsed && (
          <Text active={isActive} subLabel={subLabel}>
            {text}
          </Text>
        )}
      </Container>
    )
  }
}

const SidebarLabel = withRouter(connect(state => ({
  collapsed: state.layout.sidebarCollapsed,
  isMobile: state.meta.isMobile
}))(Label))

export default SidebarLabel
