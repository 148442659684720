import React from 'react'
import { toArray } from 'txstate-utils'
import { StyledTable, StyledTHead, StyledTR, StyledTH } from '../../../styles/Components/Table'
import { ListEditorConnect } from '../ListEditorProvider'
import ConnectedRow from '../List/Row'

class Table extends React.PureComponent {
  render () {
    const { items, Row, HeaderRow } = this.props
    return <StyledTable>
      <StyledTHead><StyledTR>
        <HeaderRow />
        <StyledTH aria-hidden='true' />
      </StyledTR></StyledTHead>
      <tbody>
        {toArray(items).map(item => (
          <ConnectedRow Row={Row} key={item.id} item={item} />
        ))}
      </tbody>
    </StyledTable>
  }
}

const mapstatetoprops = state => {
  return {
    items: state.list
  }
}

export default ListEditorConnect(mapstatetoprops)(Table)
