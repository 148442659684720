import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import TeamSelect from '../ChangeRequest/TeamSelect'
import FormHeader from '../ChangeRequest/FormHeader'
import TicketTitle from '../ChangeRequest/TicketTitle'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Question from '../ChangeRequest/Question'
import TextArea from '../Form/TextArea'
import { Prompt } from 'react-router'
import { getValue } from '../../utils/helpers'
import FormManager from '../Form/FormManager'
import SpecialImpact from '../ChangeRequest/SpecialImpact'
import TagSelect from '../Form/TagSelect'
import { actions, connect } from 'mirrorx'
import { FormButton } from '../../styles/Components/Common'
import Loader from '../Loading/Loader'
import ErrorAlert from '../ErrorAlert/ErrorAlert'
import PrimaryClient from '../ChangeRequest/PrimaryClient'
import DocumentUpload from '../Form/DocumentUpload/DocumentUpload'

const COLS = 12

const Box = styled.div`
  margin: 20px 0;
  padding: 20px 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(100, 100, 100, 0.4);
  border-radius: 3px;
  box-sizing: border-box;
`

const SectionTitle = styled.h2`
  font-size: 1.4em;
  padding-bottom: 5px;
`

class StandardRequest extends Component {
  componentDidMount () {
    actions.standard.formInit()
    actions.team.getTeams()
    actions.client.getClients()
    actions.system.getSystems()
    actions.impact.getImpacts()
  }

  componentDidUpdate (prevProps) {
    const { newStandard } = this.props
    if (newStandard && prevProps.newStandard !== newStandard) {
      this.formRedirect(this.props.newStandard)
    }
  }

  state = {
    validationFailed: null
  }

  initialFormState = props => ({
    commplan: false,
    prodonly: false,
    downtime: false,
    actionrisk: '',
    inactionrisk: '',
    client: { id: props.clients[0].id },
    team: {
      id: getValue('team.team.id', props.user)
    },
    prodteam: {
      id: getValue('team.defaultprodteam.id', props.user)
    },
    systems: [],
    impacts: [],
    uploads: []
  })

  form = React.createRef()
  specialImpacts = React.createRef()
  affectedSystems = React.createRef()

  formIsValid = () => {
    const specialImpactsAnswered = this.specialImpacts.current.isValid()
    const formIsValid = this.form.current.checkValidity()

    return formIsValid && specialImpactsAnswered
  }

  onSubmit = ({ event, data }) => {
    event.preventDefault()
    event.stopPropagation()

    if (this.formIsValid()) {
      this.postForm(data)
    } else {
      this.setState({
        validationFailed: true
      })
    }
  }

  postForm = async data => {
    data.systems = data.systems.map(({ id }) => ({ id }))
    await actions.standard.createStandard(data)
  }

  formRedirect (standard) {
    const { history } = this.props
    history.push(`/standard/${standard.id}`)
  }

  onCancel = e => {
    const { history } = this.props
    history && history.goBack()
  }

  render () {
    const { loading, errors } = this.props
    return loading ? <Loader errors={errors} /> : this.renderForm()
  }

  renderForm () {
    const {
      user,
      owner,
      teams,
      clients,
      specialimpacts,
      acceptedFileTypes,
      systems,
      errors
    } = this.props
    const prodteam = getValue('team.defaultprodteam.id', user)
    return (
      <Container>
        <Box>
          <FormManager initialValues={this.initialFormState(this.props)}>
            {({ handleChange, values, touched, validated, onSubmit }) => (
              <>
                <Form
                  ref={this.form}
                  noValidate
                  validated={validated}
                  style={{ width: '100%' }}
                >
                  <Prompt
                    message={location =>
                      !touched ||
                      (touched &&
                        location.pathname.match(/\/standard\/[0-9]+$/i))
                        ? true
                        : 'You will lose your data if you leave this page'}
                  />
                  <Container>
                    <Col xs={COLS}>
                      <FormHeader
                        title='CREATE A MASTER STANDARD'
                        owner={owner}
                      />
                    </Col>
                  </Container>
                  <Container
                    className='py-2'
                    style={{ background: '#F8F9FA' }}
                  >
                    <Col xs={COLS}>
                      <Question
                        label='Production Only'
                        value={values.prodonly}
                        name='prodonly'
                        onChange={handleChange}
                      />
                    </Col>
                  </Container>
                  <Container className='py-3'>
                    <Col xs={COLS}>
                      <SectionTitle>TICKET DETAILS</SectionTitle>
                      <TicketTitle
                        id='title'
                        name='title'
                        label={<i className='fas fa-ticket-alt' />}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TeamSelect
                        name='prodteam'
                        label='Production Team'
                        required
                        feedback='A production team is required. It can be your own team.'
                        value={values.prodteam.id}
                        values={teams}
                        userTeam={prodteam}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TextArea
                        label='Standard Request Details'
                        name='description'
                        placeholder='Outline the standard details and explain why this standard is necessary'
                        feedback='Please enter a detailed description of this standard request'
                        value={values.description}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TextArea
                        name='instructions'
                        label='Instructions for Production Team'
                        feedback="Your prod team instructions can't be blank"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <Question
                        name='commplan'
                        label='Communication Plan Needed'
                        value={values.commplan}
                        onChange={handleChange}
                      />
                    </Col>
                    {values.commplan ? (
                      <Col className='pt-3' xs={COLS}>
                        <TextArea
                          name='commplandetails'
                          label='Communication Plan Details'
                          feedback="Your communication plan details can't be blank"
                          onChange={handleChange}
                        />
                      </Col>
                    ) : null}
                  </Container>
                  <Container className='py-3'>
                    <Col xs={COLS}>
                      <SectionTitle>IMPACT</SectionTitle>
                    </Col>
                    <Col xs={COLS}>
                      <Question
                        name='downtime'
                        label='Will this standard require downtime?'
                        value={values.downtime}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TagSelect
                        name='systems'
                        options={systems}
                        onChange={handleChange}
                        invalid={
                          this.state.validationFailed && !values.systems.length
                        }
                        feedback='Please select at least one affected system'
                      />
                    </Col>
                    <Col xs={COLS}>
                      <TextArea
                        name='impacted'
                        label='What is the impact of this standard?'
                        placeholder='Describe the impact of this standard'
                        instructions='Consider who will be impacted by this standard, positively or negatively'
                        feedback='Please describe the impact of this standard'
                        value={values.impacted}
                        onChange={handleChange}
                      />
                    </Col>
                  </Container>
                  <Container>
                    <Col xs={COLS}>
                      <SectionTitle>CLIENT</SectionTitle>
                      <PrimaryClient
                        name='client'
                        value={values.client}
                        values={clients}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={COLS}>
                      <DocumentUpload
                        name='uploads'
                        ref={this.documentUpload}
                        formValidated={validated}
                        fileTypes={acceptedFileTypes}
                        values={values.uploads}
                        onChange={handleChange}
                        addFilesMessage='Click to upload a document'
                      />
                    </Col>
                  </Container>
                  <Container
                    className='py-2'
                    style={{ background: '#F8F9FA' }}
                  >
                    <Col xs={COLS}>
                      <SpecialImpact
                        name='impacts'
                        formValidated={validated}
                        ref={this.specialImpacts}
                        required
                        isActive
                        value={values.impacts}
                        values={specialimpacts}
                        onChange={handleChange}
                        feedback='Please state whether this impacts Financial Aid'
                        label='Will this change have a primary impact on Financial Aid?'
                        requestedImpact={1}
                      />
                    </Col>
                  </Container>
                </Form>
                <ErrorAlert errors={errors} />
                <Container>
                  <Col xs={COLS}>
                    <ButtonToolbar className='pt-3'>
                      <FormButton
                        onClick={onSubmit(this.onSubmit)}
                      >
                        Submit
                      </FormButton>
                      <FormButton
                        className='muted'
                        onClick={this.onCancel}
                      >
                        Cancel
                      </FormButton>
                    </ButtonToolbar>
                  </Col>
                </Container>
              </>
            )}
          </FormManager>
        </Box>
      </Container>
    )
  }
}

StandardRequest.defaultProps = {
  acceptedFileTypes: [
    { name: 'Standard Request', id: 'request' },
    { name: 'Other', id: 'other' }
  ]
}

const mapStateToProps = state => {
  const internalProject = {
    id: 0,
    name: 'Internal Project'
  }
  const teams = state.team.list
  const newStandard = state.standard.current
  const clients = [internalProject, ...state.client.list]
  const systems = state.system.list
  const user = state.auth.current
  const specialimpacts = state.impact.list
  const loading =
    !teams.length ||
    !clients.length ||
    !systems.length ||
    !specialimpacts.length ||
    !Object.keys(user).length
  const errors = Array.of(
    state.team.error,
    state.standard.error,
    state.client.error,
    state.system.error,
    state.impact.error,
    state.user.errorCurrent
  )
  return {
    teams,
    newStandard,
    clients,
    systems,
    user,
    specialimpacts,
    loading,
    errors
  }
}
export default connect(mapStateToProps)(StandardRequest)
