import React from 'react'
import ReleaseDescription from './ReleaseDescription'
import RequestTitle from '../RequestDetailSharedComponents/RequestTitle'
import { ContentBox } from '../../styles/Components/ContentBox'
import ReleaseSummary from './ReleaseSummary'

const ReleaseInfo = ({ release }) => {
  return (
    <ContentBox>
      <RequestTitle doc={release} />
      <ReleaseSummary release={release} />
      <ReleaseDescription release={release} />
    </ContentBox>
  )
}

export default ReleaseInfo
