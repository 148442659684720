import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'
import DatePicker from '../Form/DatePicker'
import TextArea from '../Form/TextArea'
import FormManager from '../Form/FormManager'
import Form from 'react-bootstrap/Form'
import { FormButton } from '../../styles/Components/Common'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ErrorAlert from '../ErrorAlert/ErrorAlert'
import { connect, actions } from 'mirrorx'

const FormTitle = styled.h4`
  font-size: 1.2em;
  padding-bottom: 15px;
  font-weight: bold;
`

const ReleaseReport = ({ validationError, release, errors }) => {
  const form = React.createRef()

  const postForm = async (data) => {
    await actions.release.createReleaseReport({ releaseid: release.id, data })
  }

  const handleSubmit = ({ event, data }) => {
    if (form.current.checkValidity()) {
      postForm(data)
    }
  }

  const handleCancel = (touched, resetForm) => () => {
    const prompt = 'Are you sure you want to cancel the release report?'
    if (!touched || (touched && window.confirm(prompt))) {
      resetForm && resetForm()
      form.current.reset()
    }
  }

  return (
    <Container>
      <Col xs={12}>
        <FormTitle>Release Completed</FormTitle>
      </Col>
      <Row>
        <FormManager initialValues={{}}>
          {({
            handleChange,
            values,
            touched,
            validated,
            onSubmit,
            resetForm
          }) => (
            <Col xs={12} lg={8}>
              <Form
                ref={form}
                noValidate
                validated={validated}
                style={{ width: '100%' }}
              >
                <Col xs={12} sm={6}>
                  <DatePicker
                    onChange={handleChange}
                    name='releasedate'
                    label='Actual Date Release Completed'
                    required
                  />
                </Col>
                <Col xs={12}>
                  <TextArea
                    name='notes'
                    label='Release Notes for Ticket Owner'
                    feedback="Release Notes for Ticket Owner can't be blank"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <TextArea
                    name='saptransport'
                    label='SAP transport information'
                    feedback="SAP transport information can't be blank"
                    onChange={handleChange}
                    optional
                  />
                </Col>
                <ErrorAlert errors={errors} />
                <Col xs={12}>
                  <ButtonToolbar className='my-3'>
                    <FormButton
                      onClick={onSubmit(handleSubmit)}
                    >
                      Submit
                    </FormButton>
                    <FormButton
                      className='muted'
                      onClick={handleCancel(touched, resetForm)}
                    >
                      Cancel
                    </FormButton>
                  </ButtonToolbar>
                </Col>
              </Form>
            </Col>
          )}
        </FormManager>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  const errors = Array.of(state.release.error)
  return {
    errors
  }
}

export default connect(mapStateToProps)(ReleaseReport)
