import React from 'react'
import Form from 'react-bootstrap/Form'

const UserSelect = ({ onChange, name, values, value, label }) => {
  const handleChange = (e) => {
    onChange && onChange({ name, value: { id: e.target.value } })
  }

  const renderOption = user => {
    return <option key={user.id} value={user.id}>{user.fullname}</option>
  }

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as='select'
        name={name}
        onChange={handleChange}
        defaultValue={value}
        required>
        {values.map(renderOption)}
      </Form.Control>
    </Form.Group>
  )
}

export default UserSelect
