import { REQUEST_STATUS } from './constants'

const needsAnswer = doc => {
  return doc.status === REQUEST_STATUS.review && (doc.questions || []).some(q => !(q.answers || []).length)
}
const action = (doc, text) => ({ type: doc.modelName || 'release', text, id: doc.id })

const hasQuestionAction = change => {
  return needsAnswer(change)
    ? action(change, 'Answer Question')
    : undefined
}

const createReleaseAction = change => {
  return change.actions.release
    ? action(change, 'Create Release')
    : undefined
}

const closeChangeAction = change => {
  return change.actions.close
    ? action(change, 'Close Change')
    : undefined
}

const hasReleaseQuestionAction = change => {
  const release = (change.releases || []).find(needsAnswer)
  return release ? action(release, 'View Question') : undefined
}

const createReportAction = (change) => {
  const release = (change.releases || []).find(r => r.actions.report)
  return release ? action(release, 'Create Report') : undefined
}

const closeReleaseAction = change => {
  const release = (change.releases || []).find(r => r.actions.close)
  return release ? action(release, 'Close Release') : undefined
}

const openReleaseAction = change => {
  const release = (change.releases || []).find(r => r.status === 'review' || (r.status === 'approved' && change.team.id !== change.prodteam.id))
  return release ? action(release, 'View Release') : undefined
}

const isMasterStandardAction = change => {
  if (change.modelName !== 'standard') return undefined
  return change.isExpiring ? action(change, 'Review') : action(change, 'View Standard')
}

export default function (change) {
  return hasQuestionAction(change) ||
    hasReleaseQuestionAction(change) ||
    createReportAction(change) ||
    closeReleaseAction(change) ||
    closeChangeAction(change) ||
    openReleaseAction(change) ||
    createReleaseAction(change) ||
    isMasterStandardAction(change) ||
    action(change, 'View Change')
}
