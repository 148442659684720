import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import DropdownButton from 'react-bootstrap/DropdownButton'
import DropdownItem from 'react-bootstrap/DropdownItem'
import InputGroup from 'react-bootstrap/InputGroup'
import { displayDocumentType } from '../../../utils/transmogrifier'
import Button from 'react-bootstrap/Button'
import { Icon } from '../../../styles/Components/Common'
import { UPLOAD_TYPES } from '../../../utils/constants'

class FileListItem extends Component {
  render () {
    const { file, types, onClick, isInvalid, removeFile } = this.props
    let variant = 'outline-secondary'

    if (isInvalid) {
      variant = 'outline-danger'
    }

    return (
      <Form.Group>
        <InputGroup style={{ margin: '10px 0' }}>
          <InputGroup.Prepend>
            <Button variant='danger' onClick={removeFile(file)}>
              <Icon className='far fa-trash-alt' alt='Remove File' />
            </Button>
          </InputGroup.Prepend>
          <Form.Control value={file.name} readOnly isInvalid={isInvalid} />
          <DropdownButton id={`${file.name}TypeMenu`} variant={variant} as={InputGroup.Append} title={displayDocumentType(file.docType)}>
            {
              types.map((type, i) => {
                return (
                  <DropdownItem
                    key={i.toString()}
                    as='button'
                    onClick={onClick(file, type.id)}
                    title={type.name}
                  >{UPLOAD_TYPES[type.id]}</DropdownItem>
                )
              })
            }
          </DropdownButton>
        </InputGroup>
      </Form.Group>
    )
  }
}

class FileList extends Component {
  onClick = (file, type) => e => {
    e.preventDefault()
    const { onFileTypeSelect } = this.props
    onFileTypeSelect && onFileTypeSelect(file, type)
  }

  removeFile = (file) => e => {
    e.preventDefault()
    const { onRemoveFile } = this.props
    onRemoveFile && onRemoveFile(file)
  }

  renderFile = (file, i) => (
    <FileListItem
      key={i.toString()}
      file={file}
      types={this.props.types}
      onClick={this.onClick}
      isInvalid={!file.docType}
      removeFile={this.removeFile}
    />
  )

  render () {
    const { files } = this.props
    return (
      <>
        {files.map(this.renderFile)}
      </>
    )
  }
}

FileList.defaultProps = {
  types: [
    { name: 'Client Request', id: 'request' },
    { name: 'Client Signoff', id: 'signoff' }
  ]
}

export default FileList
