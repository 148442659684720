import React, { Component } from 'react'
import { actions, connect } from 'mirrorx'
import ReleasesTable from './ReleasesTable/ReleasesTable'
import ListContainer from '../List/ListContainer'
import Loader from '../Loading/Loader'
import FilterModal from '../Filter/FilterModal'
import ReleasesFilter from '../Filter/ReleasesFilter'
import ResponsiveTable from '../ChangeList/ChangesTable/ResponsiveTable'
import qs from 'qs'

class ReleaseList extends Component {
  componentDidMount () {
    this.queryReleases()
    actions.user.getUsers()
    actions.team.getTeams()
    actions.system.getSystems()
    actions.client.getClients()
  }

  queryReleases = () => {
    const params = qs.parse(this.props.location.hash.slice(1), { arrayFormat: 'repeat' })
    actions.releaseList.getReleases(params)
  }

  render () {
    const { loading, releases, history, errors, isMobile, filtersModal } = this.props
    return (
      <>
        <ListContainer
          dateRange
          title='Release Requests'
          toggleModal={actions.releaseList.toggleFiltersModal}
          uri='release'
          onSubmit={this.queryReleases}
          isMobile={isMobile}>
          {loading
            ? <Loader errors={errors} />
            : isMobile
              ? <ResponsiveTable changes={releases} history={history} />
              : <ReleasesTable releases={releases} history={history} />}
          {filtersModal && (
            <FilterModal closeModal={actions.releaseList.toggleFiltersModal}>
              <ReleasesFilter />
            </FilterModal>)}
        </ListContainer>
      </>
    )
  }
}

const mapStateToProps = state => {
  const releases = state.releaseList.list
  const filtersModal = state.releaseList.filtersModalOpen
  const isMobile = state.meta.isMobile
  const loading = !state.releaseList.hasFetchedList || state.releaseList.loading
  const errors = Array.of(state.releaseList.error)
  return {
    releases,
    filtersModal,
    isMobile,
    loading,
    errors
  }
}

export default connect(mapStateToProps)(ReleaseList)
