import mirror, { actions } from 'mirrorx'
import api from '../api/api'

const requestClients = state => ({
  ...state,
  loading: true,
  error: null
})

const requestClient = state => ({
  ...state,
  loadingOne: true,
  error: null
})

const clientsSuccess = (state, data) => ({
  ...state,
  loading: false,
  error: null,
  lastPage: data.finalpage || 0,
  list: data.documents || [],
  actions: data.actions
})

const clientSuccess = (state, data) => ({
  ...state,
  loadingOne: false,
  error: null,
  current: data
})

const clientsFailure = (state, error) => ({
  ...state,
  loading: false,
  error
})

const clientFailure = (state, error) => ({
  ...state,
  loadingOne: false,
  errorOne: error
})

const clearCurrent = state => ({
  ...state,
  current: {}
})

mirror.model({
  name: 'client',
  initialState: {
    loading: false,
    loadingOne: false,
    error: null,
    errorOne: null,
    lastPage: null,
    list: [],
    actions: {},
    current: {}
  },
  reducers: {
    requestClient,
    requestClients,
    clientsSuccess,
    clientSuccess,
    clientsFailure,
    clientFailure,
    clearCurrent
  },
  effects: {
    async getClients () {
      actions.client.requestClients()
      try {
        const data = await api.getClients()
        actions.client.clientsSuccess(data)
      } catch (error) {
        actions.client.clientsFailure({ error: error.message })
      }
    },
    async getClient (id) {
      actions.client.requestClient()
      try {
        const client = await api.getClient(id)
        // await new Promise((resolve) => setTimeout(resolve, 1500))
        actions.client.clientSuccess(client)
      } catch (error) {
        actions.client.clientFailure({ error: error.message })
      }
    }
  }
})
