import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import { omit } from './helpers'
import dayjs from 'dayjs'

export const withURLFilter = (WrappedComponent) => {
  return withRouter(class extends Component {
    constructor (props) {
      super(props)
      this.format = { arrayFormat: 'repeat' }
      this.startDate = dayjs().subtract(1, 'year').format('MM/DD/YYYY')
      this.endDate = dayjs().format('MM/DD/YYYY')
      this.state = {
        q: '',
        startdate: this.startDate,
        enddate: this.endDate,
        prodonly: null,
        type: null,
        status: [],
        creator: [],
        team: [],
        prodteam: [],
        system: [],
        client: [],
        commplan: null,
        downtime: null,
        impact: null,
        lonewolf: null
      }
    }

    componentDidMount () {
      const { history } = this.props
      this.unsubscribe = history.listen(location => {
        this.setState(this.asNormalizedObject())
      })

      this.setState(qs.parse(this.asNormalizedObject()))
    }

    componentWillUnmount () {
      this.unsubscribe()
    }

    omitAndUpdate = (key, defVal) => {
      this.omitFromHash(key, defVal)
      this.setState({ [key]: defVal })
    }

    asQueryString = () => {
      return `?${this.props.location.hash.slice(1)}`
    }

    asNormalizedObject = () => {
      return qs.parse(this.props.location.hash.slice(1), this.format)
    }

    updateURL = () => {
      const { location } = this.props
      this.props.history.push(location.pathname + location.hash)
    }

    mergeHash = value => {
      const { location } = this.props
      location.hash =
        '#' +
        qs.stringify(
          {
            ...this.asNormalizedObject(),
            ...value
          },
          this.format
        )
    }

    omitFromHash = key => {
      const { location } = this.props
      location.hash =
        '#' +
        qs.stringify(
          omit(this.asNormalizedObject(), key),
          this.format
        )
    }

    omitOrMerge = (key, val, bypass) => {
      if (!bypass && Array.isArray(this.state[key])) {
        this.mergeHash({ [key]: this.state[key].filter(v => v !== val) })
      } else {
        this.omitAndUpdate(key, undefined)
      }
    }

    handleDateChosen = ({ start, end }) => {
      const startDate = dayjs(start)
      const endDate = dayjs(end).endOf('day')
      if (!startDate.isValid() || !endDate.isValid()) {
        this.omitAndUpdate('startdate', this.startDate)
        this.omitAndUpdate('enddate', this.endDate)
      } else {
        this.mergeHash({ startdate: startDate.toISOString() })
        this.mergeHash({ enddate: endDate.toISOString() })
      }
      this.updateURL()
    }

    handleFilterRemove = (key, val, bypass) => {
      if (key === 'startdate') {
        this.omitAndUpdate(key, this.startDate)
      } else if (key === 'enddate') {
        this.omitAndUpdate(key, this.endDate)
      } else {
        this.omitOrMerge(key, val, bypass)
      }
      this.updateURL()
    }

    render () {
      return (
        <WrappedComponent
          handleDateChosen={this.handleDateChosen}
          handleFilterRemove={this.handleFilterRemove}
          mergeHash={this.mergeHash}
          updateURL={this.updateURL}
          asQueryString={this.asQueryString}
          asObject={this.asNormalizedObject}
          format={this.format}
          state={this.state}
          {...this.props}
        />
      )
    }
  })
}
