export function createVoteHelpers (change, userid) {
  const isDisabled = () => {
    if (change.status === 'denied') {
      return true
    }
    return change.votes.reduce((accum, current) => {
      return Object.prototype.hasOwnProperty.call(current, 'vote') && current.user.id === userid
        ? true
        : accum
    }, false)
  }

  const hasUserVote = (action) => {
    if (isDisabled()) {
      return change.votes.reduce((accum, current) => {
        return current.user.id === userid && current.vote === action
          ? true
          : accum
      }, false)
    }
    return false
  }

  const getClassList = (voteAction) => {
    const classes = []
    isDisabled() && classes.push('disabled')
    hasUserVote(voteAction) && classes.push('active')
    return classes.join(' ')
  }

  return {
    isDisabled,
    hasUserVote,
    getClassList
  }
}
