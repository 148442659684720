import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const countVotes = obj => {
  const votes = obj.votes || []
  const total = votes.length
  if (!total) return 'N/A'
  const voted = votes.filter(({ vote = null }) => vote).length
  return `${voted}/${total}`
}

export const checkVotingComplete = obj => {
  const votes = obj.votes || []
  const total = votes.length
  const voted = votes.filter(({ vote = null }) => vote).length
  return voted === total
}

export const pairNumbers = end => {
  const vals = []
  for (let i = 0; i < end; i += 4) {
    vals.push(i)
    vals.push(i + 1)
  }
  return vals
}

export const everyOther = end => {
  const vals = []
  for (let i = 0; i < end; i += 2) {
    vals.push(i)
  }
  return vals
}

export const initCap = str => {
  return (
    str &&
    str.toLowerCase().replace(/(?:^|\s)[a-z]/g, m => {
      return m.toUpperCase()
    })
  )
}

export const boolToHuman = bool => {
  if (typeof bool === 'string') {
    return bool === 'true' || bool === '1' ? 'Yes' : 'No'
  }
  return bool ? 'Yes' : 'No'
}

export const listToCommas = (list, key = 'name') => {
  return list.length ? list.map(doc => doc[key]).join(', ') : 'None'
}

export const cacheTime = () => {
  return Math.trunc(Date.now() / 1000)
}

export const omit = (obj, omitKey) => {
  return Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key]
    }
    return result
  }, {})
}

export const getValue = (accessor = '', initial = {}) => {
  if (initial === null) {
    throw new Error(`Cannot access ${accessor} on null initial value`)
  }
  if (accessor === null) {
    throw new Error('Cannot access null property of an object')
  }

  return accessor.split('.').reduce((accum, accessor, i, arr) => {
    const val = accum[accessor]
    const valueIsLast = arr.length - 1 === i

    if (valueIsLast) {
      return val
    }

    return val || {}
  }, initial)
}

export const sortByKey = (accessor, order) => (a, b) => {
  const valA = getValue(accessor, a)
  const valB = getValue(accessor, b)

  if (order === 'ascending') {
    if ((!valA && valB) || valA < valB) return -1
    if ((!valB && valA) || valA > valB) return 1
  } else if (order === 'descending') {
    if ((!valA && valB) || valA < valB) return 1
    if ((!valB && valA) || valA > valB) return -1
  }
  return 0
}

export const rebuildSelect = (valObj, stateVar) => {
  const standardObj = Array.isArray(valObj) ? valObj : Object.entries(valObj)
  const newArr = []
  for (let i = 0; i < standardObj.length; i++) {
    const innerArr = standardObj[i]
    const key = String(innerArr[0])
    if (key === stateVar || (Array.isArray(stateVar) && stateVar.some(v => String(v) === key))) {
      newArr.push(innerArr)
    }
  }

  return newArr.map(v => ({ value: v[0], label: v[1] }))
}

export const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
export const identity = x => x
export const compose = (f, g) => basis => f(g(basis))
export const pipe = (...fns) => basis => fns.reduce((g, f) => f(g), basis)
export const trace = label => x => {
  console.log(`${label}: `, x)
  return x
}

export const a11yOnClick = handler => {
  if (!handler) return {}
  return {
    tabIndex: 0,
    style: { cursor: 'pointer' },
    ...onClickOrEnter(handler)
  }
}

export const onClickOrEnter = handler => (handler ? {
  onClick: handler,
  onKeyPress: (e) => { if (e.key === 'Enter') handler(e) }
} : {})

export const equalShallow = (a, b) => Object.keys(a).length === Object.keys(b).length &&
  Object.keys(a).every(key => Object.prototype.hasOwnProperty.call(b, key) && b[key] === a[key])

export const generatestring = () => Math.random().toString(36).slice(2, 10).replace(/^\d/, 'r')

const DEFAULT_TIMEZONE = 'America/Chicago'
export const getBrowserTimeZone = () => {
  if (Intl && Intl.DateTimeFormat) {
    const dtf = Intl.DateTimeFormat()
    if (dtf.resolvedOptions) return dtf.resolvedOptions().timeZone || DEFAULT_TIMEZONE
  }
  return DEFAULT_TIMEZONE
}

export function daysAgo (dt) {
  const ret = dayjs(dt).fromNow()
  if (ret === 'a day ago') return 'yesterday'
  return ret
}
