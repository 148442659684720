import React, { PureComponent } from 'react'
import EmptyState from '../../Empty/EmptyState'

class SimpleEmptyState extends PureComponent {
  render () {
    const { height } = this.props
    return (
      <EmptyState
        viewBox='0 20 294.53 160.79'
        height={height}
        quote='The data is lost in space...'
        description='There are no results; adjust your filters to find results.'
      />
    )
  }
}

SimpleEmptyState.defaultProps = {
  height: '350px'
}

export default SimpleEmptyState
