import React from 'react'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import styled from 'styled-components'

const Text = styled.p`
  margin-bottom: 0px;
`

const ErrorAlert = ({ errors = [], defaultMessage = 'An error occurred.' }) => {
  return (
    <>
      {errors
        .filter(val => val)
        .reduce((accum, val) => {
          return (
            <Container className='my-3'>
              <Alert variant='danger'>
                <Text>
                  <strong>{val.error || defaultMessage}</strong>
                </Text>
                {Object.values(val.validation || {}).map((err, index) => (
                  <Text key={index}>{err}</Text>
                ))}
              </Alert>
            </Container>
          )
        }, null)}
    </>
  )
}

export default ErrorAlert
