import React from 'react'
import './index.css'
import './styles/css/ionicons.css'
import './styles/css/mdi.css'
import './styles/css/daterangepicker.css'
import App from './App'
import { render } from 'mirrorx'

require('./store/Change')
require('./store/ChangeList')
require('./store/ReleaseList')
require('./store/StandardList')
require('./store/Release')
require('./store/Team')
require('./store/Client')
require('./store/System')
require('./store/User')
require('./store/Standard')
require('./store/Impact')
require('./store/Dashboard')
require('./store/Theme')
require('./store/Meta')
require('./store/Layout')
require('./store/Auth')

const root = document.getElementById('root')

render(<App />, root)
