import React from 'react'
import { DetailText, PaddedInContentBox } from '../../styles/Components/ContentBox'
import DetailPairs from '../RequestDetailSharedComponents/DetailPairs'
import { isBlank } from 'txstate-utils'

const ReleaseClosure = ({ closed }) => {
  return <>
    <DetailPairs pairs={[
      { name: 'Closed By', value: closed.user.fullname || closed.user.username },
      { name: 'Closed Date', value: closed.date }
    ]} />
    {!isBlank(closed.lessons) &&
      <PaddedInContentBox>
        <DetailText title='Lessons Learned' text={closed.lessons} />
      </PaddedInContentBox>}
  </>
}

export default ReleaseClosure
