import React from 'react'
import styled from 'styled-components'
import { Icon } from './Common'

export const StyledTable = styled.table.attrs({
  className: 'table table-hover'
})``

export const StyledTHead = styled.thead.attrs({
  className: 'thead-light'
})``

export const StyledTR = styled.tr``

export const StyledTH = styled.th.attrs({
  scope: 'col'
})``

export const StyledTD = styled.td`
`

const ArrowTDFunc = props => (
  <StyledTD {...props}><Icon className='fas fa-angle-right' /></StyledTD>
)

export const ArrowTD = styled(ArrowTDFunc)`
  width: 50px;
  text-align: center;
`
