import React from 'react'
import styled from 'styled-components'
import { SubjectTitle } from './Common'
import { isBlank, isEmpty } from 'txstate-utils'

const InnerBox = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(100, 100, 100, 0.4);
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  ${props => props.scrollable && `
    overflow-y: auto;
    height: 100%;
  `}
`

const OuterBox = styled.div`
  margin: 0 auto;
  padding-bottom: 20px;
  padding-left: ${props => props.right || props.middle ? '10px' : '20px'};
  padding-right: ${props => props.left || props.middle ? '10px' : '20px'};
  ${props => props.scrollable && `
    height: 100%;
  `}
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    padding-bottom: 10px;
    padding-left: ${props => props.right || props.middle ? '5px' : '10px'};
    padding-right: ${props => props.left || props.middle ? '5px' : '10px'};
  }
`

export const ContentBox = ({ children, scrollable, left, right, middle }) => {
  return (
    <OuterBox left={left} right={right} middle={middle} scrollable={scrollable}>
      <InnerBox scrollable={scrollable}>
        {children}
      </InnerBox>
    </OuterBox>
  )
}

// A div that contains content boxes
export const ContentBoxContainer = styled.div`
  padding-top: 20px;
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    padding-top: 10px;
  }
`

// When inside a content box and you want to stretch a background color
// to the edges
export const FullWidthInContentBox = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    margin-bottom: 15px;
  }
`

// Typical block inside a content box, only bottom padding
export const PaddedInContentBox = styled.div`
  padding: 0 20px 20px 20px;
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    padding: 0 15px 15px 15px;
  }
`

// For the first block inside a content box, has top padding
export const HeaderInContentBox = styled.div`
  padding: 20px;
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    padding: 15px;
  }
`

export const Divider = styled.hr`
  height: 1.5px;
  width: 100%;
  background-color: #AFAFAF;
  margin: 8px 0 0 0;
`

const HeaderFlexParent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a { line-height: 1; }
`

export const StyledHeaderInContentBox = styled(props => {
  const level = props.level || 1
  const Tag = 'h' + level
  return (
    <HeaderInContentBox className={props.className}>
      <HeaderFlexParent>
        {props.title && <Tag>{props.title}</Tag>}
        {props.children}
      </HeaderFlexParent>
      <Divider />
    </HeaderInContentBox>
  )
})`
h1, h2, h3, h4, h5, h6 { margin: 0; }
h1 { font-size: 2rem; }
h2 { font-size: 1.5rem; }
h3 { font-size: 1.2rem; }
h4 { font-size: 1rem; }
h5 { font-size: 1rem; }
h6 { font-size: 1rem; }
`

export const CollapsableHeader = styled(HeaderInContentBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a { line-height: 1; }
`

const DetailTextStyled = styled.p`
  font-size: .9rem;
`
export const DetailText = ({ title, text, style, children }) => {
  if (isBlank(text) && isEmpty(children)) return null
  return (
    <>
      {!isBlank(title) ? <SubjectTitle>{title}</SubjectTitle> : null}
      <DetailTextStyled style={style}>
        {!isBlank(text) && text.trim().split('\n').map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          )
        })}
        {children}
      </DetailTextStyled>
    </>
  )
}

export const DetailPair = ({ label, value }) => (
  <div><strong>{label}:</strong> {typeof value === 'boolean' ? value ? 'Yes' : 'No' : value}</div>
)
