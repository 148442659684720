import mirror from 'mirrorx'

mirror.model({
  name: 'layout',
  initialState: {
    sidebarCollapsed: true
  },
  reducers: {
    toggleSidebar: (state) => ({
      ...state,
      sidebarCollapsed: !state.sidebarCollapsed
    })
  }
})
