import React, { Component } from 'react'
import { FormButton as Button } from '../../styles/Components/Common'

class Add extends Component {
  onClick = e => {
    const { onClick } = this.props
    e.preventDefault()
    onClick && onClick()
  }

  render () {
    const { label, style } = this.props
    return (
      <Button style={style} variant='secondary' onClick={this.onClick}>
        {label}
        <i className='ion-plus' style={{ marginTop: 3, marginLeft: 10 }} />
      </Button>
    )
  }
}

export default Add
