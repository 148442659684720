import React, { Component } from 'react'
import { actions } from 'mirrorx'
import styled from 'styled-components'
import { isBlank } from 'txstate-utils'
import { FormButton, InvalidFeedback } from '../../styles/Components/Common'
import withBasicForm from '../../utils/basicFormProvider'

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

class ReplyForm extends Component {
  handleSubmit = e => {
    e.preventDefault()
    const { type, id, syncState, formState: { answer } } = this.props
    if (isBlank(answer)) {
      syncState({ isFormValid: false })
    } else {
      actions[type].answerQuestion({ id, type, body: answer })
      syncState({ answer: '', isFormValid: true })
    }
  }

  render () {
    const {
      owner,
      handleInputChange,
      formState: { answer, isFormValid = true }
    } = this.props
    return (
      <>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className='form-group pt-3'>
            <label className='sr-only' htmlFor='answer-input'>
              Reply to {owner}
            </label>
            <textarea
              placeholder={`Reply to ${owner}`}
              onChange={handleInputChange}
              className='form-control'
              id='answer-input'
              value={answer}
              name='answer'
              rows='3'
              required
            />
            <InvalidFeedback isFormValid={isFormValid}>
              Please provide a valid answer
            </InvalidFeedback>
          </div>
          <div className='form-group'>
            <ButtonBar>
              <FormButton className='muted' onClick={() => this.props.onCancel(!isBlank(answer))}>
                Cancel
              </FormButton>
              <FormButton type='submit' className='nm'>
                Reply
              </FormButton>
            </ButtonBar>
          </div>
        </form>
      </>
    )
  }
}
export default withBasicForm(ReplyForm)
