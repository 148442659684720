import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const MultiSelect = styled(Select)`
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    width: 80%;
  }
`

const SelectFilter = ({ label = '', name, options = [], value, handleChange, single, lowerCase }) => {
  const buildOptions = (options) => options.reduce((accum, item) => {
    const [value, label] = item
    accum.push({ value, label })
    return accum
  }, [])

  return (
    <div className='form-group'>
      <label className='form-check-label' htmlFor={`filter-${label}`}>
        {lowerCase ? label.replace('-', ' ') : label.replace('-', ' ').toUpperCase()}
      </label>
      <MultiSelect
        isMulti={!single}
        isClearable
        isSearchable
        name={name}
        id={`filter-${label}`}
        options={buildOptions(options)}
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}
export default SelectFilter
