import React, { Component } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import DashHeader from './DashHeader'
import DashBody from './DashBody'
import EmptyState from '../../Empty/EmptyState'

const Title = styled.h3`
  font-size: ${props => props.theme.dashboard.table.title.fontSize};
`

const Table = styled.table`
  border-collapse: separate;
  border-spacing: ${props => props.actionable ? '0 10px' : 'inherit'};
  margin: ${props => props.actionable ? '-10px 0 0 0' : '0'};
`

class DashTable extends Component {
  handleRowClick = ({ modelName, id, scroll }) => {
    const { history } = this.props
    const hash = scroll !== undefined ? `#scroll=${scroll}` : ''
    history.push(`/${modelName}/${id}${hash}`)
  }

  render () {
    const { headers, data, title, actionable, emptyState } = this.props
    const classList = 'table table-borderless table-hover'

    if (!data.length) {
      return <EmptyState {...emptyState} />
    }
    return (
      <Container fluid>
        {title ? <Title>{title}</Title> : null}
        <Table className={classList} actionable={actionable}>
          <DashHeader headers={headers} actionable={actionable} />
          <DashBody onRowClick={this.handleRowClick} headers={headers} actionable={actionable} data={data} />
        </Table>
      </Container>
    )
  }
}
export default DashTable
