import React, { Component } from 'react'
import $ from 'jquery'
import Portal from './Portal'
import styled from 'styled-components'
import { FormButton } from '../../styles/Components/Common'
import {
  Modal,
  ModalBackdrop,
  ModalDialog,
  ModalCentered,
  ModalContent,
  ModalBody
} from '../../styles/Components/Modal'

const CenteredBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
`

const CenteredText = styled.p`
  font-size: 1.1rem;
  text-align: center;
`

class PromptModal extends Component {
  componentDidMount () {
    $('#prompt-input').focus()
  }

  textInput = React.createRef()

  handleSubmit = e => {
    e.preventDefault()
    const inputField = $('#prompt-input')
    if (inputField.val().length < 2) {
      inputField.classList = 'form-control is-invalid'
    } else {
      inputField.classList = 'form-control is-valid'
      this.props.onConfirm()
    }
  }

  render () {
    const { onClose, promptText, onInput } = this.props
    return (
      <Portal>
        <ModalBackdrop onClick={onClose} />
        <Modal>
          <ModalDialog>
            <ModalCentered>
              <ModalContent>
                <ModalBody>
                  <form onSubmit={this.handleSubmit} noValidate>
                    <div className='form-group'>
                      <CenteredText>{promptText}</CenteredText>
                      <label className='sr-only' htmlFor='prompt-input'>{promptText}</label>
                      <textarea
                        ref={this.textInput}
                        rows='3'
                        className='form-control'
                        id='prompt-input'
                        minLength='2'
                        onChange={onInput}
                        required
                      />
                      <div className='invalid-feedback'>
                        Please provide a valid reason
                      </div>
                    </div>
                    <CenteredBox>
                      <FormButton type='submit'>
                        Submit
                      </FormButton>
                      <FormButton
                        className='muted'
                        onClick={onClose}
                      >
                        Cancel
                      </FormButton>
                    </CenteredBox>
                  </form>
                </ModalBody>
              </ModalContent>
            </ModalCentered>
          </ModalDialog>
        </Modal>
      </Portal>
    )
  }
}
export default PromptModal
