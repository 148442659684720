import React from 'react'
import styled from 'styled-components'
import UploadDisplay from '../Uploads/UploadDisplay'
import { SubjectTitle } from '../../styles/Components/Common'
import { PaddedInContentBox, DetailText } from '../../styles/Components/ContentBox'
import { Link } from 'react-router-dom'

const ChangeRequestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 7px;
  .change-description { margin: 0 }
`

const ChangeRequestLink = styled.div`
  font-size: 0.8rem;
  margin-left: 0.5rem;
`

const ReleaseDescription = ({ release }) => {
  const change = release.change
  return (
    <PaddedInContentBox>
      <ChangeRequestContainer>
        <SubjectTitle className='change-description'>Change Request Description</SubjectTitle>
        <ChangeRequestLink>(<Link to={'/change/' + change.id}>View Change Request</Link>)</ChangeRequestLink>
      </ChangeRequestContainer>
      <DetailText style={{ clear: 'both' }} text={change.description} />
      <DetailText title='Change Impact' text={change.impacted} />
      <DetailText title='Release Request Description' text={release.description} />
      <DetailText title='Instructions for Prod Team' text={release.instructions} />
      <DetailText title='Communication Plan Details' text={release.commplandetails} />
      <UploadDisplay title='Release Documents' uploads={release.uploads} />
    </PaddedInContentBox>
  )
}

export default ReleaseDescription
