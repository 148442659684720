import React, { Component } from 'react'
import {
  countVotes,
  pairNumbers,
  everyOther,
  compose,
  pipe
} from '../../utils/helpers'
import VotingRow from './VotingRow'
import Container from 'react-bootstrap/Container'
import { connect, actions } from 'mirrorx'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import { VOTING_TYPES } from '../../utils/constants'
import DelegateVotingModal from './DelegateVotingModal'
import { voteBuilder } from '../../utils/builders'
import PromptModal from '../Portal/PromptModal'
import Banner from '../../utils/banner'

const HeaderContainer = styled.div`
  margin-bottom: 1.5em;
`

const HeaderTitle = styled.span`
  font-size: 1.2rem;
`

const VotingIcon = styled.i`
  color: ${props => props.theme.common.colors.activeBlue};
  padding: 7px;

  &.lg {
    font-size: 1.2rem;
  }
`

class VotingSummary extends Component {
  state = {
    isConfirmOpen: false,
    isPromptOpen: false,
    reason: null,
    vote: {}
  }

  handleShowConfirmation = currentVote => {
    this.setState({
      isConfirmOpen: true,
      vote: currentVote
    })
  }

  handleHideConfirmation = () => {
    this.setState({
      isConfirmOpen: false,
      vote: {}
    })
  }

  handleTogglePrompt = () => {
    this.setState({
      isPromptOpen: !this.state.isPromptOpen
    })
  }

  handleReasonInput = e => {
    this.setState({
      reason: e.target.value
    })
  }

  handlePromptConfirmClicked = () => {
    this.handleTogglePrompt()
    this.completeVote()
  }

  handleConfirmClicked = () => {
    const { delegate } = this.state.vote
    if (delegate) {
      this.handleTogglePrompt()
    } else {
      this.completeVote()
    }
  }

  completeVote = () => {
    const { user, type } = this.state.vote
    const { id, modelName } = this.props.change
    const vote = this.createVote(user.id, type.action)
    this.handleHideConfirmation()
    this.postDelegateVote(id, modelName, vote)
  }

  postDelegateVote = async (id, type, vote) => {
    const constructVote = pipe(
      voteBuilder.addId(id),
      voteBuilder.addType(type),
      voteBuilder.addVote(vote)
    )
    await actions[type].postVote(constructVote(voteBuilder.basis))
  }

  getDeniedReason = () => {
    const reason = window.prompt(
      'Please provide a reason for denying this request.'
    )
    return reason
  }

  checkVote = userVote => {
    return (
      (userVote.vote === 'deny' && !!userVote.reason) ||
      userVote.vote !== 'deny'
    )
  }

  createVote = (id, vote) => {
    const user = { id }
    const { delegate } = this.state.vote
    const constructVote =
      delegate
        ? pipe(
          voteBuilder.addReason(this.state.reason),
          voteBuilder.addUser(user),
          voteBuilder.addVote(vote)
        )
        : compose(
          voteBuilder.addUser(user),
          voteBuilder.addVote(vote)
        )
    return constructVote(voteBuilder.basis)
  }

  voteIcon = {
    approve: <VotingIcon className='mdi mdi-thumb-up' />,
    deny: <VotingIcon className='mdi mdi-thumb-down' />,
    notapplicable: <VotingIcon className='ion-minus-round lg' />
  }

  getVoteProps = vote => {
    const approve = 'approve'
    const deny = 'deny'
    const notapplicable = 'notapplicable'
    const voteProps = { type: VOTING_TYPES.pending.display }
    switch (vote) {
      case approve:
        voteProps.icon = this.voteIcon.approve
        voteProps.type = VOTING_TYPES.approved.displayPast
        return voteProps
      case deny:
        voteProps.icon = this.voteIcon.deny
        voteProps.type = VOTING_TYPES.denied.displayPast
        return voteProps
      case notapplicable:
        voteProps.icon = this.voteIcon.notapplicable
        voteProps.type = VOTING_TYPES.na.displayPast
        return voteProps
      default:
        return voteProps
    }
  }

  createVoteTable = change => {
    if (change.votes) {
      return change.votes.map(this.createVoteRow)
    }
  }

  checkDelegate = userVote => {
    return (userVote.actions || {}).vote
  }

  createVoteRow = (userVote, index, voteArr) => {
    const { user, votedby, reason } = userVote
    const pairs = this.props.isMobile
      ? everyOther(voteArr.length + 1)
      : pairNumbers(voteArr.length + 1)
    const background = pairs.includes(index) ? { background: '#F2F5F8' } : null
    const voteProps = this.getVoteProps(userVote.vote)
    const delegate = this.checkDelegate(userVote)
    const changeProps = {
      id: this.props.change.id,
      type: this.props.change.modelName
    }

    return (
      <VotingRow
        key={user.id}
        changeProps={changeProps}
        vote={voteProps}
        votedby={votedby}
        delegate={delegate}
        openModal={this.handleShowConfirmation}
        background={background}
        user={user}
        reason={reason}
      />
    )
  }

  getVoteError = errors => {
    return errors.reduce((accum, current) => {
      if (current && current.error) {
        accum += ` ${current.error}`
      }
      return accum
    }, '')
  }

  renderVotingSummary = () => {
    const { change, errors } = this.props
    const title =
      change.modelName === 'change' ? 'Team CAB' : 'IT CAB'
    const votes = this.createVoteTable(change)
    const bannerTop = (document.getElementById('voting-summary') || {}).offsetTop
    return (
      <Container className='my-4' id='voting-summary'>
        <Container>
          {!!errors.length &&
            Banner.sendError({
              message: this.getVoteError(errors),
              duration: 15000,
              position: {
                top: bannerTop || 8,
                left: 0,
                right: 0
              }
            })}
          <HeaderContainer>
            <Row>
              <Col xs='auto'>
                <HeaderTitle>{title}</HeaderTitle>
              </Col>
              <Col xs='auto'>
                <HeaderTitle>{countVotes(change)}</HeaderTitle>
              </Col>
            </Row>
          </HeaderContainer>
          <Row>{votes}</Row>
        </Container>
      </Container>
    )
  }

  render () {
    return (
      <>
        {this.renderVotingSummary()}
        {this.state.isConfirmOpen && !this.state.isPromptOpen && (
          <DelegateVotingModal
            closeDialog={this.handleHideConfirmation}
            vote={this.state.vote}
            onConfirmClicked={this.handleConfirmClicked}
          />
        )}
        {this.state.isPromptOpen && (
          <PromptModal
            onClose={this.handleTogglePrompt}
            onInput={this.handleReasonInput}
            onConfirm={this.handlePromptConfirmClicked}
            promptText={this.state.vote.type === VOTING_TYPES.approved
              ? 'Please provide a reason for approving this request.'
              : this.state.vote.type === VOTING_TYPES.denied
                ? 'Please provide a reason for denying this request.'
                : 'Please provide a reason for choosing N/A for this request.'}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  const errors = Array.of(
    state.change.voteError,
    state.release.voteError,
    state.standard.voteError
  ).filter(v => v !== null)
  return {
    errors
  }
}

export default connect(mapStateToProps)(VotingSummary)
