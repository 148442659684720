import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import TextArea from '../Form/TextArea'
import Form from 'react-bootstrap/Form'
import FormManager from '../Form/FormManager'
import Col from 'react-bootstrap/Col'
import ButtonSelect from '../Form/ButtonSelect'
import { displayReleaseType } from '../../utils/transmogrifier'
import { RELEASE_TYPES } from '../../utils/constants'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import DatePicker from '../Form/DatePicker'
import DocumentUpload from '../Form/DocumentUpload/DocumentUpload'
import Container from 'react-bootstrap/Container'
import UserSelect from './UserSelect'
import Loader from '../Loading/Loader'
import Alert from 'react-bootstrap/Alert'
import ErrorAlert from '../ErrorAlert/ErrorAlert'
import { actions, connect } from 'mirrorx'
import { FormButton } from '../../styles/Components/Common'
import { isEmpty } from 'txstate-utils'

class ReleaseRequest extends Component {
  componentDidMount () {
    const { change } = this.props
    actions.release.formInit()
    actions.team.getTeam(change.team.id)
    if (change.standard && change.standard.id) actions.standard.getStandard(change.standard.id)
    else actions.standard.formInit()
  }

  form = React.createRef()
  documentUpload = React.createRef()
  releaseTypes = Object.keys(RELEASE_TYPES)

  handleSubmit = ({ event, data }) => {
    event.preventDefault()
    event.stopPropagation()

    const form = this.form.current
    const isValid = form.checkValidity()

    if (isValid) {
      this.postForm(data)
    }
  }

  handleCancel = (touched, resetForm) => () => {
    const prompt = 'Are you sure you want to cancel the release request?'
    if (!touched || (touched && window.confirm(prompt))) {
      resetForm && resetForm()
      this.form.current.reset()
    }
  }

  postForm = async data => {
    const { change } = this.props
    if (data.developer && data.developer.id === '0') {
      data.specialdeveloper = 'Vendor'
    }
    await actions.release.createRelease({
      changeid: change.id,
      data: data
    })
  }

  sameTeam = change => {
    return change.team.id === change.prodteam.id
  }

  findProdUser = (users, userId) => {
    try {
      const otherUsers = users.filter(user => user.id !== userId)
      return otherUsers.length ? otherUsers.shift().id : userId
    } catch (err) {
      return userId
    }
  }

  renderForm () {
    const { change, team, standard, user, errors, acceptedFileTypes } = this.props
    // wait for standard to be loaded
    if (change.standard && change.standard.id && isEmpty(standard)) return null
    const prodUserId = this.findProdUser(team.users, user.id)
    const initialValues = {
      uploads: [],
      type: change.type,
      developer: { id: user.id },
      instructions: standard ? standard.instructions : '',
      reviewer: { id: prodUserId }
    }
    return (
      <Container className='pb-3'>
        <Row>
          <FormManager initialValues={initialValues}>
            {({
              handleChange,
              values,
              touched,
              validated,
              onSubmit,
              resetForm
            }) => (
              <Col xs={12} lg={8}>
                <Form
                  ref={this.form}
                  noValidate
                  validated={validated}
                  style={{ width: '100%' }}
                >
                  {change.type === 'normal' ? (
                    <Col xs={12}>
                      <ButtonSelect
                        label='Release Type'
                        name='type'
                        value={values.type}
                        valueToDisplay={displayReleaseType}
                        values={this.releaseTypes}
                        onChange={handleChange}
                      />
                    </Col>
                  ) : null}
                  {this.sameTeam(change) ? (
                    <>
                      <Col xs={12}>
                        <UserSelect
                          name='developer'
                          label='Who was the primary developer for this change?'
                          value={values.developer.id}
                          values={[{ id: 0, fullname: 'Vendor' }, ...team.users]}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={12}>
                        <UserSelect
                          name='reviewer'
                          label='Who is moving the release to production?'
                          value={values.reviewer.id}
                          values={team.users}
                          onChange={handleChange}
                        />
                      </Col>
                    </>
                  ) : null}
                  <Col xs={12}>
                    {+values.developer.id === +values.reviewer.id ? (
                      <Alert variant='warning'>
                        <Alert.Heading>Warning!</Alert.Heading>
                        <span>
                          When the primary developer moves the change to
                          production, it will be a flag for auditors.
                        </span>
                      </Alert>
                    ) : null}
                  </Col>
                  <Col xs={12}>
                    <TextArea
                      name='description'
                      label='Description of Release Request'
                      feedback="Your description can't be blank"
                      optional={change.prodonly || change.type !== 'normal' || values.type !== 'normal'}
                      onChange={handleChange}
                    />
                  </Col>
                  {change.commplan ? (
                    <Col xs={12}>
                      <TextArea
                        name='commplandetails'
                        label='Communication Plan Details'
                        feedback="Your communication plan can't be blank"
                        onChange={handleChange}
                      />
                    </Col>) : null}
                  <Col xs={12}>
                    <TextArea
                      name='instructions'
                      label='Instructions for Production Team'
                      value={values.instructions}
                      optional
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={12}>
                    <DatePicker
                      onChange={handleChange}
                      name='intended'
                      label='Intended Release Date'
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <DocumentUpload
                      name='uploads'
                      ref={this.documentUpload}
                      formValidated={validated}
                      fileTypes={acceptedFileTypes}
                      required={(change.client || {}).id && (change.type === 'expedited' || ['normal', 'expedited'].includes(values.type))}
                      requiredDoc={acceptedFileTypes[0].id}
                      values={values.uploads}
                      onChange={handleChange}
                    />
                  </Col>
                  <ErrorAlert errors={errors} />
                  <Col xs={12}>
                    <ButtonToolbar className='mt-3'>
                      <FormButton
                        variant='secondary'
                        className='mr-3'
                        onClick={onSubmit(this.handleSubmit)}
                      >
                        Submit
                      </FormButton>
                      <FormButton
                        className='muted'
                        onClick={this.handleCancel(touched, resetForm)}
                      >
                        Cancel
                      </FormButton>
                    </ButtonToolbar>
                  </Col>
                </Form>
              </Col>
            )}
          </FormManager>
        </Row>
      </Container>
    )
  }

  render () {
    const { loading, errors } = this.props
    return loading ? <Loader errors={errors} /> : this.renderForm()
  }
}

ReleaseRequest.defaultProps = {
  acceptedFileTypes: [
    { name: 'Client Signoff', id: 'signoff' },
    { name: 'Other', id: 'other' }
  ]
}

const mapStateToProps = state => {
  const team = state.team.current
  const standard = state.standard.current
  const user = state.auth.current
  const loading = !Object.keys(team).length || !Object.keys(user).length
  const errors = Array.of(state.team.error, state.user.errorCurrent, state.release.error)
  return {
    team,
    standard,
    user,
    loading,
    errors
  }
}

export default connect(mapStateToProps)(ReleaseRequest)
