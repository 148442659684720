import React, { Component } from 'react'
import styled from 'styled-components'
import { FormButton } from '../../styles/Components/Common'
import PromptModal from '../Portal/PromptModal'

const Container = styled.div`
`

const Description = styled.p`
    font-size: .75em;
    margin-bottom: .5em;
`

class CancelButton extends Component {
  state = {
    dialogOpen: false,
    reason: undefined
  }

  handleInput = (e) => {
    this.setState({
      reason: e.target.value
    })
  }

  handleModalToggle = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen
    })
  }

  handleConfirm = () => {
    const { reason } = this.state
    reason && this.props.onCancel(reason)
  }

  render () {
    return (
      <Container className={this.props.noContainerClassName ? undefined : 'mb-3'}>
        {this.props.description && <Description><strong>Cancel this Request</strong></Description>}
        <FormButton className={this.props.className || 'danger'} onClick={this.handleModalToggle}>{this.props.text || 'Cancel'}</FormButton>
        {this.state.dialogOpen &&
          (<PromptModal
            onClose={this.handleModalToggle}
            onConfirm={this.handleConfirm}
            onInput={this.handleInput}
            promptText={this.props.question || 'Why are you cancelling this request?'}
          />)}
      </Container>

    )
  }
}

export default CancelButton
