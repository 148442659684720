import React, { Component } from 'react'
import styled from 'styled-components'
import Loader from './Loader'

const TableRow = styled.tr`
  color: white;
  font-variant: small-caps;
  font-size: 40px;
  padding: 20px;
`

class TableLoader extends Component {
  render () {
    const { headers } = this.props
    return (
      <tbody>
        <TableRow>
          <td style={{ textAlign: 'center' }} colSpan={headers.length}>
            <Loader />
          </td>
        </TableRow>
      </tbody>
    )
  }
}

export default TableLoader
