import React from 'react'
import { ListEditorConnect } from '../ListEditorProvider'
import { CancelButton, SaveButton, DangerButton, ButtonGroup } from '../../../styles/Components/FormButtons'
import { FormProvider, FormConnect } from '../../AlternateForm/FormProvider'

const ConnectedSaveButton = FormConnect(state => ({ onClick: state.saveItem }))(SaveButton)

export const ListEditorForm = ListEditorConnect(state => ({
  item: state.item,
  cancelEditing: state.cancelEditing,
  apiSave: state.apiSave,
  apiValidate: state.apiValidate,
  apiDelete: state.apiDelete,
  onSave: state.onSave
}))(({ FormFields, item, cancelEditing, apiSave, apiValidate, apiDelete, onSave }) => (
  <FormProvider item={item} apiValidate={apiValidate} apiSave={apiSave} onSave={onSave}>
    <FormFields />
    <ButtonGroup>
      <div>
        <ConnectedSaveButton>Save</ConnectedSaveButton>
        <CancelButton onClick={cancelEditing}>Cancel</CancelButton>
      </div>
      <div>
        <DangerButton onClick={() => apiDelete(item)}>Delete</DangerButton>
      </div>
    </ButtonGroup>
  </FormProvider>
))
