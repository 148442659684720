import React, { Component } from 'react'
import $ from 'jquery'
import Portal from '../Portal/Portal'
import styled from 'styled-components'
import { FormButton } from '../../styles/Components/Common'
import {
  Modal,
  ModalBackdrop,
  ModalDialog,
  ModalCentered,
  ModalContent,
  ModalBody
} from '../../styles/Components/Modal'

const CenteredText = styled.p`
  text-align: center;
  margin-bottom: 0;
`

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

class DelegateVotingModal extends Component {
  componentDidMount () {
    $('#delegate-submit').focus()
  }

  render () {
    const { closeDialog, vote, onConfirmClicked } = this.props
    return (
      <Portal>
        <ModalBackdrop onClick={closeDialog} />
        <Modal>
          <ModalDialog>
            <ModalCentered>
              <ModalContent>
                <ModalBody>
                  <CenteredText>You are voting</CenteredText>
                  <CenteredText>
                    <strong>{vote.type.display}</strong> for <strong>{vote.user.fullname}</strong> is that correct?
                  </CenteredText>
                  <CenteredBox>
                    <FormButton type='submit' id='delegate-submit' onClick={onConfirmClicked}>Submit</FormButton>
                    &nbsp;
                    <FormButton className='muted' onClick={closeDialog}>Cancel</FormButton>
                  </CenteredBox>
                </ModalBody>
              </ModalContent>
            </ModalCentered>
          </ModalDialog>
        </Modal>
      </Portal>
    )
  }
}

export default DelegateVotingModal
