import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import FormManager from '../Form/FormManager'
import API from '../../api/api'
import ButtonSelect from '../Form/ButtonSelect'
import { actions } from 'mirrorx'
import { getValue } from '../../utils/helpers'
import Banner from '../../utils/banner'
import TextEdit from '../Form/TextEdit'
import FormButtons from '../Form/FormButtons'
import { withTheme } from 'styled-components'
import SelectFilter from '../Filter/Form/SelectFilter'

const sendSuccess = (message) => {
  Banner.sendSuccess({ message, duration: 3000 })
}

const sendError = (message) => {
  Banner.sendError({ message, duration: 3000 })
}

class UserForm extends Component {
  handleSubmit = async ({ data, event }) => {
    event && event.preventDefault()
    const username = data.username || ''
    const fullname = data.fullname || ''
    const id = data.id || null

    if (username.length <= 0) return
    if (fullname.length <= 0) return

    if (id) {
      this.submitUpdatedUser(data)
    } else {
      this.submitNewUser(data)
    }
  }

  submitNewUser = async (data) => {
    try {
      const res = await API.postUser(data)
      sendSuccess(`Successfully created user for ${data.fullname}`)
      const { root, history } = this.props

      history.push(`${root}/${res.id}`)

      actions.user.getUsers()
      actions.user.getUser(res.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  submitUpdatedUser = async (data) => {
    try {
      await API.putUser(data)
      sendSuccess(`Successfully updated ${data.fullname}`)
      this.props.toggleEdit()
      actions.user.getUsers()
      actions.user.getUser(data.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  onCancel = () => {
    const { edit, addNew, toggleEdit } = this.props
    if (edit) {
      toggleEdit()
      this.resetForm()
    }

    if (edit && addNew) {
      this.props.handleClose()
    }
  }

  getInputStyle = () => {
    const { theme } = this.props
    let style = {
      paddingLeft: '10px'
    }
    if (this.props.edit === false) {
      style = {
        ...style,
        marginBottom: 8,
        border: 'solid 1px #F0F0F0',
        borderRadius: '0.25em',
        backgroundColor: '#F8F9FA'
      }
    } else {
      style = {
        ...style,
        border: `solid 1px ${theme.common.colors.lightBlue}`
      }
    }
    return style
  }

  initialValues = () => {
    const { id = null, username, admin, fullname, releaseProxy } = this.props.data
    const incomingReleaseProxy = {
      value: (releaseProxy || {})._id,
      label: (releaseProxy || {}).fullname
    }
    return {
      id,
      admin: admin || false,
      username: username || '',
      fullname: fullname || '',
      releaseProxy: releaseProxy ? incomingReleaseProxy : undefined
    }
  }

  form = React.createRef()

  clearForm = () => {
    const form = this.form.current
    if (!form) return

    form.updateValues(
      { name: 'id', value: null },
      { name: 'fullname', value: '' },
      { name: 'username', value: '' },
      { name: 'admin', value: false },
      { name: 'changeProxy', value: undefined },
      { name: 'releaseProxy', value: undefined }
    )
  }

  resetForm = () => {
    const form = this.form.current
    if (!form) return
    form.resetForm()
  }

  handleDelete = async () => {
    const { data, toggleEdit, userCanDelete, history, root } = this.props
    if (userCanDelete && data.id) {
      await API.deleteUser(data)
      sendSuccess(`Successfully deleted ${data.fullname}`)
      toggleEdit()
      actions.user.getUsers()
      history.push && history.push(root)
    }
  }

  onClickSubmit = (onSubmit) => () => {
    onSubmit(this.handleSubmit)()
  }

  onClickEdit = () => {
    const { userCanEdit, toggleEdit, edit, addNew } = this.props
    if (!edit && (userCanEdit || addNew)) {
      toggleEdit()
    }
  }

  render () {
    const { edit, userCanEdit, userCanDelete, data, actions, addNew, handleClose, list } = this.props
    const userCanEditAdmin = getValue('actions.editadmin', data)
    const userCanCreateAdmin = getValue('createadmin', actions)

    const adminButtonDisabled = !edit || !(userCanEditAdmin || (userCanCreateAdmin && addNew))

    return (
      <FormManager ref={this.form} initialValues={this.initialValues()}>
        {
          ({ values, handleChange, onSubmit, resetForm, touched, validated }) => {
            return (
              <Form
                onSubmit={onSubmit(this.handleSubmit)}
                validated={validated}
              >
                <Form.Group>
                  <TextEdit
                    required
                    label='NetID'
                    name='username'
                    readOnly={!addNew}
                    plaintext
                    style={this.getInputStyle()}
                    value={values.username || ''}
                    placeholder={'enter user\'s netid'}
                    feedback='A valid NetID is required to create a user'
                    onChange={handleChange}
                  />
                  <TextEdit
                    required
                    label='Full Name'
                    feedback='A full name is required to create a user'
                    name='fullname'
                    readOnly={!edit}
                    plaintext={!edit}
                    style={this.getInputStyle()}
                    value={values.fullname || ''}
                    placeholder={'enter user\'s full name'}
                    onChange={handleChange}
                  />
                  <ButtonSelect
                    name='admin'
                    label='Admin User'
                    disabled={adminButtonDisabled}
                    value={values.admin}
                    values={[true, false]}
                    onChange={data => {
                      if (adminButtonDisabled) return
                      handleChange(data)
                    }}
                    valueToDisplay={(value) => value ? 'Yes' : 'No'}
                  />
                  <TextEdit
                    readOnly
                    plaintext
                    label='Change Proxy'
                    name='changeproxy'
                    style={this.getInputStyle()}
                    value={((data.team || {}).manager || {}).fullname || 'Not Applicable'}
                  />
                  {
                    edit ? (
                      <>
                        <SelectFilter
                          single
                          lowerCase
                          label='Release Proxy'
                          value={values.releaseProxy}
                          options={list.filter(l => !l.orphaned).map(l => ([l.id, l.fullname]))}
                          handleChange={(value) => {
                            handleChange({ name: 'releaseProxy', value })
                          }}
                        />
                        <Alert variant='warning'>
                          This designation is permanent until a new designation is made.
                        </Alert>
                      </>
                    ) : <>
                      <TextEdit
                        readOnly
                        plaintext
                        label='Release Proxy'
                        name='releaseproxy'
                        style={this.getInputStyle()}
                        value={(data.releaseProxy || {}).fullname || 'None Selected'}
                      />
                      {(data.releaseProxy || {}).orphaned && (
                        <Alert variant='danger'>
                          {(data.releaseProxy || {}).fullname} is no longer active
                        </Alert>
                      )}
                    </>
                  }
                </Form.Group>
                <FormButtons
                  edit={edit}
                  userCanEdit={userCanEdit}
                  showDelete={edit && userCanDelete}
                  deleteLabel='Deactivate'
                  onClickDelete={this.handleDelete}
                  onClickCancel={this.onCancel}
                  onClickClose={handleClose}
                  onClickSubmit={this.onClickSubmit(onSubmit)}
                  onClickEdit={this.onClickEdit}
                />
              </Form>
            )
          }
        }
      </FormManager>
    )
  }
}

export default withTheme(UserForm)
