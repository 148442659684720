import React, { Component } from 'react'
import { actions, connect } from 'mirrorx'
import qs from 'qs'
import $ from 'jquery'
import Loader from '../Loading/Loader'
import ReleaseInfo from './ReleaseInfo'
import Collapsable from '../Collapsable/Collapsable'
import VotingCollapsable from '../Voting/VotingCollapsable'
import ReleaseReport from '../ReleaseReport/ReleaseReport'
import ReleaseReportSummary from '../ReleaseReport/ReleaseReportSummary'
import LessonsLearned from '../LessonsLearned/LessonsLearned'
import ReleaseClosure from './ReleaseClosure'
import { ContentBoxContainer } from '../../styles/Components/ContentBox'
import CancelButton from '../RequestDetailSharedComponents/CancelButton'
import CloseButtonContainer from '../RequestDetailSharedComponents/CloseButtonContainer'

class ReleaseDetails extends Component {
  componentDidMount () {
    const { id } = this.props.match.params
    actions.release.getRelease(id)
    const hash = qs.parse(this.props.location.hash.slice(1), { arrayFormat: 'repeat' })
    hash.scroll && this.handleScroll(hash.scroll)
  }

  handleScroll (scroll) {
    const target = $(`#${scroll}`).offset()
    if (target) {
      $('#main-container').animate({
        scrollTop: target.top
      }, 1000)
    }
  }

  renderDetails () {
    const { release } = this.props
    return (
      <ContentBoxContainer>
        <ReleaseInfo release={release} />
        <VotingCollapsable doc={release} />
        {release.actions.report ? (
          <Collapsable title='Submit Release Report' startsOpen id='create-report'>
            <ReleaseReport release={release} />
          </Collapsable>
        ) : null}
        {release.report.date ? (
          <Collapsable title='Release Report Completed' startsOpen={release.closed.date}>
            <ReleaseReportSummary report={release.report} />
          </Collapsable>
        ) : null}
        {release.actions.close ? (
          <Collapsable title='Submit Lessons Learned & Close Release' startsOpen id='close-release'>
            <LessonsLearned release={release} />
          </Collapsable>
        ) : null}
        {release.closed.date ? (
          <Collapsable title='Release Closed' startsOpen id='close-release'>
            <ReleaseClosure closed={release.closed} />
          </Collapsable>
        ) : null}
        <CloseButtonContainer>
          {release.actions.delete && <CancelButton description onCancel={reason => actions.release.cancelRelease({ id: release.id, reason })} question='Why are you cancelling this release request?' />}
        </CloseButtonContainer>
      </ContentBoxContainer>
    )
  }

  render () {
    const { loading, errors } = this.props
    return loading ? <Loader errors={errors} /> : this.renderDetails()
  }
}

const mapStateToProps = state => {
  const release = state.release.current
  const change = release.change
  const loading = !Object.keys(release).length
  const errors = Array.of(state.release.error)
  return {
    release,
    change,
    loading,
    errors
  }
}

export default connect(mapStateToProps)(ReleaseDetails)
