import styled from 'styled-components'
export const Modal = styled.div`
  position: fixed;
  display: inline-block;
  justify-content: center;
  top: 30%;
  left: 50%;
  margin-left: -215px;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
`

export const ModalSidebar = styled(Modal)`
  top: 0;
  bottom: 0;
  right: 0; 
  left: auto;

  &.mobile {
    left: 0;
    margin-left: 0;
  }
`

export const ModalDialog = styled.div`
  position: relative;
  width: 430px;
  height: 100%;
  margin: ${props => props.sidebar ? '0' : '0.5rem'};
  pointer-events: none;
`

export const ModalCentered = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
`

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border: ${props => props.sidebar ? 'none' : '1px solid rgba(0, 0, 0, 0.2)'};
  border-radius: ${props => props.sidebar ? 'none' : '0.3rem'};
  outline: 0;
`

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`

export const ModalHeader = styled.div`
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
`

export const ModalTitle = styled.h5`
  margin-bottom: 0;
  line-height: 1.5;
`

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: ${props => props.sidebar ? '1rem' : '3rem'};
`

export const ModalFooter = styled.div`
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
`
