import React, { Component } from 'react'
import YesNoFilter from './YesNoFilter'

class ToggleFilter extends Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidMount () {
    this.props.shouldFocus && this.inputRef.current.focus()
  }

  render () {
    const { handleActive, handleBtnClick, value, label, title = '' } = this.props
    const parsedValue = value === undefined ? null : JSON.parse(value)
    return (
      <div className='form-group'>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={parsedValue !== null}
            onChange={handleActive}
            name={label}
            id={`filter-${label}`}
            ref={this.inputRef}
          />
          <label className='form-check-label' htmlFor={`filter-${label}`} title={title}>
            {label.replace(/-/g, ' ').toUpperCase()}
          </label>
        </div>
        <YesNoFilter isActive={parsedValue !== null} value={parsedValue} onClick={handleBtnClick} />
      </div>
    )
  }
}

export default ToggleFilter
