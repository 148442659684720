import mirror, { actions } from 'mirrorx'
import api from '../api/api'

const requestSystems = state => ({
  ...state,
  loading: true,
  error: null
})

const requestSystem = state => ({
  ...state,
  loadingOne: true,
  error: null
})

const systemsSuccess = (state, data) => ({
  ...state,
  loading: false,
  error: null,
  lastPage: data.finalpage || 0,
  list: data.documents || [],
  actions: data.actions
})

const systemSuccess = (state, data) => ({
  ...state,
  loadingOne: false,
  errorOne: null,
  current: data
})

const systemsFailure = (state, error) => ({
  ...state,
  loading: false,
  error
})

const systemFailure = (state, error) => ({
  ...state,
  loadingOne: false,
  errorOne: error
})

const clearCurrent = state => ({
  ...state,
  current: {}
})

mirror.model({
  name: 'system',
  initialState: {
    loading: false,
    loadingOne: false,
    error: null,
    errorOne: null,
    lastPage: null,
    list: [],
    actions: {},
    current: {}
  },
  reducers: {
    requestSystems,
    requestSystem,
    systemsSuccess,
    systemSuccess,
    systemsFailure,
    systemFailure,
    clearCurrent
  },
  effects: {
    async getSystems () {
      actions.system.requestSystems()
      try {
        const data = await api.getSystems()
        actions.system.systemsSuccess(data)
      } catch (error) {
        actions.system.systemsFailure({ error: error.message })
      }
    },
    async getSystem (id) {
      actions.system.requestSystem()
      try {
        const system = await api.getSystem(id)
        actions.system.systemSuccess(system)
      } catch (error) {
        actions.system.systemFailure({ error: error.message })
      }
    }
  }
})
