import mirror, { actions } from 'mirrorx'
import api from '../api/api'

mirror.model({
  name: 'standardList',
  initialState: {
    hasFetchedList: false,
    filtersModalOpen: false,
    loading: null,
    error: null,
    list: []
  },
  reducers: {
    requestStandards: state => ({
      ...state,
      loading: true,
      hasFetchedList: false,
      list: [],
      error: null
    }),
    standardsSuccess: (state, data) => ({
      ...state,
      actions: data.perms,
      loading: false,
      hasFetchedList: true,
      error: null,
      lastPage: data.finalpage || 0,
      list: data.standards || []
    }),
    standardsFailure: (state, error) => ({
      ...state,
      hasFetchedCurrent: false,
      hasFetchedList: false,
      loading: false,
      error
    }),
    filtersModalToggle: (state, filtersModalOpen) => ({
      ...state,
      filtersModalOpen
    })
  },
  effects: {
    async getStandards (params = {}, getState) {
      actions.standardList.requestStandards()
      try {
        const { documents: standards, finalpage, actions: perms } = await api.getStandards(params)
        actions.standardList.standardsSuccess({ standards, finalpage, perms })
      } catch (error) {
        actions.standardList.standardsFailure({ error: error.message })
      }
    },
    toggleFiltersModal (_, getState) {
      const modalState = getState().standardList.filtersModalOpen
      actions.standardList.filtersModalToggle(!modalState)
    }
  }
})
