import React from 'react'
import dayjs from 'dayjs'
import SimpleTable from '../../Table/SimpleTable/SimpleTable'
import { countVotes, initCap } from '../../../utils/helpers'

const ReleasesTable = ({ history, releases }) => {
  const handleRowClick = id => {
    id && history.push(`/release/${id}`)
  }

  const flattenRelease = release => {
    const { id, intended, created, type, status, change: { title } } = release
    const { team, prodteam } = release.change
    return {
      id,
      sortId: id.split('.').map(n => n.padStart(12, '0')).join('.'),
      title,
      type: initCap(type),
      status: initCap(status),
      teamName: team.shortname,
      prodTeam: prodteam.shortname,
      created: dayjs(created).format('MM-DD-YYYY h:mm a'),
      intended: dayjs(intended).format('MM-DD-YYYY'),
      votes: countVotes(release)
    }
  }

  const flatReleases = releases.map(release => flattenRelease(release))
  const columns = [
    { display: '#', accessor: 'id', sortOn: 'sortId' },
    { display: 'Title', accessor: 'title' },
    { display: 'Team', accessor: 'teamName' },
    { display: 'Type', accessor: 'type' },
    { display: 'Status', accessor: 'status' },
    { display: 'Prod Team', accessor: 'prodTeam' },
    { display: 'Intended', accessor: 'intended' },
    { display: 'Votes', accessor: 'votes' }
  ]
  return (
    <SimpleTable
      headers={columns}
      data={flatReleases}
      handleRowClick={handleRowClick}
    />
  )
}

export default ReleasesTable
