import React from 'react'
import { DetailPair } from '../../../styles/Components/ContentBox'

class SystemDetail extends React.PureComponent {
  render () {
    const { item } = this.props
    return <>
      <DetailPair label='Name' value={item.name} />
      <DetailPair label='Frequently Audited' value={item.important} />
    </>
  }
}

export default SystemDetail
