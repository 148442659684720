import React, { Component } from 'react'
import styled from 'styled-components'
import ReplyForm from './ReplyForm'
import { FormButton } from '../../styles/Components/Common'
import Post from './Post'

const Box = styled.div`
  margin: 15px 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(100, 100, 100, 0.4);
  border-radius: 3px;
  padding: 20px;
`

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
`

const Reply = styled.div`
  margin-left: 40px;
  padding-left: 10px;
  border-left: 2px solid ${props => props.theme.common.colors.darkestGray};
`

class Question extends Component {
  state = {
    formClosed: true
  }

  handleCancel = (touched) => {
    const prompt = 'Are you sure you want to cancel the reply?'
    if (!touched || (touched && window.confirm(prompt))) {
      this.handleFormExpand()
    }
  }

  handleFormExpand = () => {
    const formClosed = !this.state.formClosed
    this.setState({
      formClosed
    })
  }

  render () {
    const { answers = [], owner, id, requestType, timePosted, body, canAnswer } = this.props

    const answerReply = this.state.formClosed
      ? null
      : <ReplyForm id={id} type={requestType} owner={owner} onCancel={this.handleCancel} />

    const replyButton = this.state.formClosed
      ? <FormButton onClick={this.handleFormExpand} style={{ marginRight: 15 }}>Reply</FormButton>
      : null

    const answerList = answers.map((answer, index) => {
      return (
        <Reply key={index}>
          <Post owner={answer.user.fullname} timePosted={answer.date} body={answer.body} />
        </Reply>
      )
    })

    return (
      <Box>
        <Post owner={owner} timePosted={timePosted} body={body} />
        {answerList}
        {answerReply}
        <ButtonBar>
          {canAnswer ? replyButton : null}
        </ButtonBar>
      </Box>
    )
  }
}

export default Question
