const matchMagicNumber = (resolve, blob) => e => {
  const arr = new Uint8Array(e.target.result).subarray(0, 4)
  let header = ''
  for (let i = 0; i < arr.length; i++) {
    header += arr[i].toString(16)
  }
  let mimeType = blob.type

  switch (header) {
    case '89504e47':
      mimeType = 'image/png'
      break
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      mimeType = 'image/jpeg'
      break
    case '25504446':
      mimeType = 'application/pdf'
      break
    default:
      break
  }

  resolve(mimeType)
}

const getMimeType = (blob) => {
  return new Promise((resolve, reject) => {
    if (window.FileReader && window.Blob) {
      const fileReader = new window.FileReader()

      fileReader.onloadend = matchMagicNumber(resolve, blob)
      fileReader.readAsArrayBuffer(blob.slice(0, 4))
    } else {
      resolve(blob.type)
    }
  })
}

const humanFileSize = (bytes, si) => {
  const thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(0) + ' ' + units[u]
}

const isNewFile = (file, files) => {
  return files.filter(({ lastModified, size, name }) => {
    const { lastModified: newLastModified, size: newSize, name: newName } = file
    const exists = newLastModified === lastModified && newSize === size && newName === name
    return exists
  }).length <= 0
}

const isFileDropped = e => {
  if (!e.dataTransfer) {
    return true
  }

  return e.dataTransfer.types.every(type => type === 'Files' || type === 'application/x-moz-file')
}

export {
  getMimeType,
  humanFileSize,
  isNewFile,
  isFileDropped
}
