import React from 'react'
import styled from 'styled-components'
import { ActionButton } from '../../../styles/Components/Common'
import actionResolver from '../../../utils/actionResolver'
import { a11yOnClick } from '../../../utils/helpers'

const Row = styled.p`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 2px solid ${props => props.theme.common.colors.lightGray};
  padding-bottom: 12px;
  margin: 12px;
  cursor: pointer;
  word-break: break-word;
`

const ActionLink = styled.i`
  color: ${props => props.theme.dashboard.table.icon.textColor};
  font-size: ${props => props.theme.dashboard.table.icon.fontSize};
  text-align: right;
`

const DashRow = ({ item, user, onClick, actionable }) => {
  const content = `#${item.id}-${item.title || item.change.title}`
  const action = actionResolver(item)
  const rowClick = onClick(action.type, action.id)
  return (
    <>
      <Row {...a11yOnClick(rowClick)}>
        {content}
        {actionable ? (
          <ActionButton
            onClick={rowClick}
          >
            {action.text}
          </ActionButton>
        ) : (
          <ActionLink className='fas fa-angle-right' />
        )}
      </Row>
    </>
  )
}
export default DashRow
