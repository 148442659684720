import { SUCCESS, WARNING, ERROR, EVENT_NAME } from '../components/Banner/Banner'
import Subject from './subject'

const Banner = {
  sendError (options) {
    Subject.next(EVENT_NAME, {
      ...options,
      type: ERROR
    })
  },
  sendWarning (options) {
    Subject.next(EVENT_NAME, {
      ...options,
      type: WARNING
    })
  },
  sendSuccess (options) {
    Subject.next(EVENT_NAME, {
      ...options,
      type: SUCCESS
    })
  }
}

Object.freeze(Banner)

export default Banner
