import React from 'react'
import DashTable from '../../Table/DashboardTable/DashTable'
import { initCap, countVotes, get } from '../../../utils/helpers'
import DashList from '../../Table/DashboardTable/DashList'

const VotingDisplay = ({ voting: { changes, releases, standards }, history, isMobile }) => {
  const emptyState = {
    quote: 'Enjoy your free space travel',
    description: 'There are no requests pending approval.'
  }

  const fieldReducer = (accum, item) => {
    const data = {
      ...item,
      title: item.title || (item.change || {}).title,
      type: initCap(item.type) || 'Master',
      team: item.team || item.change.prodteam,
      releases: item.releases || get(['change', 'releases'], item),
      votes: countVotes(item)
    }
    accum.push(data)
    return accum
  }

  const headers = [
    { display: 'Request #', accessor: 'id', nowrap: true },
    { display: 'Owner', accessor: 'creator.fullname', cleanbreak: true },
    { display: 'Title', accessor: 'title' },
    { display: 'Type', accessor: 'type', nowrap: true },
    { display: 'Team', accessor: 'team.name', cleanbreak: true },
    { display: 'Votes', accessor: 'votes', nowrap: true },
    { display: 'Submitted', accessor: 'created', daysago: true, cleanbreak: true }
  ]

  const required = changes.reduce(fieldReducer, [])
    .concat(releases.reduce(fieldReducer, []))
    .concat(standards.reduce(fieldReducer, []))

  return (
    isMobile
      ? <DashList title='Change Requests' history={history} emptyState={emptyState} data={required} />
      : <DashTable history={history} headers={headers} emptyState={emptyState} data={required} />
  )
}
export default VotingDisplay
