import React from 'react'
import Question from './Question'

const QuestionList = ({ change }) => {
  const { questions = [] } = change

  const renderQuestion = (question) => {
    return (
      <Question
        key={question.id}
        id={question.id}
        owner={question.user.fullname}
        timePosted={question.date}
        body={question.body}
        answers={question.answers}
        canAnswer={question.actions.answer}
        requestType={change.modelName}
      />
    )
  }

  return questions.map(renderQuestion)
}

export default QuestionList
