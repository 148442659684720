import React, { PureComponent } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

class TicketTitle extends PureComponent {
  onChange = e => {
    const { onChange } = this.props
    onChange && onChange(e.target)
  }

  render () {
    const { label, value, id, name } = this.props
    return (
      <Form.Group>
        <Form.Label htmlFor='title'>Title</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{label}</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required
            as='input'
            type='text'
            id={id}
            value={value}
            name={name}
            placeholder='Enter Title'
            onChange={this.onChange}
          />
          <Form.Control.Feedback type='invalid'>
            Please enter a brief title for this change request
          </Form.Control.Feedback>
        </InputGroup>

      </Form.Group>
    )
  }
}

export default TicketTitle
