import React from 'react'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import styled from 'styled-components'
import { VOTING_TYPES } from '../../utils/constants'
import MobileSelect from './MobileSelect'

const VoteItem = styled.div`
  box-sizing: content-box
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`

const VoteText = styled.p`
  margin: 0;
`

const VotedByText = styled.span`
  display: block;
  text-align: right;
  font-size: 0.8em;
  font-weight: bold;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const QuestionOutlineSVG = ({ size = 24 }) => {
  return <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' width={size} height={size}>
    <path fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z' clipRule='evenodd' />
  </svg>
}

const VotingRow = ({ vote, votedby, user, background, openModal, delegate, reason }) => {
  const handleApprovedClicked = () => {
    openModal && openModal({ user, delegate, type: VOTING_TYPES.approved })
  }

  const handleDeniedClicked = () => {
    openModal && openModal({ user, delegate, type: VOTING_TYPES.denied })
  }

  const handleNaClicked = () => {
    openModal && openModal({ user, delegate, type: VOTING_TYPES.na })
  }

  return (
    <Col sm={12} md={5} key={user.username}>
      <VoteItem style={background} id={user.id}>
        <VoteText>
          {vote.icon}
          {user.fullname}
        </VoteText>

        {delegate ? (
          <MobileSelect
            onApprovedClicked={handleApprovedClicked}
            onDeniedClicked={handleDeniedClicked}
            onNaClicked={handleNaClicked}
            title='Vote for this person'
          />
        ) : (
          <Wrapper>
            <VoteText style={{ lineHeight: 1.3 }}>
              {vote.type}
              {votedby && <VotedByText>by {votedby.username}</VotedByText>}
            </VoteText>
            {reason && (
              <OverlayTrigger
                placement='top'
                delay={{ show: 100, hide: 250 }}
                overlay={<Tooltip id='tooltip-top'>Reason: {reason}</Tooltip>}
              >
                <button className='clear-btn' style={{ marginLeft: 5 }}>
                  <QuestionOutlineSVG />
                </button>
              </OverlayTrigger>
            )}
          </Wrapper>
        )}
      </VoteItem>
    </Col>
  )
}

export default VotingRow
