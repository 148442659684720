import React, { Component } from 'react'
import styled from 'styled-components'
import BoxHeader from './BoxHeader'
import BoxContent from './BoxContent'
import { Box } from '../../styles/Components/Common'

const DashBox = styled(Box)`
  min-height: ${props => (props.isOpen ? '350px' : 'inherit')}
  margin: 8px 0;
`

class DashboardBox extends Component {
  state = {
    isChanged: false,
    isOpen: false
  }

  isOpen () {
    if (!this.props.isMobile) return true
    return this.state.isChanged ? this.state.isOpen : this.props.startsOpen
  }

  handleClick = () => {
    this.setState({
      isChanged: true,
      isOpen: !this.isOpen()
    })
  }

  render () {
    const isOpen = this.isOpen()
    return (
      <DashBox isOpen={isOpen}>
        <BoxHeader {...this.props} isOpen={isOpen} onClick={this.handleClick} />
        {isOpen ? (
          <BoxContent {...this.props} isOpen={isOpen}>
            {this.props.children}
          </BoxContent>
        ) : null}
      </DashBox>
    )
  }
}

DashboardBox.defaultProps = {
  startsOpen: true
}

export default DashboardBox
