import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'

class TeamSelect extends Component {
  onChange = e => {
    const { onChange, name } = this.props
    onChange && onChange({ name, value: { id: e.target.value } })
  }

  renderOption = team => {
    return (
      <option key={team.id} value={team.id}>
        {team.name}
      </option>
    )
  }

  render () {
    const { values, name, value, label, required, feedback } = this.props
    return (
      <Form.Group>
        <Form.Label htmlFor={name}>{label}</Form.Label>
        <Form.Control
          id={name}
          as='select'
          name={name}
          onChange={this.onChange}
          value={value || ''}
          required={required}
        >
          <option key={0} value=''>Please select a team</option>
          {values.map(this.renderOption)}
        </Form.Control>
        {feedback ? <Form.Control.Feedback type='invalid'>{feedback}</Form.Control.Feedback> : null}
      </Form.Group>
    )
  }
}

TeamSelect.defaultProps = {
  values: []
}

export default TeamSelect
