import React from 'react'
import UploadDisplay from '../Uploads/UploadDisplay'
import { PaddedInContentBox, DetailText } from '../../styles/Components/ContentBox'

const StandardDescription = ({ standard }) => {
  return (
    <PaddedInContentBox>
      <DetailText title='Description' text={standard.description} />
      <DetailText title='Change Impact' text={standard.impacted} />
      <DetailText title='Instructions for Prod Team' text={standard.instructions} />
      <UploadDisplay title='Documents' uploads={standard.uploads} />
    </PaddedInContentBox>
  )
}

export default StandardDescription
