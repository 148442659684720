import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import { generatestring } from '../../utils/helpers'

class TextEdit extends Component {
  constructor () {
    super()
    this.controlid = generatestring()
  }

  onChange = e => {
    const { onChange } = this.props
    onChange && onChange(e.target)
  }

  render () {
    const {
      type,
      required,
      name,
      readOnly,
      plaintext,
      style,
      value,
      placeholder,
      label,
      feedback
    } = this.props

    return (
      <Form.Group>
        <Form.Label htmlFor={this.controlid}>
          {label}
        </Form.Label>
        <Form.Control
          id={this.controlid}
          type={type}
          required={required}
          name={name}
          readOnly={readOnly}
          plaintext={plaintext}
          style={style}
          value={value}
          placeholder={placeholder}
          onChange={this.onChange}
        />
        <Form.Control.Feedback type='invalid'>{feedback}</Form.Control.Feedback>
      </Form.Group>
    )
  }
}

export default TextEdit
