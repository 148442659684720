import React, { Component } from 'react'
import SimpleRow from './SimpleRow'
import { sortByKey as by } from '../../../utils/helpers'

class SimpleBody extends Component {
  sortData = (data, sortProps) => {
    const { accessor, order } = sortProps
    const sortedData = [...data]
    if (accessor) {
      sortedData.sort(by(accessor, order))
    }

    return sortedData
  }

  createRows = (row, headers) => {
    const { handleDelete, handleRowClick, style } = this.props
    return (
      <SimpleRow
        style={style}
        data={row}
        headers={headers}
        handleDelete={handleDelete}
        handleRowClick={handleRowClick}
        key={row.id}
      />
    )
  }

  render () {
    const { data, headers, sortProps, selectedId } = this.props
    const sortedData = this.sortData(data, sortProps)
    const rows = sortedData.map((row) => {
      row.selected = String(row.id) === selectedId
      return this.createRows(row, headers)
    })

    return (
      <tbody>
        {rows}
      </tbody>
    )
  }
}

export default SimpleBody
