import React from 'react'

export default props => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 300 300'
    width={props.size}
    height={props.size}
  >
    <title>Artboard 2 copy 5</title>
    <path d='M259.87,68.79,245.2,54.12a24.34,24.34,0,0,0-34.43,0l-18,17.94,49.1,49.1,18-18A24.33,24.33,0,0,0,259.87,68.79Z' />
    <path d='M178.09,86.79,73.35,191.53a24.17,24.17,0,0,0-6.45,11.8l-4.44,44.73,52.71-2.49a24,24,0,0,0,7.28-4.94L227.19,135.89Zm-67,147.38H76.34V199.45H90.23v20.83h20.83Z' />
  </svg>
)
