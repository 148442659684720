import React from 'react'
import { ToggleButton } from '../../../styles/Components/Common'

const YesNoFilter = ({ isActive, value, onClick }) => {
  return (
    <div className='btn-group mt-2' role='group' aria-label='Yes or No'>
      <ToggleButton
        disabled={!isActive}
        className={value === true ? 'selected' : undefined}
        onClick={() => onClick(true)}
      >
        Yes
      </ToggleButton>
      <ToggleButton
        disabled={!isActive}
        className={value === false ? 'selected' : undefined}
        onClick={() => onClick(false)}
      >
        No
      </ToggleButton>
    </div>
  )
}
export default YesNoFilter
