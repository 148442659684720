import React, { Component } from 'react'
import Select from 'react-select'
import Selected from './Selected'
import { actions, connect } from 'mirrorx'
import { getValue, generatestring } from '../../../utils/helpers'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'

/**
 * FormSelect is a reusable select component based on react-select.
 */
class FormSelect extends Component {
  static propTypes = {
    /**
     * The mirrorx store key used for loading indicators and data fetching
     */
    source: PropTypes.string.isRequired,
    /**
     * The key name of the array the data is stored in. If source
     * is `user` and options is `list` then the component will look in
     * `state.user.list` for an array of data to use as select options
    */
    options: PropTypes.string.isRequired,
    /**
     * The name of the mirrorx action to fetch data. For example,
     * a value of `getUsers` will be used to call `actions.user.getUsers()`
     */
    getSource: PropTypes.string.isRequired,
    /**
     * How the data should be mapped. The object needs a label, value, and id at a minimum:
     * {
     *   id: Number,
     *   value: Any,
     *   label: String (displayed in the options and as a selected item)
     * }
     */
    dataMap: PropTypes.func.isRequired,
    /**
     * The selected filter will filter the list of options to include items as selected.
     * It is a normal function passed to `filter` which is called on each item in the
     * `source.options`
     */
    selectedFilter: PropTypes.func,
    /**
     * Sometimes the selected items come from a totally different place than the list of options,
     * or there is no reasonable way to filter the list of options. If this is set to a value
     * such as `team.current.cab` then the array at that location in the mirrorx store will have
     * the `dataMap` applied to its elements and it will be used as the default selected values.
     */
    defaultSelected: PropTypes.string,
    /**
     * onChange should be used as a way to transform data prior to form submission.
     */
    onChange: PropTypes.func,

    /**
     * The placeholder text for the react select element
     */
    placeholder: PropTypes.string
  }

  select = React.createRef()

  constructor () {
    super()
    this.labelid = generatestring()
  }

  onRemoveClick = () => {
    console.log('removed')
  }

  componentDidMount () {
    const { source, getSource } = this.props
    actions[source][getSource]()
  }

  clearValue = () => {
    const select = getValue('select.current.select', this)
    select && select.setValue([], 'clear')
  }

  blur = () => {
    this.select.current.blur && this.select.current.blur()
  }

  onChange = (...args) => {
    const { onChange } = this.props
    this.blur()
    onChange && onChange(...args)
  }

  getStyles = (isDisabled) => {
    const styles = {}
    const { theme } = this.props
    return {
      control: (provided, state) => {
        const borderColor = theme.common.colors.lightBlue

        if (state.isFocused === true) {
          styles.boxShadow = `0 0 0 1px ${borderColor}`
        } else {
          styles.boxShadow = provided.boxShadow
        }

        if (isDisabled) {
          styles['&:hover'] = {
            borderColor: provided['&:hover'].borderColor
          }
        } else {
          styles['&:hover'] = { borderColor }
        }

        const border = `solid 1px ${borderColor}`
        const newStyles = {
          ...provided,
          ...styles,
          ...(isDisabled ? {} : { border })
        }
        return newStyles
      }
    }
  }

  render () {
    const { label, name, data, loading, selected, isDisabled, hideDropdown, emptyMessage, placeholder } = this.props

    return <>
      {label ? <label htmlFor={this.labelid}>{label}</label> : null}
      <Select
        ref={this.select}
        isClearable
        inputId={this.labelid}
        components={{
          Control: props => (
            <Selected
              {...props}
              emptyMessage={emptyMessage}
              hideDropdown={hideDropdown}
            />
          )
        }}
        styles={this.getStyles(isDisabled)}
        isSearchable
        name={name || 'defaultselectname'}
        isMulti
        isLoading={loading}
        isDisabled={isDisabled}
        options={data}
        defaultValue={selected}
        placeholder={placeholder}
        controlShouldRenderValue={false}
        onChange={this.onChange}
      />
    </>
  }
}

const mapStateToProps = (state, ownProps) => {
  const { dataMap, source, values, options } = ownProps
  const storeData = state[source]
  const selected = values.map(dataMap)

  return {
    data: (getValue(options, storeData) || []).map(dataMap),
    loading: storeData.loading,
    selected
  }
}

export default withTheme(connect(mapStateToProps, null, null, { forwardRef: true, withRef: true })(FormSelect))
