import React, { PureComponent } from 'react'

class FilterIcon extends PureComponent {
  render () {
    const { width, height, color } = this.props
    return (
      <svg role='img' style={{ marginTop: '-3px' }} width={width} height={height} viewBox='0 0 100 100' aria-label='filters icon'>
        <g>
          <g style={{ fill: color }} transform='translate(50 50) scale(0.69 0.69) rotate(90) translate(-50 -50)'>
            <svg role='img' x='0px' y='0px' fill={color} version='1.1' viewBox='0 0 100 100'>
              <title>
                Filter Icon
              </title>
              <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
                <g fill={color} fillRule='nonzero'>
                  <path d='M69.5,47.5 L69.5,28.5 L66.5,28.5 L66.5,47.5 L62.5,47.5 L62.5,58.5 L66.5,58.5 L66.5,71.5 L69.5,71.5 L69.5,58.5 L73.5,58.5 L73.5,47.5 L69.5,47.5 Z M51.5,32.5 L51.5,28.5 L48.5,28.5 L48.5,32.5 L44.5,32.5 L44.5,43.5 L48.5,43.5 L48.5,71.5 L51.5,71.5 L51.5,43.5 L55.5,43.5 L55.5,32.5 L51.5,32.5 Z M33.5,56.5 L33.5,28.5 L30.5,28.5 L30.5,56.5 L26.5,56.5 L26.5,67.5 L30.5,67.5 L30.5,71.5 L33.5,71.5 L33.5,67.5 L37.5,67.5 L37.5,56.5 L33.5,56.5 Z' />
                </g>
              </g>
            </svg>
          </g>
        </g>
      </svg>
    )
  }
}
export default FilterIcon
