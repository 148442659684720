import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
`

const FadedText = styled.div`
  color: #b1b1b2;
  font-size: 0.9rem;
`

const Text = styled.p`
  display: inline-block;
`

const ChangeSubInfo = ({ fullname, team }) => {
  return (
    <Container>
      <Row>
        <Col xs={6} lg={4}>
          <VerticalBox>
            <FadedText>TICKET OWNER</FadedText>
            <Text>{fullname}</Text>
          </VerticalBox>
        </Col>
        <Col xs={6} lg={4}>
          <VerticalBox>
            <FadedText>TEAM</FadedText>
            <Text>{team}</Text>
          </VerticalBox>
        </Col>
      </Row>
    </Container>
  )
}
export default ChangeSubInfo
