import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

const HeadingRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const Title = styled.p`
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  padding-right: 15px;
`

const Time = styled.p`
  margin: 0;
  color: #999999;
  font-size: 0.9rem;
`

const Body = styled.p`
  margin: 15px 0;
  text-align: left;
`

const Post = ({ owner, timePosted, body }) => {
  const date = timePosted ? dayjs(timePosted).format('MMM D, YYYY @ h:mm a') : 'Unknown'
  return (
    <>
      <HeadingRow>
        <Title>{owner}</Title>
        <Time>{date}</Time>
      </HeadingRow>
      <Body>
        {body.split('\n').map((item, key) => <React.Fragment key={key}>{item}<br /></React.Fragment>)}
      </Body>
    </>
  )
}

export default Post
