import React from 'react'
import styled from 'styled-components'
import { connect } from 'mirrorx'
import { ContentBox, ContentBoxContainer } from '../../styles/Components/ContentBox'
import { Overlay } from '../../styles/Components/Common'

const Container = styled(ContentBoxContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
`

const Pane = styled.div`
  width: ${props => props.full ? '100%' : '50%'};
  height: 100%;
`

const OverlayPane = props => {
  return (
    <Pane {...props} aria-hidden={props.overlay}>
      {props.children}
      {props.overlay && <Overlay />}
    </Pane>
  )
}

class PaneManager extends React.PureComponent {
  render () {
    const { left, right, isMobile, showRight, disableLeft } = this.props
    const showLeft = !isMobile || !showRight
    return <Container>
      {showLeft && <OverlayPane full={isMobile || !showRight} overlay={disableLeft}>
        <ContentBox scrollable left={!isMobile && showRight}>
          {left}
        </ContentBox>
      </OverlayPane>}
      {showRight && <Pane full={isMobile}>
        <ContentBox scrollable right={!isMobile}>
          {right}
        </ContentBox>
      </Pane>}
    </Container>
  }
}

export default connect(state => ({ isMobile: state.meta.isMobile }))(PaneManager)
