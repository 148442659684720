import React from 'react'
import styled from 'styled-components'
import { SecondaryTitle, StyledLink } from '../../styles/Components/Common'
import { ContentBox } from '../../styles/Components/ContentBox'

const HeaderRow = styled(StyledLink)`
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: inherit;
  &:hover { text-decoration: none; }
`

const ActionLink = styled.i`
  font-size: 1.7rem;
  color: #6c757d;
`

const Subtitle = styled.span`
  font-size: 1em;
  color: #36353480;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ;
  align-items: flex-start;
`

const ReleaseRow = ({ subtitle, title, href }) => {
  return (
    <ContentBox>
      <HeaderRow to={href}>
        <TitleContainer>
          <SecondaryTitle>{title}</SecondaryTitle>
          <Subtitle>{subtitle || ''}</Subtitle>
        </TitleContainer>
        <ActionLink className='mdi mdi-chevron-right-circle-outline' />
      </HeaderRow>
    </ContentBox>
  )
}

export default ReleaseRow
