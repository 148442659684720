import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import { getValue, generatestring } from '../../utils/helpers'
import { connect } from 'mirrorx'
import styled, { withTheme } from 'styled-components'

const Feedback = styled.span`
  color: ${props => props.color};
  font-size: 80%;
`

class Dropdown extends Component {
  constructor () {
    super()
    this.labelid = generatestring()
  }

  select = React.createRef()

  onChange = (e) => {
    const { onChange, name } = this.props
    onChange && onChange({ name, value: e })
  }

  transformOptions = options => {
    const { display, valueKey } = this.props
    return options.map(option => ({
      id: option.id,
      value: option[valueKey],
      label: option[display]
    }))
  }

  clearValue = () => {
    const select = getValue('select.current.select', this)
    select && select.clearValue()
  }

  getStyles = (isValid) => {
    const { danger, success } = this.props.colors
    const styles = {}
    if (isValid === true) {
      styles.borderColor = success
      styles['&:hover'] = {
        borderColor: success
      }
    } else if (isValid === false) {
      styles.borderColor = danger
      styles['&:hover'] = {
        borderColor: danger
      }
    }

    return {
      control: (provided, state) => {
        const { edit, theme } = this.props
        if (state.isFocused === true) {
          if (isValid === true) {
            styles.boxShadow = `0 0 0 1px ${success}`
          } else if (isValid === false) {
            styles.boxShadow = `0 0 0 1px ${danger}`
          } else {
            styles.boxShadow = `0 0 0 1px ${theme.co}`
          }
        } else {
          styles.boxShadow = provided.boxShadow
        }

        const borderColor = theme.common.colors.lightBlue
        const border = `solid 1px ${borderColor}`
        if (edit) {
          styles['&:hover'] = { borderColor }
        } else {
          styles['&:hover'] = provided['&:hover']
        }
        const newStyles = {
          ...provided,
          ...styles,
          ...(edit ? { border } : {})
        }
        return newStyles
      }
    }
  }

  displayFeedback = (isValid) => {
    const { feedback = 'This field is invalid' } = this.props
    const { danger } = this.props.colors
    if (isValid === false) {
      return <Feedback color={danger}>{feedback}</Feedback>
    }
    return null
  }

  render () {
    const { label, value, edit, options, display, valueKey, isValid, noOptionsMessage, noSearchMessage, placeholder = 'Select...' } = this.props

    return (
      <Form.Group>
        <label htmlFor={this.labelid}>{label}</label>
        <Select
          ref={this.select}
          inputId={this.labelid}
          styles={this.getStyles(isValid)}
          isDisabled={!edit}
          options={this.transformOptions(options)}
          isClearable
          isSearchable
          placeholder={placeholder}
          defaultValue={{
            id: getValue('id', value || {}),
            value: getValue(valueKey, value || {}),
            label: getValue(display, value || {})
          }}
          onChange={this.onChange}
          noOptionsMessage={(input) => {
            let message
            const value = getValue('inputValue', input)
            if (value && value.length > 0) {
              message = noSearchMessage
            } else {
              message = noOptionsMessage
            }
            return message
          }}
        />
        {this.displayFeedback(isValid)}
      </Form.Group>
    )
  }
}

const mapStateToProps = state => ({
  colors: state.meta.colors
})

export default withTheme(connect(mapStateToProps, null, null, { forwardRef: true, withRef: true })(Dropdown))
