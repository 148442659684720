import React, { Component } from 'react'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import { connect } from 'mirrorx'

class TagSelect extends Component {
  select = React.createRef()

  onChange = (selected, action) => {
    const { onChange, name } = this.props

    const affectedSystems = (selected || []).map(system => ({
      id: system.value,
      name: system.label
    }))

    onChange && onChange({ name, value: affectedSystems })
  }

  transform = options => {
    return options.map(option => ({
      value: option.id,
      label: option.name
    }))
  }

  isValid = () => {
    return (((this.select.current || {}).state || {}).selectValue || []).length >= 1
  }

  placeholder = (provided, state) => {
    return {
      ...provided,
      color: '#737373'
    }
  }

  getStyles = (isValid) => {
    const { danger, success } = this.props.colors
    const styles = {}
    if (isValid === true) {
      styles.borderColor = success
      styles['&:hover'] = {
        borderColor: success
      }
    } else if (isValid === false) {
      styles.borderColor = danger
      styles['&:hover'] = {
        borderColor: danger
      }
    }

    return {
      control: (provided, state) => {
        if (state.isFocused === true) {
          if (isValid === true) {
            styles.boxShadow = `0 0 0 1px ${success}`
          } else if (isValid === false) {
            styles.boxShadow = `0 0 0 1px ${danger}`
          }
        } else {
          styles.boxShadow = provided.boxShadow
        }
        const newStyles = {
          ...provided,
          ...styles
        }
        return newStyles
      },
      placeholder: this.placeholder
    }
  }

  render () {
    const { name, options, invalid, feedback, formValidated, value } = this.props
    return (
      <Form.Group>
        <Form.Label htmlFor={name}>Select Affected Systems</Form.Label>
        <Select
          inputId={name}
          ref={this.select}
          isMulti
          isSearchable
          styles={invalid || formValidated ? this.getStyles(!invalid) : { placeholder: this.placeholder }}
          placeholder='Search for systems...'
          name={name}
          options={this.transform(options)}
          onChange={this.onChange}
          value={value}
        />
        {invalid ? (
          <div className='text-danger mt-1' style={{ fontSize: '.8em' }}>
            {feedback}
          </div>
        ) : null}
      </Form.Group>
    )
  }
}

const mapStateToProps = state => ({
  colors: state.meta.colors
})

export default connect(mapStateToProps, null, null, { withRef: true, forwardRef: true })(TagSelect)
