import React, { Component } from 'react'
import { ToggleButton } from '../../styles/Components/Common'
import styled from 'styled-components'
import unionby from 'lodash.unionby'

const Feedback = styled.p`
  margin: 5px 0 0 15px;
  font-size: 0.8rem;
  color: var(--danger);
`

const FeedbackOutline = styled.div`
  border: ${props => (props.valid ? '2px solid var(--danger)' : 'none')};
  padding: ${props => (props.valid ? '3px' : '0')};
`

const Label = styled.span`
  margin-bottom: 0;
`

class SpecialImpact extends Component {
  state = {
    touched: !!this.props.touched
  }

  isValid = () => {
    return this.state.touched || !this.props.isActive
  }

  checkRequestedImpact = () => {
    const { value, requestedImpact } = this.props
    return value.some(({ id }) => id === requestedImpact)
  }

  handleChange = ({ add }) => e => {
    const { onChange, name, requestedImpact, value } = this.props
    !this.state.touched && this.setState({ touched: true })

    onChange({
      name,
      value: add
        ? unionby(value, [{ id: requestedImpact }], 'id')
        : value.filter(({ id }) => id !== requestedImpact)
    })
  }

  render () {
    const {
      isActive,
      formValidated,
      feedback,
      forwardRef,
      label
    } = this.props
    const inValid = isActive && !this.state.touched && formValidated
    const hasRequestedImpact = this.checkRequestedImpact()
    return (
      <div className='row'>
        <div className='col-lg-4 col-12'>
          <div className='d-flex align-items-center h-100'>
            <Label htmlFor='finaid-impact'>
              <strong>
                {label}
              </strong>
            </Label>
          </div>
        </div>
        <div className='col-lg-6 col-12'>
          <div
            ref={forwardRef}
            className='btn-group'
            role='group'
            id='finaid-impact'
            aria-label='Yes or No'
          >
            <FeedbackOutline valid={inValid}>
              <ToggleButton
                disabled={!isActive}
                className={
                  hasRequestedImpact ? 'selected' : undefined
                }
                onClick={this.handleChange({ add: true })}
              >
                Yes
              </ToggleButton>
              <ToggleButton
                disabled={!isActive}
                className={
                  (this.state.touched && !hasRequestedImpact) ||
                  (!isActive && !hasRequestedImpact)
                    ? 'selected'
                    : undefined
                }
                onClick={this.handleChange({ add: false })}
              >
                No
              </ToggleButton>
            </FeedbackOutline>
          </div>
        </div>
        {inValid ? (
          <Feedback>{feedback}</Feedback>
        ) : null}
      </div>
    )
  }
}
export default SpecialImpact
