import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import FormManager from '../Form/FormManager'
import API from '../../api/api'
import { actions } from 'mirrorx'
import Banner from '../../utils/banner'
import TextEdit from '../Form/TextEdit'
import FormButtons from '../Form/FormButtons'
import { withTheme } from 'styled-components'

const sendSuccess = (message) => {
  Banner.sendSuccess({ message, duration: 3000 })
}

const sendError = (message) => {
  Banner.sendError({ message, duration: 3000 })
}

class ClientForm extends Component {
  handleSubmit = async ({ data, event }) => {
    event && event.preventDefault()
    const name = data.name || ''
    const id = data.id || null

    if (name.length && name.length <= 0) return

    if (id) {
      this.submitUpdatedClient(data)
    } else {
      this.submitNewClient(data)
    }
  }

  submitNewClient = async (data) => {
    try {
      const res = await API.postClient(data)
      sendSuccess(`Sucessfully created ${data.name}`)
      const { root, history } = this.props

      history.push(`${root}/${res.id}`)

      actions.client.getClients()
      actions.client.getClient(res.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  submitUpdatedClient = async (data) => {
    try {
      await API.putClient(data)
      sendSuccess(`Successfully updated ${data.name}`)
      this.props.toggleEdit()
      actions.client.getClients()
      actions.client.getClient(data.id)
    } catch (err) {
      sendError(err.message)
    }
  }

  onCancel = () => {
    const { edit, addNew, toggleEdit } = this.props
    if (edit) {
      toggleEdit()
      this.resetForm()
    }

    if (edit && addNew) {
      this.props.handleClose()
    }
  }

  getInputStyle = () => {
    const { theme } = this.props
    let style = {}
    if (this.props.edit === false) {
      style = {
        border: 'solid 1px #F0F0F0',
        borderRadius: '0.25em',
        paddingLeft: '10px',
        backgroundColor: '#F8F9FA'
      }
    } else {
      style = {
        border: `solid 1px ${theme.common.colors.lightBlue}`
      }
    }
    return style
  }

  initialValues = () => {
    const { data } = this.props
    return {
      name: data.name,
      id: data.id || null
    }
  }

  form = React.createRef()

  clearForm = () => {
    const form = this.form.current
    if (!form) return

    form.updateValues({ name: 'id', value: null }, { name: 'name', value: '' })
  }

  resetForm = () => {
    const form = this.form.current
    if (!form) return
    form.resetForm()
  }

  handleDelete = async () => {
    const { data, toggleEdit, userCanDelete, history, root } = this.props
    if (userCanDelete && data.id) {
      await API.deleteClient(data)
      sendSuccess(`Successfully deleted ${data.name}`)
      toggleEdit()
      actions.client.getClients()
      history.push && history.push(root)
    }
  }

  onClickSubmit = (onSubmit) => () => {
    onSubmit(this.handleSubmit)()
  }

  onClickEdit = () => {
    const { userCanEdit, toggleEdit, edit, addNew } = this.props
    if (!edit && (userCanEdit || addNew)) {
      toggleEdit()
    }
  }

  render () {
    const { edit, userCanEdit, userCanDelete, handleClose } = this.props

    return (
      <FormManager ref={this.form} initialValues={this.initialValues()}>
        {
          ({ values, handleChange, onSubmit, resetForm, touched, validated }) => {
            return (
              <Form
                onSubmit={onSubmit(this.handleSubmit)}
                validated={validated}
              >
                <Form.Group>
                  <TextEdit
                    required
                    label='Client Name'
                    feedback='A client is required to have a name'
                    readOnly={!edit}
                    plaintext={!edit}
                    style={this.getInputStyle()}
                    name='name'
                    placeholder='enter client name'
                    value={values.name || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
                <FormButtons
                  edit={edit}
                  userCanEdit={userCanEdit}
                  showDelete={edit && userCanDelete}
                  onClickDelete={this.handleDelete}
                  onClickCancel={this.onCancel}
                  onClickClose={handleClose}
                  onClickSubmit={this.onClickSubmit(onSubmit)}
                  onClickEdit={this.onClickEdit}
                />
              </Form>
            )
          }
        }
      </FormManager>
    )
  }
}

export default withTheme(ClientForm)
