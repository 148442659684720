import React from 'react'
import { boolToHuman, listToCommas } from '../../utils/helpers'
import { IMPACT_IDS } from '../../utils/constants'
import DetailPairs from '../RequestDetailSharedComponents/DetailPairs'

const ChangeSummary = ({ change }) => {
  const financialImpact = change.impacts.some(({ id }) => id === IMPACT_IDS.finAid)
  const customer = change.client.name ? change.client : { name: 'Internal' }
  const pairs = [
    { name: 'Change Owner', value: change.creator.fullname },
    { name: 'Change Team', value: change.team.name },
    { name: 'Prod Team', value: change.prodteam.name },
    { name: 'Primary Customer', value: customer.name },
    { name: 'Submitted', value: change.created },
    ...(change.votecomplete ? [{ name: 'Approved', value: change.votecomplete }] : []),
    { name: 'Systems', value: listToCommas(change.systems) },
    { name: 'Prod Only', value: boolToHuman(change.prodonly) },
    { name: 'Financial Aid Impact', value: boolToHuman(financialImpact) },
    { name: 'Needs Downtime', value: boolToHuman(change.downtime) },
    { name: 'Needs Communication', value: boolToHuman(change.commplan) }
  ]
  return (
    <DetailPairs pairs={pairs} />
  )
}

export default ChangeSummary
