import React, { useState } from 'react'
import { isEmpty, get, set } from 'txstate-utils'
import { FilteringProvider, FilteringConnect } from '../AlternateProvider/Provider'
import WebForm from './WebForm'

export const FormContext = React.createContext()
export const FormConnect = FilteringConnect(FormContext.Consumer)

const initialState = {
  showallfeedback: false,
  saving: false,
  error: null,
  validationversion: 0,
  validationerrors: {},
  item: {}
}

export class FormProvider extends FilteringProvider {
  constructor () {
    super()
    this.state = initialState
    this.focusRef = React.createRef()
    this.mounted = false
  }

  /* reducers */
  saving () {
    return this.update(state => ({
      ...state,
      saving: true
    }))
  }

  saveSuccess (data) {
    return this.update(state => ({
      ...initialState,
      item: data
    }))
  }

  saveError (error) {
    return this.update(state => ({
      ...state,
      saving: false,
      showallfeedback: true,
      error: error.validationerrors ? null : error,
      validationversion: error.validationerrors ? state.validationversion + 1 : state.validationversion,
      validationerrors: error.validationerrors
    }))
  }

  updateEditingData (data) {
    return this.update(state => ({
      ...state,
      item: data
    }))
  }

  validationError (errors) {
    return this.update(state => ({
      ...state,
      error: null,
      validationversion: state.validationversion + 1,
      validationerrors: errors
    }))
  }

  validationSuccess (data, mutate) {
    return this.update(state => ({
      ...state,
      item: mutate ? data : state.item,
      validationversion: state.validationversion + 1,
      validationerrors: {}
    }))
  }

  /* Effects */
  saveItem = async () => {
    const item = this.state.item
    try {
      clearTimeout(this.updateItemTimer)
      await this.saving()
      const data = await this.props.apiSave(item)
      await this.saveSuccess(data)
      // notify the form parent that there was a successful save, if applicable
      this.props.onSave && this.props.onSave(data)
    } catch (e) {
      await this.saveError(e)
    }
  }

  // after validating, the server may respond with an object that has been
  // automatically mutated - for instance, all the strings may come back trimmed
  // in order to avoid inconveniencing users, we will only load this altered data into
  // the form at specific times, such as when a field loses focus, instead of on every
  // onchange event
  // form elements should send the mutate parameter in those cases
  updateItem = async (path, val, mutate) => {
    if (!this.mounted) return
    const item = set(this.state.item, path, val)
    await this.updateEditingData(item)
    clearTimeout(this.updateItemTimer)
    this.updateItemTimer = setTimeout(async () => {
      if (!this.mounted) return
      try {
        const data = await this.props.apiValidate(item)
        if (this.mounted && data.id === this.props.item.id) await this.validationSuccess(data, mutate)
      } catch (e) {
        if (this.mounted && e.validationerrors) await this.validationError(e.validationerrors)
      }
    }, mutate ? 0 : 350)
  }

  resetItem = async () => {
    await this.updateEditingData(JSON.parse(JSON.stringify(this.props.item)))
  }

  async componentDidMount () {
    this.mounted = true
    await this.resetItem()
    if (this.focusRef.current) this.focusRef.current.querySelector('input').focus()
  }

  async componentWillUnmount () {
    this.mounted = false
    clearTimeout(this.updateItemTimer)
  }

  async componentDidUpdate () {
  }

  render () {
    const Form = this.props.Form || WebForm
    return (
      <FormContext.Provider value={{
        ...this.state,
        saveItem: this.saveItem,
        updateItem: this.updateItem,
        resetItem: this.resetItem
      }}>
        <Form className={this.props.className} saveItem={this.saveItem} showValidationAlert={this.state.showallfeedback && !isEmpty(this.state.validationerrors)}>
          {this.props.children}
        </Form>
      </FormContext.Provider>
    )
  }
}

function InputInner (props) {
  const [state, setState] = useState({ interacted: Number.MAX_SAFE_INTEGER })
  const onChange = async val => {
    await setState(state => ({ interacted: props.validationversion }))
    await props.updateItem(props.name, val, false)
  }
  const onBlur = async val => {
    await setState(state => ({ interacted: state.interacted === Number.MAX_SAFE_INTEGER ? props.validationversion : state.interacted }))
    await props.updateItem(props.name, val, true)
  }

  let showfeedback = false
  const currentlyvalidating = state.interacted === props.validationversion
  if (!currentlyvalidating) {
    const interacted = state.interacted !== Number.MAX_SAFE_INTEGER
    showfeedback = (interacted || props.showallfeedback)
  }

  const showinvalid = !!(showfeedback && props.error)

  const eventprops = valFromEvent => ({
    onChange: e => onChange(valFromEvent(e)),
    onBlur: e => onBlur(valFromEvent(e))
  })

  const boolClick = val => e => {
    e.preventDefault()
    onBlur(val)
  }

  return (
    <props.WrappedComponent {...props} {...state} setState={setState}
      eventprops={eventprops} boolClick={boolClick}
      showinvalid={showinvalid} showfeedback={showfeedback} />
  )
}

export const InputConnect = (WrappedComponent) => {
  return FormConnect((state, props) => ({
    value: get(state.item, props.name),
    error: props.error || state.validationerrors[props.name],
    showallfeedback: state.showallfeedback,
    validationversion: state.validationversion,
    updateItem: state.updateItem
  }))(props => InputInner({ ...props, WrappedComponent }))
}
