import React, { Component } from 'react'
import Portal from './Portal'
import styled from 'styled-components'
import $ from 'jquery'
import { FormButton } from '../../styles/Components/Common'
import {
  Modal,
  ModalBackdrop,
  ModalDialog,
  ModalCentered,
  ModalContent,
  ModalBody
} from '../../styles/Components/Modal'

const CenteredText = styled.p`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 0;
`

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`

class SignOutModal extends Component {
  componentDidMount () {
    $('#signout-link').focus()
  }

  render () {
    const { closeModal } = this.props
    return (
      <Portal>
        <ModalBackdrop onClick={closeModal} />
        <Modal>
          <ModalDialog>
            <ModalCentered>
              <ModalContent>
                <ModalBody>
                  <CenteredText>Would you like to sign out?</CenteredText>
                  <CenteredBox>
                    <form action='/api/logout' method='get'>
                      <FormButton type='submit' id='signout-link'>
                        Sign Out
                      </FormButton>
                    </form>
                    &nbsp;
                    <FormButton className='muted' onClick={closeModal}>
                      Cancel
                    </FormButton>
                  </CenteredBox>
                </ModalBody>
              </ModalContent>
            </ModalCentered>
          </ModalDialog>
        </Modal>
      </Portal>
    )
  }
}
export default SignOutModal
