import React, { Component } from 'react'
import Subject from '../../utils/subject'
import { connect } from 'mirrorx'
import BannerDisplay from './BannerDisplay'

export const WARNING = 'warning'
export const ERROR = 'error'
export const SUCCESS = 'success'
export const EVENT_NAME = 'banner_message'

class Banner extends Component {
  state = {
    active: false,
    message: 'An unknown error has been encountered',
    position: undefined,
    type: ERROR
  }

  hideTimeout = null

  onClick = () => {
    this.hide()
  }

  hide = () => {
    if (this.state.active) this.setState({ active: false })
  }

  typeIsValid = (type) => {
    return type === WARNING || type === ERROR || type === SUCCESS
  }

  display = ({ duration, message, position, type }) => {
    if (!this.typeIsValid(type)) return

    if (!this.state.active) {
      this.setState({
        active: true,
        message,
        position,
        type
      }, () => {
        if (this.state.active) {
          this.hideTimeout = setTimeout(this.hide, duration || 1500)
        }
      })
    } else {
      this.setState({
        message
      })
    }
  }

  componentDidMount () {
    Subject.subscribe(EVENT_NAME, this.display)
    if (this.hideTimeout) clearTimeout(this.hideTimeout)
  }

  componentWillUnmount () {
    Subject.unsubscribe(EVENT_NAME, this.display)
  }

  setBannerColor (type) {
    const { colors } = this.props
    switch (type) {
      case ERROR: return colors.danger
      case WARNING: return colors.warning
      case SUCCESS: return colors.success
      default: return '#1F263E'
    }
  }

  render () {
    const { active, message, position, type } = this.state
    const color = this.setBannerColor(type)
    return (
      <BannerDisplay active={active} color={color} onClick={this.onClick} position={position} message={message} />
    )
  }
}

Banner.defaultProps = {
  active: true
}

const mapStateToProps = state => ({
  colors: state.meta.colors
})

export default connect(mapStateToProps)(Banner)
