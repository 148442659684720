import React, { useRef, useEffect, useMemo } from 'react'

const ValidationAlert = props => {
  const { focusRef, alertClassName } = props
  useEffect(() => {
    const ipt = focusRef.current.querySelector('[aria-invalid]')
    if (ipt) ipt.focus()
  }, [focusRef])
  return <div role='alert' className={alertClassName || 'alert alert-danger'}>Submission failed. Please correct errors.</div>
}

export default props => {
  const { showValidationAlert, className, saveItem, children } = props
  const focusRef = useRef()
  useEffect(() => {
    const ipt = focusRef.current.querySelector('input')
    if (ipt) ipt.focus()
  }, [])

  return useMemo(() => (
    <form ref={focusRef} className={className} onSubmit={e => { e.preventDefault(); saveItem() }}>
      {showValidationAlert && <ValidationAlert focusRef={focusRef} />}
      {children}
    </form>
  ), [className, saveItem, showValidationAlert, children])
}
