import React from 'react'
import styled from 'styled-components'
import {
  StatusPill,
  MainTitle,
  SecondaryTitle,
  CenteredBox
} from '../../styles/Components/Common'
import CancelledMessage from '../ChangeDetails/CancelledMessage'
import { CHANGE_TYPES, CHANGE_STATUS, RELEASE_TYPES, RELEASE_STATUS, STANDARD_STATUS, REQUEST_ICONS } from '../../utils/constants'
import { HeaderInContentBox } from '../../styles/Components/ContentBox'

const Container = styled(HeaderInContentBox)`
  display: flex;
  flex-direction: column;
`

const Icon = styled.i`
  margin-top: -3px;
  margin-right: 5px;
  font-size: 1.5em;
`

const setPillClass = typeOrStatus => {
  const list = ['expedited', 'standard']
  return list.includes(typeOrStatus) ? typeOrStatus : undefined
}

const titlemap = {
  change: 'Change Request',
  release: 'Release Request',
  standard: 'Master Standard Request'
}

const getDisplayType = (type, modelName) => {
  if (modelName === 'change') return CHANGE_TYPES[type]
  if (modelName === 'release') return RELEASE_TYPES[type]
  return false
}

const getDisplayStatus = (status, modelName) => {
  if (modelName === 'change') return CHANGE_STATUS[status]
  if (modelName === 'release') return RELEASE_STATUS[status]
  if (modelName === 'standard') return STANDARD_STATUS[status]
  return false
}

const RequestTitle = ({ doc }) => {
  const displaytype = getDisplayType(doc.type, doc.modelName)
  const displaystatus = getDisplayStatus(doc.status, doc.modelName)
  return (
    <Container>
      <MainTitle>#{doc.id} - {doc.title || doc.change.title}</MainTitle>
      <CancelledMessage closure={doc.closed} status={doc.status} />
      <CenteredBox>
        <Icon className={REQUEST_ICONS[doc.modelName]} />
        <SecondaryTitle className='nm request-title'>{titlemap[doc.modelName]}</SecondaryTitle>
        {displaytype ? <StatusPill className={setPillClass(doc.type)}>{displaytype}</StatusPill> : null}
        {displaystatus ? <StatusPill className={setPillClass(doc.status)}>{displaystatus}</StatusPill> : null}
      </CenteredBox>
    </Container>
  )
}

export default RequestTitle
