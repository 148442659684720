import mirror, { actions } from 'mirrorx'
import api from '../api/api'

const requestUsers = state => ({
  ...state,
  loading: true,
  error: null
})

const requestUser = state => ({
  ...state,
  loadingOne: true,
  errorOne: null
})

const usersSuccess = (state, data) => ({
  ...state,
  loading: false,
  error: null,
  lastPage: data.finalpage || 0,
  list: data.documents || [],
  actions: data.actions
})

const userSuccess = (state, data) => ({
  ...state,
  loadingOne: false,
  errorOne: null,
  current: data
})

const usersFailure = (state, error) => ({
  ...state,
  loading: false,
  error
})

const userFailure = (state, error) => ({
  ...state,
  loadingOne: false,
  errorOne: error
})

const clearCurrent = state => ({
  ...state,
  current: {}
})

mirror.model({
  name: 'user',
  initialState: {
    loading: false,
    loadingOne: false,
    loadingCurrent: false,
    error: null,
    errorOne: null,
    errorCurrent: null,
    lastPage: null,
    list: [],
    current: {},
    actions: {}
  },
  reducers: {
    requestUsers,
    requestUser,
    usersSuccess,
    userSuccess,
    usersFailure,
    userFailure,
    clearCurrent
  },
  effects: {
    async getUsers (_, getState) {
      if (getState().user.loading) return
      actions.user.requestUsers()
      try {
        const data = await api.getAllUsers()
        actions.user.usersSuccess(data)
      } catch (error) {
        actions.user.usersFailure({ error: error.message })
      }
    },
    async getUser (id, getState) {
      if (getState().user.loadingOne) return
      actions.user.requestUser()
      try {
        const user = await api.getUser(id)
        actions.user.userSuccess(user)
      } catch (error) {
        actions.user.userFailure({ error: error.message })
      }
    }
  }
})
