import React, { Component } from 'react'
import styled from 'styled-components'
import Label from './Label'
import { connect, actions } from 'mirrorx'
import { withRouter } from 'react-router-dom'
import { StyledLink } from '../../styles/Components/Common'

const Container = styled.nav`
  width: ${props => (props.collapsed ? 60 : 250)}px;
  position: fixed;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${props => props.theme.sidebar.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${props => props.theme.mobilebreakpoint}px) {
    width: ${props => props.collapsed ? 0 : 250}px;
  }
`

const MainLogo = styled.img.attrs({
  src: '/img/logo/cm_logo.svg',
  height: '36px',
  width: '36px',
  alt: 'Change Management'
})`
  margin-top: -5px;
`

const Title = styled(StyledLink)`
  display: block;
  font-size: ${props => props.theme.sidebar.title.fontSize};
  color: ${props => props.theme.sidebar.title.textColor};
  padding-top: ${props => props.theme.sidebar.title.paddingTop};
  margin-bottom: ${props => props.theme.sidebar.title.marginBottom};
  text-align: ${props => props.theme.sidebar.title.textAlign};
  user-select: none;
  &:hover { color: ${props => props.theme.sidebar.title.textColor}; text-decoration: none; }
`

const MainHr = styled.hr`
  margin-top: 0;
  margin-bottom: 20px;
  opacity: ${props => props.theme.sidebar.hr.opacity};
  border: ${props => props.theme.sidebar.hr.border};
  width: ${props => props.theme.sidebar.hr.width};
`

const SubHr = styled(MainHr)`
  margin-bottom: .5rem;
  width: ${props => props.theme.sidebar.hr.sub.width};
`

const SubSectionTitle = styled.p`
  text-align: ${props => props.theme.sidebar.subSection.title.textAlign};
  font-size: ${props => props.theme.sidebar.subSection.title.fontSize};
  color: ${props => props.theme.sidebar.subSection.title.textColor};
  margin-top: ${props => props.theme.sidebar.subSection.title.marginTop};
  margin-bottom: ${props => props.theme.sidebar.subSection.title.marginBottom};
  user-select: none;
`

const SubSection = styled.div`
  background-color: ${props => props.theme.sidebar.subSection.background};
`

const SubSectionLogo = styled.img.attrs({
  src: '/img/logo/txst_logo.svg',
  height: props => props.theme.sidebar.subSection.logo.height,
  width: props => props.theme.sidebar.subSection.logo.width,
  alt: 'Texas State University - The Rising Star of Texas'
})`
  height: ${props => props.theme.sidebar.subSection.logo.height};
  width: ${props => props.theme.sidebar.subSection.logo.width};
  margin-top: ${props => props.theme.sidebar.subSection.logo.marginTop};
  margin-bottom: ${props => props.theme.sidebar.subSection.logo.marginBottom};
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const MoreIcon = styled.i`
  font-size: 3rem;
  color: ${props => props.theme.sidebar.selectedMenu.textColor};

  &:hover {
    color: ${props => props.theme.sidebar.selectedMenu.iconColor};
  }
`

class SideBar extends Component {
  render () {
    const { className, collapsed } = this.props
    return (
      <Container collapsed={collapsed} className={className}>
        <div>
          <Title to='/'>
            {collapsed ? (
              <MainLogo />
            ) : (
              <>
                <MainLogo />
                &nbsp; CHANGE MANAGEMENT
              </>
            )}
          </Title>
          <MainHr />
          <Label to='/' text='My Dashboard' logo='icon-dashboard-northstar' />
          <Label
            to='/change'
            text='Change Requests'
            logo='icon-change-orbit'
          />
          <Label
            to='/release'
            text='Release Requests'
            logo='icon-release-rocket'
          />
          <Label
            to='/standard'
            text='Master Standards'
            logo='icon-masters-key'
          />
        </div>

        <SubSection>
          {!collapsed && (
            <>
              <SubSectionTitle>
                {collapsed ? 'SM' : 'System Management'}
              </SubSectionTitle>
              <SubHr />
              <Label to='/clients' text='Clients' subLabel />
              <Label to='/systems' text='Systems' subLabel />
              <Label to='/teams' text='Teams' subLabel />
              <Label to='/users' text='Users' subLabel />
              <Label href='https://git.txstate.edu/its/change-ui/issues/new/choose' target='_blank' text='Feedback' subLabel />
              <Label href='/files/policies.20220916.docx' text='Documentation' subLabel />
              <LogoWrapper>
                <SubSectionLogo />
              </LogoWrapper>
            </>
          )}
          {collapsed && (
            <LogoWrapper>
              <MoreIcon
                className='ion-ios-more'
                onClick={() => actions.layout.toggleSidebar()}
              />
            </LogoWrapper>
          )}
        </SubSection>
      </Container>
    )
  }
}

SideBar.displayName = 'SideBar'

export default withRouter(connect(state => ({
  collapsed: state.layout.sidebarCollapsed
}))(SideBar))
