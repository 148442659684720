import React, { Component } from 'react'
import Dropdown from '../Form/Dropdown'

class ManagerSelect extends Component {
  clearValue = () => {
    const dropdown = this.dropdown
    dropdown && dropdown.clearValue()
  }

  componentDidMount () {
    const { value } = this.props
    if (!value || !value.id) this.clearValue()
  }

  render () {
    const { edit, name, onChange, managers, value, isValid, errorMessage, placeholder } = this.props

    return (
      <Dropdown
        ref={c => { this.dropdown = c && c.getWrappedInstance(); return this.dropdown }}
        isValid={isValid}
        feedback={errorMessage}
        name={name}
        edit={edit}
        onChange={onChange}
        options={managers}
        value={value}
        placeholder={placeholder}
        valueKey='username'
        label='Team Manager'
        display='fullname'
        noSearchMessage='No team members match your search'
        noOptionsMessage='There are no team members to choose as a manager'
      />
    )
  }
}

export default ManagerSelect
