import React from 'react'

export const LoadingCircle = () => {
  return (
    <div className='sk-circle'>
      <div className='sk-circle1 sk-child' />
      <div className='sk-circle2 sk-child' />
      <div className='sk-circle3 sk-child' />
      <div className='sk-circle4 sk-child' />
      <div className='sk-circle5 sk-child' />
      <div className='sk-circle6 sk-child' />
      <div className='sk-circle7 sk-child' />
      <div className='sk-circle8 sk-child' />
      <div className='sk-circle9 sk-child' />
      <div className='sk-circle10 sk-child' />
      <div className='sk-circle11 sk-child' />
      <div className='sk-circle12 sk-child' />
    </div>
  )
}

export const ErrorAlert = ({ className, children }) => (
  <div className={'alert alert-danger ' + className} role='alert'>{children}</div>
)

export class Loader extends React.PureComponent {
  constructor () {
    super()
    this.state = { showLoader: false, showError: false }
  }

  showLoader () {
    this.setState({ showLoader: true, showError: false })
  }

  showError () {
    this.setState({ showLoader: false, showError: true })
  }

  componentDidMount () {
    this.loadertimer = setTimeout(() => this.showLoader(), 300)
    this.errortimer = setTimeout(() => this.showError(), this.props.timeout || 10000)
  }

  componentWillUnmount () {
    clearTimeout(this.loadertimer)
    clearTimeout(this.errortimer)
  }

  render () {
    const { error } = this.props
    const { showLoader, showError } = this.state
    return <>
      {showLoader && <LoadingCircle />}
      {showError && <ErrorAlert>{error || 'An error occurred.'}</ErrorAlert>}
    </>
  }
}
